import service from "@/store/services/payments-transactions-service";

export const state = {
  list: {},
  payment: {},
  meta: {},
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, payment) => {
    state.payment = payment;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },
  toggleOnHold({ commit, dispatch }, params) {
    return service.toggleOnHold(params).then((transactions) => {
      commit("SET_RESOURCE", transactions);
    });
  },
  sendPaymentsToDpa({ commit, dispatch }, params) {
    return service.sendPaymentsToDpa(params).then((transactions) => {
      commit("SET_RESOURCE", transactions);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.total,
  payment: (state) => state.payment,
};

const payments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default payments;
