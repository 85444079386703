export default {
  type: "products",
  name: null,
  is_taxable:true,
  sku: null,
  excerpt: "",
  price: null,
  picture: null,
  gallery: [],
  relationshipNames: ["taxesGroup", "organization", "allowedLocations"],
  /* taxesGroup: {
    type: "taxes-groups",
    id: null,
  }, */
};
