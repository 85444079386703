var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('div',{staticClass:"title-header"},[(!_vm.noHeaders)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(_vm._s(_vm.signature.code))]),_c('status-badge',{staticClass:"mr-3 p-2 rounded-status-tag",attrs:{"status":_vm.signature.status}})],1),_c('div',{staticClass:"col-6 text-right"},[(
            (_vm.signature.status == _vm.STATUS_DRAFT ||
              _vm.signature.status == _vm.STATUS_DECLINED ||
              _vm.signature.status == _vm.STATUS_EXPIRED) &&
            _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SIGNATURES)
          )?_c('base-button',{staticClass:"btn-outline-success btn-success",attrs:{"loading":_vm.loading,"icon":"","size":"sm"},on:{"click":_vm.sendSignature}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-paper-plane"})]),(
              _vm.signature.status == _vm.STATUS_DECLINED ||
              _vm.signature.status == _vm.STATUS_EXPIRED
            )?_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("SIGNATURES.RESEND"))+" ")]):_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("SIGNATURES.SEND"))+" ")])]):_vm._e(),(
            _vm.signature.status == _vm.STATUS_DRAFT &&
            _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SIGNATURES)
          )?_c('base-button',{staticClass:"btn-outline-danger",attrs:{"icon":"","size":"sm"},on:{"click":_vm.deleteSignature}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])]):_vm._e(),(
            (_vm.signature.status == _vm.STATUS_DRAFT ||
              _vm.signature.status == _vm.STATUS_DECLINED ||
              _vm.signature.status == _vm.STATUS_EXPIRED) &&
            _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SIGNATURES)
          )?_c('base-button',{staticClass:"elite-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.editSignature}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-edit"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])]):_vm._e(),(
            _vm.signature.status === _vm.STATUS_COMPLETED &&
            (!_vm.signature.meta?.signed_documents ||
              _vm.signature.meta.signed_documents.length === 0)
          )?_c('base-button',{staticClass:"elite-button add",attrs:{"loading":_vm.loading,"icon":"","size":"sm"},on:{"click":_vm.getSignedDocuments}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-download"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("SIGNATURES.GET_DOCUMENTS"))+" ")])]):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"all-infos"},[_c('div',{staticClass:"all-infos-text"},[(_vm.signature.contract.contract_type)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.TYPE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.signature.contract.contract_type ? _vm.$t("CONTRACTS." + _vm.signature.contract.contract_type) : "")+" ")])]):_vm._e(),(_vm.signature.contract.name)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.NAME")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.signature.contract.name ?? "")+" ")])]):_vm._e(),(_vm.signature.contract.excerpt)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.EXCERPT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.signature.contract.excerpt ?? "")+" ")])]):_vm._e(),(_vm.signature.email_message)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("SIGNATURES.EMAIL_MESSAGE")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.signature.email_message ?? "")+" ")])]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.signature.created_at))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.UPDATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.signature.updated_at))+" ")])])])]),(_vm.signature.status != _vm.STATUS_COMPLETED)?_c('div',{staticClass:"documents mt-3 mb-3"},[_c('h3',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("SIGNATURES.FILES_TO_BE_SIGN")))]),_c('el-divider'),_c('file-list-component',{attrs:{"contract":_vm.signature.contract,"generated-documents":_vm.signature.meta?.documents_to_sign ?? []}})],1):_vm._e(),(
      _vm.signature.status == _vm.STATUS_COMPLETED && _vm.signature.meta?.signed_documents
    )?_c('div',{staticClass:"documents mt-3 mb-3"},[_c('h3',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("SIGNATURES.SIGNED_FILES")))]),_c('el-divider'),_c('files-list',{attrs:{"documents":_vm.signature.meta?.signed_documents}})],1):_vm._e(),(_vm.signature.signers)?_c('div',{staticClass:"payment-method-signers mt-3"},[_c('h3',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("SIGNATURES.SIGNERS")))]),_c('el-divider'),_c('object-details',{attrs:{"data":_vm.getSigners(_vm.signature.signers),"with-trad":false}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }