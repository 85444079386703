<template>
  <div class="container-fluid">
    <payment-method-form
      :holder-type="holderType"
      :holder-id="holderId"
      :loading="loading"
      :paymentMethodData="paymentMethod"
      :formErrors="formErrors"
      @paymentMethodSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPaymentMethod from "../defaultPaymentMethod";
import PaymentMethodForm from "../partials/PaymentMethodForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PaymentMethodForm,
  },

  mixins: [alertLeave],

  props: ["holderType", "holderId"],

  data() {
    return {
      paymentMethod: cloneDeep(defaultPaymentMethod),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(paymentMethod) {
      this.loading = true;
      const paymentMethodData = cloneDeep(paymentMethod);
      delete paymentMethodData.id;
      try {
        await this.$store.dispatch("paymentMethods/add", paymentMethodData);
        this.$notify({
          type: "success",
          message: this.$t("PAYMENT_METHODS.PAYMENT_METHOD_ADDED"),
        });
        const paymentMethod = await this.$store.getters[
          "paymentMethods/paymentMethod"
        ];
        this.$emit("onViewPaymentMethod", paymentMethod, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
