<template>
  <div
    class="container-fluid mt-3"
    v-if="$currentUserCan($permissions.PERM_VIEW_ANY_TAXES)"
  >
    <span class="resize-loading" v-if="!taxesGroup">
      <span class="loader"></span>
    </span>
    <div v-else class="taxes-wrapper">
      <div class="all-infos-taxes">
        <div class="all-infos-taxes-text">
            <h4 v-if="taxesGroup.name">
              {{ taxesGroup.name ?? "" }}
            </h4>
            <div v-if="taxesGroup.excerpt">
             <p>{{ taxesGroup.excerpt ?? "" }}</p>
            </div>
        </div>
      </div>
      <div class="taxes-view-table">
        <taxes-view-table :taxes="taxesGroup.taxes" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import TaxesViewTable from "../partials/taxes/TaxesViewTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    TaxesViewTable,
  },

  mixins: [],

  props: {
    taxesGroupId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      taxesGroup: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("taxesGroups/get", this.taxesGroupId);
        this.taxesGroup =
          this.$store.getters["taxesGroups/taxesGroup"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editTaxesGroup(taxesGroup) {
      this.$emit("onEditTaxesGroup", taxesGroup);
    },
    deleteTaxesGroup(taxesGroup) {
      this.$emit("onDeleteTaxesGroup", taxesGroup);
    },
  },
};
</script>
