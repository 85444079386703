<template>
  <div class="taxes-content">
    <div class="custom-header">
      <h3>{{ $t("COMMON.DETAILS") }}</h3>
      <base-button
        class="elite-button add"
        type="button"
        @click="toggleModalState"
      >
        <span class="btn-inner--icon">
          <i class="far fa-edit"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("TAXES_GROUPS.EDIT_TAXES") }}
        </span>
      </base-button>
    </div>
    <div class="">
      <taxes-view-table :taxes="taxesGroup.taxes" />
    </div>

    <taxes-form
      :taxesGroupData="taxesGroup"
      :showModal="showModal"
      @onChangeModalState="toggleModalState"
      @needReload="needReload"
    />
  </div>
</template>

<script>
import TaxesViewTable from "./TaxesViewTable.vue";
import TaxesForm from "./TaxesForm.vue";

export default {
  name: "taxes-view",
  props: ["taxesGroup"],

  components: {
    TaxesViewTable,
    TaxesForm,
  },

  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    needReload() {
      this.$emit("needReload");
    },
    toggleModalState() {
      this.showModal = !this.showModal;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
