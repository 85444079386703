<template>
  <div clas="flex justify-center content-center">
  <div :class="'float-left mr-2 badge badge-' + badgeType">
    <arrow-down-icon v-if="badgeType == 'success'" />
    <arrow-up-icon v-else />
  </div>
  <div class="payment-type">
    <span v-if="badgeType == 'success'">{{ $t('PAYMENTS.SALE') }} </span>
    <span v-else> {{ $t('PAYMENTS.PURCHASE') }} </span>
  </div>
</div>
</template>

<script>
import ArrowDownIcon from "../Icons/ArrowDownIcon.vue";
import ArrowUpIcon from "../Icons/ArrowUpIcon.vue";
import { DEPOSIT, WITHDRAW } from "@/constants/payments";

export default {
  name: "InOutBadge",
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return [DEPOSIT, WITHDRAW].includes(value);
      },
    },
  },
  computed: {
    badgeType() {
      switch (this.type) {
        case DEPOSIT:
          return "success";
        case WITHDRAW:
          return "danger";
        default:
          return "default";
      }
    },
  },
};
</script>
