<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <!-- <base-input
        :label="`${$t('PURCHASES_INVOICES.ITEM_TYPE')}`"
        v-if="!purchasesInvoiceItem.id"
      >
        <el-select
          name="Type"
          v-model="purchasesInvoiceItemModel.purchasesInvoiceable.type"
          prepend-icon="fas fa-calendar"
          @change="changePurchasesInvoiceableType"
        >
          <el-option :value="null" :label="$t('COMMON.NONE')" />
          <el-option
            v-if="purchasesInvoice.organization"
            value="products"
            :label="$t('COMMON.PRODUCT')"
          />
          <el-option
            v-if="
              !$currentUserIsLevelOrganization() &&
              !purchasesInvoice.organization
            "
            value="packages"
            :label="$t('COMMON.PACKAGE')"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.purchasesInvoiceable" /> -->

      <base-input :label="`${$t(`COMMON.PRODUCT`)} (*)`">
        <product-selector
          :product="purchasesInvoiceItemModel.product_id"
          :allowNone="false"
          :showAll="false"
          :disabled="!!purchasesInvoiceItemModel.id"
          @productChanged="
            (productId, product) => {
              purchasesInvoiceItemModel.code = product.name;
              purchasesInvoiceItemModel.excerpt = product.excerpt ?? '';
              purchasesInvoiceItemModel.unit_price =  product.buying_price;
              purchasesInvoiceItemModel.product_id = productId;
              purchasesInvoiceItemModel.sku = product.sku;
              taxes_group_id = product.taxes_group_id;
            }
          "
        />
      </base-input>

      <base-input
        :label="`${$t('COMMON.DESIGNATION')} (*)`"
        :placeholder="$t('COMMON.DESIGNATION')"
        v-model="purchasesInvoiceItemModel.code"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.code" />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="purchasesInvoiceItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <base-input
        :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
        :placeholder="$t('COMMON.UNIT_PRICE')"
        v-model="purchasesInvoiceItemModel.unit_price"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.unit_price" />

      <base-input
        :label="`${$t('COMMON.QUANTITY')} (*)`"
        :placeholder="$t('COMMON.QUANTITY')"
        v-model="purchasesInvoiceItemModel.quantity"
        type="number"
        step="1"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.quantity" />

      <div class="taxes mt-3 mb-2">
        <view-taxes-group
          v-if="taxes_group_id"
          :taxes-group-id="taxes_group_id"
        />
        <span v-else slot="title text-gray bold">
          <taxe-icon :width="'20px'" class="custom-svg-icon" />
          {{ $t("TAXES.NO_TAXABLE_PRODUCT") }}
        </span>
      </div>

      <!-- <taxes-selector
        :label="$t('COMMON.TAXES')"
        :taxes="purchasesInvoiceItemModel.taxes"
        @taxesChanged="(taxes) => (purchasesInvoiceItemModel.taxes = taxes)"
      /> -->
      <validation-error :errors="apiValidationErrors.taxes" />

      <!--<base-input
        :label="$t('COMMON.DISCOUNT_PERCENT')"
        :placeholder="$t('COMMON.DISCOUNT_PERCENT')"
        v-model="purchasesInvoiceItemModel.discount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.discount" />-->
    </form>

    <template slot="footer">
      <button
        v-if="!purchasesInvoiceItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitPurchasesInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_INVOICES.ADD_PURCHASES_INVOICE_ITEM") }}
      </button>

      <base-button
        v-if="purchasesInvoiceItemModel.id"
        native-type="submit"
        type="primary"
        class="new-purchases-invoice-item--add"
        @click="submitPurchasesInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_INVOICES.UPDATE_PURCHASES_INVOICE_ITEM") }}
      </base-button>

      <base-button
        v-if="purchasesInvoiceItemModel.id"
        type="danger"
        @click="deletePurchasesInvoiceItem(purchasesInvoiceItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_INVOICES.DELETE_PURCHASES_INVOICE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePurchasesInvoiceItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ProductSelector from "@/components/ProductSelector.vue";
import defaultPurchasesInvoiceItem from "../defaultPurchasesInvoiceItem";
import ViewTaxesGroup from "@/views/Pages/AdminModule/TaxesGroupManagement/components/ViewTaxesGroup.vue";
import TaxeIcon from "@/components/Icons/TaxeIcon.vue";

export default {
  name: "purchases-invoice-view-details-purchases-invoice-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ViewTaxesGroup,
    TaxeIcon,
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "purchasesInvoice",
    "purchasesInvoiceItem",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      taxes_group_id: null,
      all_purchasesInvoiceables: [],
      purchasesInvoiceItemModel: cloneDeep(defaultPurchasesInvoiceItem),
    };
  },

  computed: {},

  methods: {
    getOrderIds() {
      if (!this.purchasesInvoice.purchasesOrder) {
        return null;
      }
      if (this.purchasesInvoice.purchasesOrder.invoicingItemsState) {
        const remainingProductsIds = Object.values(
          this.purchasesInvoice.purchasesOrder.invoicingItemsState
            .remainingItems
        ).map((item) => item.product_id);
        if (remainingProductsIds.length > 0) {
          return remainingProductsIds;
        }
      }
      return null;
    },

    closePurchasesInvoiceItemModal() {
      this.$emit("onClosePurchasesInvoiceItemModal", true);
    },

    submitPurchasesInvoiceItemForm() {
      const purchasesInvoiceItemData = cloneDeep(
        this.purchasesInvoiceItemModel
      );
      purchasesInvoiceItemData.unit_price = parseFloat(
        purchasesInvoiceItemData.unit_price
      );
      purchasesInvoiceItemData.quantity = parseInt(
        purchasesInvoiceItemData.quantity,
        10
      );
      purchasesInvoiceItemData.discount = parseFloat(
        purchasesInvoiceItemData.discount
      );
      if (!purchasesInvoiceItemData.purchasesInvoiceable?.id) {
        delete purchasesInvoiceItemData.purchasesInvoiceable;
      }
      this.$emit("onSubmitPurchasesInvoiceItemForm", purchasesInvoiceItemData);
    },

    deletePurchasesInvoiceItem(purchasesInvoiceItemId) {
      this.$emit("onDeletePurchasesInvoiceItem", purchasesInvoiceItemId);
    },

    productChanged(productId, product) {
      if (product.warehouseProduct) {
        this.purchasesInvoiceItemModel.purchasesInvoiceable = {
          type: "warehouse-products",
          id: product.warehouseProduct.id.toString(),
        };
        this.purchasesInvoiceItemModel.unit_price =
          product.warehouseProduct.buying_price;
        this.purchasesInvoiceItemModel.code =
          product.warehouseProduct.sku || product.sku || product.code;
        this.purchasesInvoiceItemModel.excerpt = product.denomination;
        this.purchasesInvoiceItemModel.taxes = product.warehouseProduct.taxes;
      } else if (product.supplierProduct) {
        this.purchasesInvoiceItemModel.purchasesInvoiceable = {
          type: "supplier-products",
          id: product.supplierProduct.id.toString(),
        };
        this.purchasesInvoiceItemModel.unit_price =
          product.supplierProduct.price;
        this.purchasesInvoiceItemModel.code =
          product.supplierProduct.sku || product.sku || product.code;
        this.purchasesInvoiceItemModel.excerpt = product.denomination;
        this.purchasesInvoiceItemModel.taxes = product.supplierProduct.taxes;
      } else {
        this.purchasesInvoiceItemModel.purchasesInvoiceable.id = productId;
        this.purchasesInvoiceItemModel.unit_price = product.price;
        this.purchasesInvoiceItemModel.code = product.code;
        this.purchasesInvoiceItemModel.excerpt = product.denomination;
        this.purchasesInvoiceItemModel.taxes = product.taxes;
      }
      this.purchasesInvoiceItemModel.purchasesInvoiceable.product_id =
        product.id;
    },
  },

  mounted() {},

  watch: {
    purchasesInvoiceItem(purchasesInvoiceItem) {
      if (purchasesInvoiceItem) {
        this.purchasesInvoiceItemModel = cloneDeep(purchasesInvoiceItem);
      }
      this.resetApiValidation();
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
