<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ organizationGroups.name }}</h3>
    <div class="col-12">
      <!--   <div class="col-12">
        <div class="organisation-image">
          <img
            v-if="organization.logo"
            :src="`${organization.logo}`"
            class="argon-image"
            style="width: 100%"
          />
          <img v-else src="/img/add-image.svg" class="argon-image default" />
        </div>
      </div> -->
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ organizationGroups.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="organizationGroups.description"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ organizationGroups.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <!--           <dd class="col-sm-8">
            {{
              organizationGroups.phone_type
                ? $t(`COMMON.PHONE_TYPE_${organizationGroups.phone_type}`)
                : null
            }}:
            {{ organizationGroups.phone }}
            {{
              organizationGroups.phone_extension
                ? ` (ext: ${organizationGroups.phone_extension})`
                : null
            }}
          </dd> -->
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <!--           <dd class="col-sm-8">
            <span
              v-for="(line, index) in organizationGroups.other_phones"
              :key="index"
            >
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd> -->
        </dl>
        <!--        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ organizationGroups.address }}
          </dd>
        </dl> -->
        <!--       <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.RESELLER") }}</dt>
          <dd class="col-sm-8">
            <reseller :reseller="organizationGroups.reseller" />
          </dd>
        </dl> -->
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.OWNER") }}</dt>
          <dd class="col-sm-8">
            <!--    <router-link :to="$objectViewRoute(organizationGroups.owner)">
              {{
                `${organizationGroups.owner.firstname} ${organizationGroups.owner.lastname} - ${organization.owner.email}`
              }}
            </router-link> -->
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">{{ $t("COMMON.DEFAULT_TAXES") }}</dt>
          <!--         <dd class="col-sm-12">
            <dl class="row" v-for="(tax, key) in organization.taxes" :key="key">
              <dt class="col-sm-4">{{ tax.name }}</dt>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_PERCENTAGE">
                {{ tax.value }} %
              </dd>
              <dd class="col-sm-8" v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd> -->
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="organizationGroups.createdAt">
            {{ $timeZoneDateFormat(organizationGroups.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="organizationGroups.updatedAt">
            {{ $timeZoneDateFormat(organizationGroups.updated_at) }}
          </dd>
        </dl>

        <div class="mb-2 mt-4">
          <h3>{{ $t("COMMON.BILLING_ADDRESS") }}</h3>
        </div>

        <!--         <billing-informations-view :billingInformations="organization" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";

export default {
  name: "organization-group-view-global",

  components: {
    BillingInformationsView,

    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  props: ["organizationGroups"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organizationGroups(organizationGroups) {},
  },
};
</script>
