export default {
  PAYMENT_METHODS: "Moyens de paiement",
  PAYMENT_METHOD: "Moyen de paiement",
  PAYMENT_METHODS_LIST: "Liste des moyens de paiements",
  ADD_PAYMENT_METHOD: "Ajouter un moyen de paiement",
  PAYMENT_METHOD_ADDED: "Moyen de paiement ajouté",
  EDIT_PAYMENT_METHOD: "Modifier un moyen de paiement",
  DELETE_THIS_PAYMENT_METHOD: "Supprimer ce moyen de paiement ?",
  PAYMENT_METHOD_DELETED: "Moyen de paiement supprimé",
  PAYMENT_METHOD_TYPE: "Catégorie de moyen de paiement",
  CATEGORY: "Catégorie",
  VIEW_PAYMENT_METHOD: "Moyen de paiement",
  RETURN_TO_FROM: "Retourner au formulaire",
  SAME_PAYMENT_METHODS:
    'Un ou plusieurs moyens de paiement ont déjà été enregistrés avec le numéro de compte {account_number}. S\'agit-il du même ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  CREATED_AT: "Créé le",
  bank_account: "Compte bancaire",
  credit_card: "Carte de crédit",
  awaiting_signature: "En attente de signature",
  signature_declined: "Signature refusée",
  pending: "En attente",
  active: "Active",
  disabled: "Désactivé",
  PAYMENT_METHOD_CREATED: "Moyen de paiement ajouté avec succès",
  PAYMENT_METHOD_UPDATED: "Moyen de paiement mis à jour avec succès",
  PAYMENT_METHOD_CREATION_FAILED:
    "Une erreur s'est produite lors de la création du moyen de paiement",
  PAYMENT_METHOD_UPDATE_FAILED:
    "Une erreur s'est produite lors de la mise à jour du moyen de paiement",
  PAYMENT_METHOD_HOLDER: "Titulaire",
  PAYMENT_METHOD_HOLDER_NAME: "Nom légal du titulaire du compte",
  PAYMENT_METHOD_ACCOUNT: "Compte",
  PAYMENT_METHOD_SIGNERS: "Signataires",
  PAYMENT_METHOD_DELETED: "Moyen de paiement supprimé !",
  PAYMENT_METHOD_DELETE_FAILED:
    "Désolé une erreur s'est produite lors de la suppression !",
  email_correct: "Veuillez entrer une adresse e-mail valide",
  email_required: "Veuillez entrer l'adresse e-mail",
  lastname_required: "Veuillez entrer le nom",
  firstname_required: "Veuillez entrer le prénom",
  holder_required: "Veuillez sélectionner une organisation ou une concession",
  ADD_SIGNER: "Ajouter un signataire",
  actions: "Actions",
  EDIT: "Modifier",
  DELETE: "Supprimer",
  NUMERO: "N°",
  HOLDER: "Propriétaire",
  DETAILS: "Détails",
  SIGNERS: "Signataires",
  account_number: "Numéro de compte",
  branch_code: "Numéro d’institution",
  transit_number: "Numéro de transit",
  bank_name: "Nom de la banque",
  firstname: "Prénom",
  lastname: "Nom",
  address: "Adresse",
  phone: "Téléphone",
  email: "Courriel",
  bank_account: "Compte bancaire",
  bank_acronym: "Sigle",
  account_number_required: "Le numéro du compte est requis",
  bank_acronym_required: "L'acronyme de la banque est requis (ex:BNC)",
  branch_code_required: "Le numéro d’institution est requis",
  transit_number_required: "Le numéro de transit est requis",
  bank_name_required: "Le nom de la banque est requis",
  TYPE: "Type",
  code: "Code",
  label: "Libellé",
  status: "Statut",
  STATUS: "Statut",
  created_at: "Créer le ",
  BANK: "Banque",
  SAVE: "Sauvegarder",
  SELECT: "Sélectionner un moyen de paiement",
  VIEW_DETAILS: "Voir les détails",
  OTHERS: "Autres",
  DEFAULT: "Par défaut",
  PAYMENT_METHOD_SETED_AS_DEFAULT:
    "Moyen de paiement défini comme par défaut !",
  SET_THIS_PAYMENT_METHOD_AS_DEFAULT:
    "Vous confirmez vouloir définir ce moyen de paiement comme par défaut ?",
  SET_AS_DEFAULT: "Définir",
  SET_THIS_AS_DEFAULT: "Définir par défaut",
  DEACTIVATE: "Désactiver",
  ACTIVATE: "Réactiver",
  DEACTIVATE_PAYMENT_METHOD:
    "Êtes-vous sûr de vouloir désactiver ce moyen de paiement ?",
  ACTIVATE_PAYMENT_METHOD:
    "Êtes-vous sûr de vouloir Réactiver ce moyen de paiement ?",
  ACTIVATED: "Activé",
  DEACTIVATED: "Désactivé",
  PAYMENT_METHOD_ACTIVATION_CHANGED:
    "La modification du statut de moyen de paiement a été effectué avec succès !",
  DEBIT_CONTRACT_REQUIRED:
    "Veuillez Sélectionner un modèle de contrat de débit pré-autorisé",
  SELECT_DEBIT_CONTRACT: "Sélectionnez un modèle de contrat débit",
  CREDIT_CONTRACT_REQUIRED:
    "Veuillez Sélectionner un modèle de contrat de crédit pré-autorisé",
  SELECT_CREDIT_CONTRACT: "Sélectionnez un modèle de contrat crédit",
  PAYMENT_METHOD_HOLDER_NAME_REQUIERD: "Veuillez saisir le nom du titulaire du compte",
  BRANCH_ADDRESS : "Adresse de la succursale",
  branch_address : "Adresse de la succursale",
  CHECK_SPECIMEN: "Spécimen de chèque",
  specimen_required: "Veuillez ajouter un spécimen de chèque pour ce compte",
  accepted_files: "Fichiers image de type jpg/png"
};
