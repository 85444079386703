<template>
  <el-form
    class="add-form"
    @submit.prevent="
      {
      }
    "
    ref="chargePackageForm"
  >
    <div class="form-group-wrapper">
      <!-- Charges -->
      <el-form-item
        class="form-group-wrapper-item h3 w-100"
        :label="`${$t('CHARGE_PACKAGES.CHARGE_PACKAGES')} (*)`"
        prop="chargePackage"
      >
        <charge-package-selector
          with-filters
          :chargePackage="location?.chargePackage.id"
          :multiple="false"
          :filterable="true"
          :showAll="false"
          @chargePackageChanged="chargePackageSelected"
        />
      </el-form-item>

      <div class="submit-wrapper">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          @click.prevent="handleSubmit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{ $t("CHARGE_PACKAGES.EDIT_CHARGE_PACKAGE") }}
        </base-button>
        <button
          type="button"
          class="btn btn-sm base-button btn-black text-white px-5 ml-2"
          @click="closeFormModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Form, FormItem } from "element-ui";
import ChargePackageSelector from "../../ChargePackageManagement/components/ChargePackageSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ChargePackageSelector,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },

  props: {
    locationId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },

  data() {
    return {
      location: null,
      formErrors: null,
      loading: false,
      locationChargesPackagesData: {
        type:'location',
        id: null,
        charge_package_id: null,
      },
    };
  },

  mounted() {
    this.getLocation();
    if (this.location) {
      this.locationChargesPackagesData.id = this.location.id;
    }
  },

  methods: {
    
    chargePackageSelected(selected) {
      this.locationChargesPackagesData.charge_package_id = selected;
      this.location.chargePackage = [...selected].map((item) => ({
        type: "charge-packages",
        id: item,
      }));
    },

    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("locations/get", this.locationId);
        this.location = this.$store.getters["locations/location"];
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit() {
      if (
        this.locationChargesPackagesData.charge_package_id == null
      ) {
        return;
      }
      this.loading = true;
      this.locationChargesPackagesData.id = this.location.id;

      try {
        await this.$store.dispatch(
          "locations/changeChargesPackages",
          this.locationChargesPackagesData
        );
        this.$notify({
          type: "success",
          message: this.$t("LOCATIONS.LOCATION_UPDATED"),
        });
        this.openChargePackageViewModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    

    async getLocation() {
      await this.get();
    },

    openChargePackageViewModal() {
      this.closeFormModal();
      this.$emit("needReload", this.location);
    },
    closeFormModal() {
      this.$emit("onChangeModalState");
    },
  },
};
</script>
