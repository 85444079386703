<template>
  <div class="">
    <el-form
      class="add-form"
      @submit.prevent="checkTaxesGroupInformation"
      @keydown.enter.prevent="() => {}"
      :model="taxesGroup"
      label-position="top"
      ref="taxesGroupForm"
    >
      <div class="form-group-wrapper">
        <!-- Name - Description-->

        <!-- Name -->
        <el-form-item
          v-if="!onlyTaxes"
          class="form-group-wrapper-item h3 w-100"
          :label="`${$t('COMMON.NAME')} (*)`"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('TAXES.NAME_REQUIRED'),
            },
          ]"
        >
          <el-input
            :placeholder="$t('COMMON.NAME')"
            v-model="taxesGroup.name"
            @change="
              () => {
                onFormChanged();
              }
            "
          />
          <validation-error :errors="apiValidationErrors.name" />
        </el-form-item>

        <!-- Excerpt -->
        <el-form-item
          v-if="!onlyTaxes"
          class="form-group-wrapper-item h3 w-100"
          :label="`${$t('COMMON.EXCERPT')} (*)`"
          prop="excerpt"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            :placeholder="$t('COMMON.EXCERPT')"
            v-model="taxesGroup.excerpt"
          >
          </el-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </el-form-item>

        <!-- Section Commision -->
        <el-form-item
          class="taxe-selection h3 w-100 mb-3 form-group-wrapper-item w-100"
          :label="`${$t('TAXES.SELECT_TAXES')} (*)`"
          prop="taxes"
          :rules="[
            {
              required: true,
              message: $t('TAXES.REQUIRED'),
            },
          ]"
        >
          <taxe-selector
            with-filters
            :taxe="getTaxesGroupIds(taxesGroup?.taxes)"
            :multiple="true"
            :filterable="true"
            :showAll="false"
            :allowCreate="false"
            :excludeCategory="categoryFee"
            @taxeChanged="feeSelected"
          />
        </el-form-item>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            @click.prevent="checkTaxesGroupInformation"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              taxesGroup.id
                ? $t("TAXES_GROUPS.EDIT_TAXES_GROUP")
                : $t("TAXES_GROUPS.ADD_TAXES_GROUP")
            }}
          </base-button>
          <button
            v-if="onlyTaxes"
            type="button"
            class="btn btn-sm btn-danger btn-cancel ml-2"
            @click="cancelEdition"
            :disabled="loading || processing"
          >
            {{ $t("COMMON.CANCEL") }}
          </button>
        </div>
      </div>
    </el-form>

    <modal
      :show="showTaxesGroupCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("TAXES_GROUPS.SAME_TAXES_GROUPS", {
                name: taxesGroup.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="taxesGroups"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
                <template v-slot="{ row }">
                  {{ row.excerpt ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleTaxesGroupCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Row,
  Col,
  Radio,
  MessageBox,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import TaxeSelector from "../../TaxeManagement/components/TaxeSelector.vue";
import { CATEGORY_FEE, CATEGORY_COMMISSION } from "@/constants/taxes";
export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    TaxeSelector,
  },

  mixins: [formMixin],

  props: [
    "taxesGroupData",
    "formErrors",
    "loading",
    "hideSubmit",
    "onlyTaxes",
  ],
  data() {
    let taxesGroupData = { ...this.taxesGroupData };
    return {
      taxesGroup: taxesGroupData,
      taxesGroups: [],
      total: 0,
      showModal: false,
      showTaxesGroupCheckModal: false,
      processing: false,
      categoryFee: CATEGORY_FEE,
      categoryCommission: CATEGORY_COMMISSION,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    feeSelected(selected) {
      this.taxesGroup.taxes = [...selected].map((item) => ({
        type: "taxes",
        id: item,
      }));
    },
    async handleSubmit() {
      const checkUniqueTaxesGroup =
        await this.checkTaxesGroupUniqueInformation();
      if (checkUniqueTaxesGroup) {
        await this.sendInformation();
      } else {
        this.showTaxesGroupCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.taxesGroup.name) {
        this.taxesGroup.name = this.taxesGroup.name.toString();
      }
      let taxesGroupData = cloneDeep(this.taxesGroup);
      this.$emit("taxesGroupSubmitted", taxesGroupData);
    },

    cancelEdition() {
      this.$emit("cancelEdition");
    },

    getTaxesGroupIds(taxes) {
      if (taxes && taxes.length) {
        return taxes.map((taxe) => taxe.id);
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.taxesGroup?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.taxesGroup?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("taxesGroups/list", params);
        this.taxesGroups = this.$store.getters["taxesGroups/list"];
        this.total = this.$store.getters["taxesGroups/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkTaxesGroupUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.taxesGroup?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleTaxesGroupCheckModalState();
      await this.sendInformation();
    },

    async toggleTaxesGroupCheckModalState() {
      this.showTaxesGroupCheckModal = !this.showTaxesGroupCheckModal;
    },

    checkTaxesGroupInformation() {
      this.$refs["taxesGroupForm"].validate(async (valid) => {
        if (valid) {
          if (this.taxesGroup.name) {
            this.handleSubmit();
            this.showModal = false;
          } else {
            this.showModal = true;
          }
        }
      });
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    taxesGroupData(taxesGroupData) {
      if (taxesGroupData) {
        this.taxesGroup = {
          ...this.taxesGroup,
          ...cloneDeep(taxesGroupData),
        };
      }
    },
  },
};
</script>

<style>
.add-form .btn-cancel {
  font-size: 14px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}
</style>
