<template>
  <div class="container-fluid">
    <taxe-form
      :loading="loading"
      :taxeData="taxe"
      :formErrors="formErrors"
      @taxeSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultTaxe from "../defaultTaxe";
import TaxeForm from "../partials/TaxeForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    TaxeForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      taxe: cloneDeep(defaultTaxe),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(taxe) {
      this.loading = true;

      const taxeData = cloneDeep(taxe);
      delete taxeData.id;

      try {
        await this.$store.dispatch("taxes/add", taxeData);
        this.$notify({
          type: "success",
          message: this.$t("TAXES.TAXE_ADDED"),
        });
        const taxe = await this.$store.getters["taxes/taxe"];
        this.$emit("onViewTaxe", taxe, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
