<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!taxesGroup">
      <span class="loader"></span>
    </span>
    <taxes-group-form
      v-if="taxesGroup"
      :loading="loading"
      :taxesGroupData="taxesGroup"
      :formErrors="formErrors"
      :only-taxes="onlyTaxes"
      @taxesGroupSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @cancelEdition="cancelEdition"
    />
  </div>
</template>

<script>
import TaxesGroupForm from "../partials/TaxesGroupForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { TaxesGroupForm },

  mixins: [alertLeave],

  props: {
    taxesGroupId: {
      type: [String, Number],
      required: true,
    },
    onlyTaxes: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      taxesGroup: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    cancelEdition() {
      this.$emit("cancelEdition");
    },
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("taxesGroups/get", this.taxesGroupId);
        this.taxesGroup =
          this.$store.getters["taxesGroups/taxesGroup"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(taxesGroup) {
      this.loading = true;
      const taxesGroupData = cloneDeep(taxesGroup);

      try {
        await this.$store.dispatch("taxesGroups/update", taxesGroupData);
        this.$notify({
          type: "success",
          message: this.$t("TAXES_GROUPS.TAXES_GROUP_UPDATED"),
        });
        const taxesGroup = await this.$store.getters[
          "taxesGroups/taxesGroup"
        ];
        this.$emit("onViewTaxesGroup", taxesGroup, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
