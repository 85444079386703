<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <base-input :placeholder="$t('COMMON.COUNTRY')">
          <country-selector
            clearable
            :filterable="true"
            :showAll="false"
            @countryChanged="countryChanged"
          />
        </base-input>
        <base-input :placeholder="$t('COMMON.STATE')">
          <state-selector
            clearable
            :country="selectedCountry"
            :filterable="true"
            :showAll="false"
            @stateChanged="stateChanged"
          />
        </base-input>

        <!-- <base-input>
          <el-select
            :label="$t('TAXES.TRANSACTION')"
            :placeholder="$t('TAXES.TRANSACTION')"
            v-model="selectedTransactionType"
            @change="
              (type) => {
                selectedTransactionType = type;
              }
            "
          >
            <el-option
              v-for="(taxe, val) in taxeTransactionTypes"
              :key="val"
              :value="val"
              :label="`${val ? $t('TAXES.' + val) : ''}`"
            />
          </el-select>
        </base-input> -->

        <base-input>
          <el-select
            clearable
            :label="$t('TAXES.TYPE')"
            :placeholder="$t('TAXES.TYPE')"
            v-model="selectedType"
            @change="
              (type) => {
                selectedType = type;
              }
            "
          >
            <el-option
              v-for="(taxe, val) in taxeTypes"
              :key="val"
              :value="val"
              :label="`${val ? $t('TAXES.' + val) : ''}`"
            />
          </el-select>
        </base-input>

        <base-input
          v-model="query"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
        <base-button
          class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <i class="fa fa-undo"></i>
          </span>
        </base-button>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="taxes"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="300"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.name ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('TAXES.DENOMINATION')" min-width="150">
              <template v-slot="{ row }">
                {{ row.label }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TAXES.VALUE')" min-width="200">
              <template v-slot="{ row }">
                <span v-if="row.calculation_type == TYPE_POURCENTAGE"
                  >{{ row.cost }} %</span
                >
                <span v-else-if="row.calculation_type == TYPE_MONTANT_FIXE">{{
                  $formatCurrency(row.cost)
                }}</span>
                <span v-else>{{ $t("TAXES." + row.calculation_type) }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column :label="$t('TAXES.TRANSACTION')" min-width="150">
              <template v-slot="{ row }">
                {{ $t("TAXES." + row?.tax_type) }}
              </template>
            </el-table-column> -->
            <el-table-column :label="$t('TAXES.ACTIVE')" min-width="100">
              <template v-slot="{ row }">
                <badge v-if="row.active" type="success">
                  {{ $t("TAXES.ACTIVE") }}
                </badge>
                <badge v-else type="secondary">
                  {{ $t("TAXES.DEACTIVATED") }}
                </badge>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('TAXES.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
                >
                  <a
                    type="text"
                    @click="viewTaxe(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_TAXES)"
                >
                  <a
                    type="text"
                    @click="editTaxe(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_TAXES)"
                >
                  <a
                    type="text"
                    @click="deleteTaxe(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
  Tag,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import {
  TAXE_TYPES,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  TAXE_TRANSACTIONS_TYPES,
} from "@/constants/taxes";

export default {
  name: "taxe-list-table",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Tag.name]: Tag,
    CountrySelector,
    StateSelector,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      taxes: [],
      loading: true,
      selectedType: null,
      selectedTransactionType: null,
      taxeTypes: TAXE_TYPES,
      taxeTransactionTypes: TAXE_TRANSACTIONS_TYPES,
      TYPE_POURCENTAGE: TYPE_POURCENTAGE,
      TYPE_MONTANT_FIXE: TYPE_MONTANT_FIXE,
      selectedCountry: null,
      selectedState: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    selectedCountry: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedState: {
      handler: "getListDebounced",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTransactionType: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    countryChanged(country) {
      this.selectedCountry = country;
    },
    stateChanged(state) {
      this.selectedState = state;
    },

    resetFilters() {
      this.query = null;
      this.selectedType = null;
      this.selectedTransactionType = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        if (this.selectedState) {
          params.filter.state = this.selectedState;
        }

        if (this.selectedCountry) {
          params.filter.country = this.selectedCountry;
        }

        if (this.selectedType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              calculation_type: this.selectedType,
            },
          };
        }

        if (this.selectedTransactionType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              tax_type: this.selectedTransactionType,
            },
          };
        }

        await this.$store.dispatch("taxes/list", params);
        this.taxes = this.$store.getters["taxes/list"];
        this.total = this.$store.getters["taxes/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editTaxe(taxe) {
      this.$emit("onEditTaxe", taxe);
    },

    async deleteTaxe(id) {
      const confirmation = await swal.fire({
        text: this.$t("TAXES.DELETE_THIS_TAXE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("taxes/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("TAXES.TAXE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewTaxe(taxe) {
      this.$emit("onViewTaxe", taxe);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
