<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form class="" @submit.prevent="">
      <!-- <base-input
        :label="`${$t('SALES_INVOICES.ITEM_TYPE')}`"
        v-if="!salesInvoiceItem.id"
      >
        <el-select
          name="Type"
          v-model="salesInvoiceItemModel.salesInvoiceable.type"
          prepend-icon="fas fa-calendar"
          @change="changeSalesInvoiceableType"
        >
          <el-option :value="null" :label="$t('COMMON.NONE')" />
          <el-option
            v-if="salesInvoice.organization"
            value="products"
            :label="$t('COMMON.PRODUCT')"
          />
          <el-option
            v-if="
              !$currentUserIsLevelOrganization() &&
              !salesInvoice.organization
            "
            value="packages"
            :label="$t('COMMON.PACKAGE')"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.salesInvoiceable" /> -->

      

      <base-input :label="`${$t(`COMMON.PRODUCT`)} (*)`">
        <product-selector
          :product="salesInvoiceItemModel.product_id"
          :allowNone="false"
          :showAll="false"
          :disabled="!!salesInvoiceItemModel.id"
          @productChanged="
            (productId, product) => {
              salesInvoiceItemModel.code = product.name;
              salesInvoiceItemModel.excerpt = product.excerpt ?? '';
              salesInvoiceItemModel.unit_price =  product.selling_price;
              salesInvoiceItemModel.product_id = productId;
              salesInvoiceItemModel.sku = product.sku;
              taxes_group_id = product.taxes_group_id;
            }
          "
        />
      </base-input>
      
      <base-input
        :label="`${$t('COMMON.DESIGNATION')} (*)`"
        :placeholder="$t('COMMON.DESIGNATION')"
        v-model="salesInvoiceItemModel.code"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="salesInvoiceItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <base-input
        :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
        :placeholder="$t('COMMON.UNIT_PRICE')"
        v-model="salesInvoiceItemModel.unit_price"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.unit_price" />

      <base-input
        :label="`${$t('COMMON.QUANTITY')} (*)`"
        :placeholder="$t('COMMON.QUANTITY')"
        v-model="salesInvoiceItemModel.quantity"
        type="number"
        step="1"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.quantity" />

      
      <div v-if="salesInvoiceItemModel.product_id" class="taxes mt-3 mb-2">
        <view-taxes-group
          v-if="taxes_group_id"
          :taxes-group-id="taxes_group_id"
        />
        <span v-else slot="title text-gray bold">
          <taxe-icon :width="'20px'" class="custom-svg-icon" />
          {{ $t("TAXES.NO_TAXABLE_PRODUCT") }}
        </span>
      </div>

     <!--  <taxes-selector
        :label="$t('COMMON.TAXES')"
        :taxes="salesInvoiceItemModel.taxes"
        @taxesChanged="(taxes) => (salesInvoiceItemModel.taxes = taxes)"
      />
      <validation-error :errors="apiValidationErrors.taxes" />
 -->
      <!--<base-input
        :label="$t('COMMON.DISCOUNT_PERCENT')"
        :placeholder="$t('COMMON.DISCOUNT_PERCENT')"
        v-model="salesInvoiceItemModel.discount"
        type="number"
        step="0.0001"
        input-classes="form-control-alternative"
      >
      </base-input>
      <validation-error :errors="apiValidationErrors.discount" />-->
    </form>

    <template slot="footer">
      <button
        v-if="!salesInvoiceItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitSalesInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_INVOICES.ADD_SALES_INVOICE_ITEM") }}
      </button>

      <base-button
        v-if="salesInvoiceItemModel.id"
        native-type="submit"
        type="primary"
        class="new-sales-invoice-item--add"
        @click="submitSalesInvoiceItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_INVOICES.UPDATE_SALES_INVOICE_ITEM") }}
      </base-button>

      <base-button
        v-if="salesInvoiceItemModel.id"
        type="danger"
        @click="deleteSalesInvoiceItem(salesInvoiceItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("SALES_INVOICES.DELETE_SALES_INVOICE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeSalesInvoiceItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultSalesInvoiceItem from "../defaultSalesInvoiceItem";
import ViewTaxesGroup from "@/views/Pages/AdminModule/TaxesGroupManagement/components/ViewTaxesGroup.vue";
import TaxeIcon from "@/components/Icons/TaxeIcon.vue";
import ProductSelector from "@/components/ProductSelector.vue";

export default {
  name: "sales-invoice-view-details-sales-invoice-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ViewTaxesGroup,
    TaxeIcon,
    ProductSelector
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "salesInvoice",
    "salesInvoiceItem",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      taxes_group_id: null,
      all_salesInvoiceables: [],
      salesInvoiceItemModel: cloneDeep(defaultSalesInvoiceItem),
    };
  },

  computed: {},

  methods: {
    getOrderIds() {
      if (!this.salesInvoice.salesOrder) {
        return null;
      }
      if (this.salesInvoice.salesOrder.invoicingItemsState) {
        const remainingProductsIds = Object.values(
          this.salesInvoice.salesOrder.invoicingItemsState.remainingItems
        ).map((item) => item.product_id);
        if (remainingProductsIds.length > 0) {
          return remainingProductsIds;
        }
      }
      return null;
    },

    closeSalesInvoiceItemModal() {
      this.$emit("onCloseSalesInvoiceItemModal", true);
    },

    submitSalesInvoiceItemForm() {
      const salesInvoiceItemData = cloneDeep(this.salesInvoiceItemModel);
      salesInvoiceItemData.unit_price = parseFloat(
        salesInvoiceItemData.unit_price
      );
      salesInvoiceItemData.quantity = parseInt(
        salesInvoiceItemData.quantity,
        10
      );
      salesInvoiceItemData.discount = parseFloat(salesInvoiceItemData.discount);
      if (salesInvoiceItemData.salesInvoiceable) {
        delete salesInvoiceItemData.salesInvoiceable;
      }
      this.$emit("onSubmitSalesInvoiceItemForm", salesInvoiceItemData);
    },

    deleteSalesInvoiceItem(salesInvoiceItemId) {
      this.$emit("onDeleteSalesInvoiceItem", salesInvoiceItemId);
    },

    productChanged(productId, product) {
      if (product.warehouseProduct) {
        this.salesInvoiceItemModel.salesInvoiceable = {
          type: "warehouse-products",
          id: product.warehouseProduct.id.toString(),
        };
        this.salesInvoiceItemModel.unit_price =
          product.warehouseProduct.selling_price;
        this.salesInvoiceItemModel.code =
          product.warehouseProduct.sku || product.sku || product.code;
        this.salesInvoiceItemModel.excerpt = product.denomination;
        this.salesInvoiceItemModel.taxes = product.warehouseProduct.taxes;
      } else {
        this.salesInvoiceItemModel.salesInvoiceable.id = productId;
        this.salesInvoiceItemModel.unit_price = product.price;
        this.salesInvoiceItemModel.code = product.code;
        this.salesInvoiceItemModel.excerpt = product.denomination;
        this.salesInvoiceItemModel.taxes = product.taxes;
      }
      this.salesInvoiceItemModel.salesInvoiceable.product_id = product.id;
    },
  },

  mounted() {},

  watch: {
    salesInvoiceItem(salesInvoiceItem) {
      if (salesInvoiceItem) {
        this.salesInvoiceItemModel = cloneDeep(salesInvoiceItem);
      }
      this.resetApiValidation();
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
