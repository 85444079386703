<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("TAXES_GROUPS.TAXES_GROUPS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button btn-black add"
              icon
              size="sm"
              @click="openTaxeList"
              v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-list"></i>
              </span>
              <span class="btn-inner--text">{{ $t("TAXES.TAXES") }}</span>
            </base-button>

            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openTaxesGroupCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_TAXES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TAXES_GROUPS.ADD_TAXES_GROUP") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
              :objectType="'taxesGroups'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <taxes-group-list-table
        @onViewTaxesGroup="openTaxesGroupViewModal"
        @onEditTaxesGroup="openTaxesGroupEditModal"
        @onDeleteTaxesGroup="deleteTaxesGroup"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewTaxesGroupModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewTaxesGroupModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TAXES_GROUP_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TAXES_GROUPS.VIEW_TAXES_GROUP") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openTaxesGroup"
                :objectType="'taxesGroups'"
                :objectId="openTaxesGroup.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <!-- <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openTaxesGroupEditModal(openTaxesGroup)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteTaxesGroup(openTaxesGroup)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown> -->

              <button class="close" @click="closeTaxesGroupModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-taxes-group-component
              v-if="openTaxesGroup"
              :taxesGroupId="openTaxesGroup.id"
              @onEditTaxesGroup="openTaxesGroupEditModal"
              @onDeleteTaxesGroup="deleteTaxesGroup"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditTaxesGroupModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditTaxesGroupModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TAXES_GROUP'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TAXES_GROUPS.EDIT_TAXES_GROUP") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openTaxesGroupViewModal(openTaxesGroup)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeTaxesGroupModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-taxes-group-component
              v-if="openTaxesGroup"
              :taxesGroupId="openTaxesGroup.id"
              @onViewTaxesGroup="openTaxesGroupViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddTaxesGroupModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddTaxesGroupModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TAXES_GROUP'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TAXES_GROUPS.ADD_TAXES_GROUP") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeTaxesGroupModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-taxes-group-component
              @onViewTaxesGroup="openTaxesGroupViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import TaxesGroupListTable from "./partials/TaxesGroupListTable.vue";
import EditTaxesGroupComponent from "./components/EditTaxesGroupComponent.vue";
import AddTaxesGroupComponent from "./components/AddTaxesGroupComponent.vue";
import ViewTaxesGroupComponent from "./components/ViewTaxesGroupComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    TaxesGroupListTable,
    NotificationSubscription,
    EditTaxesGroupComponent,
    AddTaxesGroupComponent,
    ViewTaxesGroupComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const taxesGroupId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewTaxesGroupModalOpened = false;
    let isEditTaxesGroupModalOpened = false;
    let isAddTaxesGroupModalOpened = false;
    let openTaxesGroup = null;
    if (taxesGroupId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewTaxesGroupModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditTaxesGroupModalOpened = true;
      }
      openTaxesGroup = { id: taxesGroupId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddTaxesGroupModalOpened = true;
    }
    return {
      isViewTaxesGroupModalOpened: isViewTaxesGroupModalOpened,
      isEditTaxesGroupModalOpened: isEditTaxesGroupModalOpened,
      isAddTaxesGroupModalOpened: isAddTaxesGroupModalOpened,
      openTaxesGroup: openTaxesGroup,
      renderKey: 1,
    };
  },

  methods: {
    openTaxesGroupCreateModal() {
      this.closeTaxesGroupModal();
      this.isAddTaxesGroupModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TaxesGroups",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openTaxesGroupViewModal(taxesGroup, reRender = false) {
      this.closeTaxesGroupModal();
      this.openTaxesGroup = taxesGroup;
      this.isViewTaxesGroupModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TaxesGroups",
          query: { id: this.openTaxesGroup.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openTaxeList() {
      this.$router.push({
        name: "List Taxes",
      });
    },

    openTaxesGroupEditModal(taxesGroup) {
      this.closeTaxesGroupModal();
      this.openTaxesGroup = taxesGroup;
      this.isEditTaxesGroupModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TaxesGroups",
          query: { id: this.openTaxesGroup.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeTaxesGroupModal() {
      this.isAddTaxesGroupModalOpened = false;
      this.isViewTaxesGroupModalOpened = false;
      this.isEditTaxesGroupModalOpened = false;
      this.openTaxesGroup = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List TaxesGroups",
          query: {},
        }).href
      );
    },

    async deleteTaxesGroup(taxesGroup) {
      const confirmation = await swal.fire({
        text: this.$t("TAXES_GROUPS.DELETE_THIS_TAXES_GROUP"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "taxesGroups/destroy",
            taxesGroup.id
          );
          this.renderKey++;
          this.closeTaxesGroupModal();
          this.$notify({
            type: "success",
            message: this.$t("TAXES_GROUPS.TAXES_GROUP_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
