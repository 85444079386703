export default {
  type: "sales-invoice-items",
  code: null,
  excerpt: "",
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["salesInvoice", "salesInvoiceable"],
  salesInvoice: {
    type: "sales-invoices",
    id: null,
  },
  salesInvoiceable: {
    type: "products",
    id: null,
  },
  pricing: {}
};
