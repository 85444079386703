<template>
  <div class="charges-selector">
    <div class="filters" v-if="withFilters">
      <div class="row" v-if="selectedCategory != categoryCommission">
        <div v-if="selectedCategory == null" class="form-wrapper col-md-6 p-2">
          <el-select
            :name="`${$t('CHARGES.CATEGORY')}`"
            :placeholder="$t('CHARGES.CATEGORY')"
            v-model="selectedChargeCategory"
          >
            <el-option
              v-for="(charge, val) in chargeCategories"
              :key="val"
              :value="val"
              :label="`${val ? $t('CHARGES.' + val) : ''}`"
            />
          </el-select>
        </div>
        <div
          v-if="selectedTransactionType == null"
          class="form-wrapper col-md-6 p-2"
        >
          <el-select
            :name="`${$t('CHARGES.TRANSACTION')}`"
            :placeholder="$t('CHARGES.TRANSACTION')"
            v-model="selectedChargeTransactionType"
          >
            <el-option
              v-for="(transaction, val) in chargeTransactions"
              :key="val"
              :value="val"
              :label="`${val ? $t('CHARGES.' + val) : ''}`"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-wrapper col-md-12 p-2">
        <el-select
          v-model="chargeModel"
          @change="chargeChanged"
          autocomplete="new-password"
          :placeholder="$t('CHARGES.SELECT')"
          :filterable="filterable"
          :multiple="multiple"
          :disabled="disabled"
          remote
          :remote-method="getList"
          :loading="loading"
          :allow-create="allowCreate"
          class="w-100"
        >
          <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
          </el-option>
          <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
          </el-option>
          <el-option
            v-for="charge in charges"
            :key="charge.id"
            :value="charge.id"
            :label="charge.name"
          >
            <template #default>
              <span class="charge-category text-uppercase text-gray">
                {{ $t("CHARGES." + charge.category) }} -
              </span>
              <span class="charge-name">{{ charge.name }}</span>

              <em
                class="text-gray"
                v-if="charge.charge_type == typePourcentage"
              >
                - {{ charge.percentage }} %</em
              >
              <em
                class="text-gray"
                v-else-if="charge.charge_type == typeMontantFixe"
              >
                - {{ $formatCurrency(charge.amount) }}</em
              >
              <em class="text-gray" v-else>
                - {{ $t("CHARGES." + charge.charge_type) }}</em
              >
            </template>
          </el-option>
          <el-option
            disabled
            v-if="charges.length == 0"
            :value="null"
            :label="$t('CHARGES.NO_CHARGES_FOUND')"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="withFilters" class="col-md-12">
        <!-- Liste des charges sélectionnées -->
        <div v-if="selectedCharges.length > 0" class="selected-charges mt-4">
          <h5 class="text-uppercase text-center">
            {{ $t("CHARGES.SELECTED_CHARGES") }}
          </h5>
          <div class="selected-charges-list">
            <div class="row">
              <!-- Liste pour les charges de type "vente" -->
              <div v-if="salesCharges.length > 0" class="sales col-md-6">
                <h5 class="text-center text-uppercase">
                  {{ $t("CHARGES.sales") }}
                </h5>
                <draggable
                  v-model="selectedCharges"
                  class="draggable-list"
                  :group="'charges'"
                  @change="emitChargeChangedEvent"
                >
                  <div
                    v-for="(charge, index) in selectedCharges"
                    :key="index"
                    class="charge-item-p"
                  >
                    <div
                      class="charge-item d-flex justify-content-between align-items-center mb-2 border rounded"
                      v-if="isSalesTransactionCharge(charge)"
                    >
                      <div class="charge-name float-left">
                        <span class="btn-inner--text mr-2">
                          <i class="fa fa-bars"></i>
                        </span>
                        <div class="full-name float-right">
                          <span class="charge-category text-uppercase text-gray"
                            >{{ $t("CHARGES." + charge.category) }} -
                          </span>
                          <span class="charge-name">{{ charge.name }}</span>
                          <em class="charge-type text-gray">
                            - {{ $t("CHARGES." + charge.charge_type) }}</em
                          >
                        </div>
                      </div>
                      <span
                        @click="removeCharge(charge.id)"
                        class="cursor-pointer btn-inner--text text-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </div>
                  </div>
                </draggable>
              </div>
              <!-- Liste pour les charges de type "achat" -->
              <div v-if="purchaseCharges.length > 0" class="purchases col-md-6">
                <h5 class="text-center text-uppercase">
                  {{ $t("CHARGES.purchases") }}
                </h5>
                <draggable
                  v-model="selectedCharges"
                  class="draggable-list"
                  :group="'charges'"
                  @change="emitChargeChangedEvent"
                >
                  <div
                    v-for="(charge, index) in selectedCharges"
                    :key="index"
                    class="charge-item-p"
                  >
                    <div
                      v-if="!isSalesTransactionCharge(charge)"
                      class="charge-item d-flex justify-content-between align-items-center mb-2 border rounded"
                    >
                      <div class="charge-name float-left">
                        <span class="btn-inner--text mr-2">
                          <i class="fa fa-bars"></i>
                        </span>
                        <div class="full-name float-right">
                          <span class="charge-category text-uppercase text-gray"
                            >{{ $t("CHARGES." + charge.category) }} -
                          </span>
                          <span class="charge-name">{{ charge.name }}</span>
                          <em class="charge-type text-gray">
                            - {{ $t("CHARGES." + charge.charge_type) }}</em
                          >
                        </div>
                      </div>
                      <span
                        @click="removeCharge(charge.id)"
                        class="cursor-pointer btn-inner--text text-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import draggable from "vuedraggable";
import _ from "lodash";
import {
  TYPE_PALIER,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  CHARGE_CATEGORIES,
  CHARGE_TRANSACTIONS_TYPES,
  CHARGE_TYPES,
  CATEGORY_COMMISSION,
  CATEGORY_FEE,
  CATEGORY_TAXE,
  CALCULATION_BASES,
  TYPE_TRASANCTION_SALES,
  TYPE_TRASANCTION_PURCHASES,
} from "@/constants/charges";

export default {
  name: "charge-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
  },

  props: {
    withFilters: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    allowNone: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    charge: {
      type: [String, Array],
      default: null,
    },
    selectedCategory: {
      type: String,
      default: null,
    },
    selectedTransactionType: {
      type: String,
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedChargeTransactionType: null,
      selectedChargeCategory: null,
      chargeCategories: CHARGE_CATEGORIES,
      chargeTransactions: CHARGE_TRANSACTIONS_TYPES,
      categoryCommission: CATEGORY_COMMISSION,
      categoryFee: CATEGORY_FEE,
      categoryTaxe: CATEGORY_TAXE,
      calculationBases: CALCULATION_BASES,
      typePalier: TYPE_PALIER,
      typePourcentage: TYPE_POURCENTAGE,
      typeMontantFixe: TYPE_MONTANT_FIXE,
      chargeTypes: CHARGE_TYPES,
      TYPE_TRASANCTION_PURCHASES: TYPE_TRASANCTION_PURCHASES,
      TYPE_TRASANCTION_SALES: TYPE_TRASANCTION_SALES,
      sort: "-created_at",
      chargeModel: this.charge,
      charges: [],
      selectedCharges: [],
      loading: false,
      query: "",
    };
  },

  created() {
    this.selectedChargeCategory = this.selectedCategory;
    this.selectedChargeTransactionType = this.selectedTransactionType;
    this.getList(null, this.charge);
  },
  computed: {
    salesCharges() {
      return this.selectedCharges.filter(
        (charge) => charge.transaction_type === this.TYPE_TRASANCTION_SALES
      );
    },
    purchaseCharges() {
      return this.selectedCharges.filter(
        (charge) => charge.transaction_type === this.TYPE_TRASANCTION_PURCHASES
      );
    },
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    // Fonction pour récupérer les charges avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.selectedType) {
          params.filter.charge_type = this.selectedType;
        }

        if (this.selectedChargeCategory) {
          params.filter.category = this.selectedChargeCategory;
        }

        if (this.selectedChargeTransactionType) {
          params.filter.transaction_type = this.selectedChargeTransactionType;
        }

        await this.$store.dispatch("charges/list", params);
        const chargesArr = await this.$store.getters["charges/list"];
        this.charges = chargesArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    isSalesTransactionCharge(charge) {
      return charge.transaction_type === this.TYPE_TRASANCTION_SALES;
    },

    chargeChanged(selectedChargeIds) {
      const currentSelectedCharges = [...this.selectedCharges];

      const fromCurrentSelected = currentSelectedCharges.filter((charge) =>
        selectedChargeIds.includes(charge.id)
      );

      // Filtrer les charges restantes dans this.charges
      const fromAllCharges = this.charges.filter(
        (charge) =>
          selectedChargeIds.includes(charge.id) &&
          !currentSelectedCharges.some((c) => c.id === charge.id)
      );

      // Combiner les deux
      const newSelectedCharges = [...fromCurrentSelected, ...fromAllCharges];
      this.selectedCharges = newSelectedCharges;
      this.$emit("chargeChanged", selectedChargeIds);
    },

    removeCharge(chargeId) {
      // Supprimer la charge de `selectedCharges`
      this.selectedCharges = this.selectedCharges.filter(
        (charge) => charge.id !== chargeId
      );

      // Supprimer l'ID de `chargeModel`
      this.chargeModel = this.chargeModel.filter((id) => id !== chargeId);

      this.emitChargeChangedEvent();
    },

    emitChargeChangedEvent() {
      const selectedChargeIds = this.selectedCharges.map((charge) => charge.id);
      this.$emit("chargeChanged", selectedChargeIds);
    },
  },

  watch: {
    selectedChargeTransactionType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedChargeCategory: {
      handler: "getListDebounced",
      immediate: true,
    },
    charge(newCharge) {
      if (newCharge) {
        this.chargeModel = newCharge;
        // Si la valeur a changé, on recharge la liste avec l'ID ou les IDs
        if (newCharge !== this.chargeModel) {
          this.getList(null, newCharge);
        }
      } else {
        this.chargeModel = null;
      }
    },
  },
};
</script>

<style>
.draggable-list {
  list-style: none;
  padding: 0;
}
.charge-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 0px 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.charge-category {
  color: gray;
  font-size: 13px;
}
.charge-type {
  font-size: 11px;
}
</style>
