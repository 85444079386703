var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-manager-container file-manager-list container-fuild"},[_c('div',{staticClass:"file-manager-list-wrapper"},[_c('div',{staticClass:"col-12 mb-2 row item-row file-manager-list-wrapper-header"},[_c('div',{staticClass:"col-1 folder-icon"}),_c('div',{staticClass:"col-7 row px-0 details"},[_c('div',{staticClass:"file-item-name col-12"},[_vm._v(_vm._s(_vm.$t("COMMON.NAME")))])]),_c('div',{staticClass:"col-2 details-right"},[_c('span',{staticClass:"updated-at"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.UPDATED_AT"))+" ")])]),_c('div',{staticClass:"col-2 details-right"},[_c('span',{staticClass:"updated-at"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CREATED_AT"))+" ")])])]),(_vm.folder.id)?_c('div',{staticClass:"col-12 mb-1 row file-manager-list-wrapper-item navigation"},[_c('div',{staticClass:"col-1 folder-icon"},[_c('div',{staticClass:"file-item file-item-folder",on:{"dblclick":function($event){return _vm.navigateToParent()}}},[_vm._m(0)])]),_c('div',{staticClass:"col-11"})]):_vm._e(),_vm._l((_vm.folder.subfolders),function(folder){return _c('div',{key:`folder-${folder.id}`,staticClass:"col-12 mb-1 row item-row file-manager-list-wrapper-item"},[_c('div',{staticClass:"col-1 folder-icon"},[_c('div',{staticClass:"file-item file-item-folder",class:[
            {
              selected: _vm.selectedItems.some(
                (item) => item.id === folder.id && item.type == 'folders'
              ),
              locked: folder.locked,
            },
          ],on:{"dblclick":function($event){return _vm.navigateToFolder(folder.id)},"click":function($event){return _vm.selectItem(folder)}}},[_vm._m(1,true),_vm._m(2,true)])]),_c('div',{staticClass:"col-7 row px-0 details"},[_c('div',{staticClass:"file-item-name col-12"},[_vm._v(" "+_vm._s(folder.name)+" ")]),(
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            folder.organization
          )?_c('div',{staticClass:"file-item-organization col-12"},[_c('span',{staticClass:"btn btn-sm btn-info"},[_c('i',{staticClass:"ni ni-building"}),_vm._v(" "+_vm._s(folder.organization.name)+" ")])]):_vm._e()]),_c('div',{staticClass:"col-2 details-right"},[_c('span',{staticClass:"updated-at"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(folder.updated_at))+" ")])]),_c('div',{staticClass:"col-2 details-right"},[_c('span',{staticClass:"updated-at"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(folder.created_at))+" ")])])])}),_vm._l((_vm.folder.files),function(file){return _c('div',{key:`file-${file.id}`,staticClass:"col-12 mb-1 row item-row file-manager-list-wrapper-item"},[_c('div',{staticClass:"col-1 file-icon"},[_c('div',{staticClass:"file-item file-item-folder",class:[
            {
              selected: _vm.selectedItems.some(
                (item) => item.id === file.id && item.type == 'files'
              ),
            },
          ],on:{"dblclick":function($event){return _vm.viewFile(file)},"click":function($event){return _vm.selectItem(file)}}},[_vm._m(3,true)])]),_c('div',{staticClass:"col-7 row px-0 details"},[_c('div',{staticClass:"file-item-name col-12"},[_vm._v(" "+_vm._s(file.name)+" ")]),(
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            _vm.folder.organization
          )?_c('div',{staticClass:"file-item-organization col-12"},[_c('span',{staticClass:"btn btn-sm btn-info"},[_c('i',{staticClass:"ni ni-building"}),_vm._v(" "+_vm._s(file.organization.name)+" ")])]):_vm._e()]),_c('div',{staticClass:"col-2 details-right"},[_c('span',{staticClass:"updated-at"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(file.updated_at))+" ")])]),_c('div',{staticClass:"col-2 details-right"},[_c('span',{staticClass:"updated-at"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(file.created_at))+" ")])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-item-content"},[_c('div',{staticClass:"file-item-icon"},[_c('i',{staticClass:"fa fa-reply"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"selected-icon"},[_c('i',{staticClass:"far fa-check"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-item-content"},[_c('div',{staticClass:"file-item-icon"},[_c('i',{staticClass:"fa fa-folder"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-item-content"},[_c('div',{staticClass:"file-item-icon"},[_c('i',{staticClass:"fa fa-file"})])])
}]

export { render, staticRenderFns }