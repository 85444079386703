<template>
  <div class="elite-tabs-wrapper-content">
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="transactions"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.CODE')"
              prop="tracking_number"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ row.code }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('TRANSACTIONS.VEHICLE')"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <vehicle :vehicle="row.vehicle" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('TRANSACTIONS.STATUS')"
              min-width="220"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <transaction-status-badge-component :transaction="row" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('TRANSACTIONS.PRICE')"
              prop="tracking_number"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatCurrency(row.price) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('TRANSACTIONS.ORGANIZATION_SELLER')"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <organization :organization="row.seller" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('TRANSACTIONS.ORGANIZATION_BUYER')"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <organization :organization="row.buyer" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_TRANSACTIONS)"
                >
                  <a
                    type="text"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <router-link :to="$objectViewRoute(row)">
                      <i class="fal fa-expand-alt"></i>
                    </router-link>
                  </a>
                </el-tooltip>

                <!--<el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_TRANSACTIONS)"
                >
                  <a
                    type="text"
                    @click="editTransaction(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>

                <el-tooltip
                  content="Delete"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_TRANSACTIONS)"
                >
                  <a
                    type="text"
                    @click="deleteTransaction(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>-->
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { French } from "flatpickr/dist/l10n/fr.js";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { TRANSACTIONS_STATUS } from "@/constants/transactions";
import TransactionStatusBadgeComponent from "@/views/Pages/AuctionsModule/TransactionManagement/components/TransactionStatusBadgeComponent";
import Location from "@/components/Location";
import Vehicle from "@/components/Vehicle";

export default {
  name: "transaction-list-table",

  components: {
    BasePagination,
    flatPicker,
    Location,
    Vehicle,
    TransactionStatusBadgeComponent,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    vehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      tracking_number: null,
      selectedRows: [],
      total: 0,
      TRANSACTIONS_STATUS,
      status: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      transactions: [],
      loading: true,
      dateRange: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    status: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "seller,buyer,sellerLocation,buyerLocation,vehicle",
        };
        if (this.vehicle) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle: this.vehicle,
            },
          };
        }

        await this.$store.dispatch("transactions/list", params);
        this.transactions = this.$store.getters["transactions/list"];
        this.total = this.$store.getters["transactions/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteTransaction(transaction) {
      this.$emit("onDeleteTransaction", transaction);
    },

    viewTransaction(transaction) {
      this.$emit("onViewTransaction", transaction);
    },

    editTransaction(transaction) {
      this.$emit("onEditTransaction", transaction);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
