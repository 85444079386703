<template>
  <form class="vehicle-form-inner" ref="form" @submit.prevent="submit">
    <div
      class="inspection-form-group width-1_2"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="vehicle.organization?.id"
          :filterable="true"
          :showAll="false"
          :disabled="disable"
          required
          @organizationChanged="
            (organizationId) => {
              vehicle.organization = {
                id: organizationId,
                type: 'organizations',
              };
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="inspection-form-group width-1_2"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <location-selector
          :location="vehicle.allowedLocations[0]?.id"
          :organization="vehicle.organization?.id"
          :disabled="disable"
          @locationChanged="
            (locationId) => {
              vehicle.allowedLocations = [
                { type: 'locations', id: locationId },
              ];
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="inspection-form-group width-1 has-button">
      <base-input
        :label="`${$t('VEHICLES.SEARCH_BY_VIN')}`"
        :placeholder="$t('VEHICLES.SEARCH_BY_VIN')"
      >
        <vehicle-api-by-vin-selector
          :filterOrganization="vehicle.organization?.id"
          :disabled="disable"
          :vin="vehicle.vin"
          :launchVinSearch="launchVinSearch"
          @vehicleSelected="onVehicleSelected"
          clearable
        />
      </base-input>

      <VinScanner @onVinScanned="onVinScanned" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.vin"
        :label="`${$t('VEHICLES.VIN')} `"
        :disabled="disable"
        :required="true"
        :placeholder="$t('VEHICLES.VIN')"
      />
      <validation-error :errors="apiValidationErrors.vin" />
    </div>

    <div class="inspection-form-group width-1_2">
      <base-input
        v-model="vehicle.sku"
        :label="`${$t('VEHICLES.SKU')} `"
        :placeholder="$t('VEHICLES.SKU')"
        :disabled="disable"
      />
      <validation-error :errors="apiValidationErrors.sku" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.model_year"
        :label="`${$t('VEHICLES.MODEL_YEAR')}`"
        :disable="disable"
        :required="true"
        :placeholder="`${$t('VEHICLES.MODEL_YEAR')}`"
      >
        <year-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.model_year"
          @yearChanged="
            (year) => {
              vehicle.model_year = year;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.model_year" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.make"
        :label="`${$t('VEHICLES.MAKE')}`"
        :placeholder="`${$t('VEHICLES.MAKE')}`"
        :disable="disable"
        :required="true"
      >
        <make-selector
          :year="vehicle.model_year"
          :disabled="disable"
          :filterable="true"
          :placeholder="$t('VEHICLES.MAKE')"
          :value="vehicle.make"
          @makeChanged="
            (make) => {
              vehicle.make = make;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.make" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.model"
        :label="`${$t('VEHICLES.MODEL')}`"
        :placeholder="`${$t('VEHICLES.MODEL')}`"
        :disable="disable"
        :required="true"
      >
        <model-selector
          :year="vehicle.model_year"
          :disabled="disable"
          :filterable="true"
          :make="vehicle.make"
          :value="vehicle.model"
          @modelChanged="
            (model) => {
              vehicle.model = model;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.model" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.series"
        :label="`${$t('VEHICLES.SERIES')}`"
        :placeholder="$t('VEHICLES.SERIES')"
        :disabled="disable"
      >
        <serie-selector
          :year="vehicle.model_year"
          :make="vehicle.make"
          :disabled="disable"
          :model="vehicle.model"
          :filterable="true"
          :value="vehicle.series"
          @serieChanged="
            (serie) => {
              vehicle.series = serie;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.series" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.style"
        :label="`${$t('VEHICLES.STYLE')}`"
        :placeholder="$t('VEHICLES.STYLE')"
        :disabled="disable"
      >
        <style-selector
          :year="vehicle.model_year"
          :make="vehicle.make"
          :model="vehicle.model"
          :serie="vehicle.serie"
          :disabled="disable"
          :filterable="true"
          :value="vehicle.style"
          @styleChanged="
            (style) => {
              vehicle.style = style;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.style" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.engine_description"
        :label="`${$t('VEHICLES.ENGINE_DESCRIPTION')}`"
        :placeholder="`${$t('VEHICLES.ENGINE_DESCRIPTION')}`"
        :disable="disable"
        :required="true"
      />

      <validation-error :errors="apiValidationErrors.engine_description" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.fuel_type"
        :label="`${$t('VEHICLES.FUEL_TYPE')} `"
        :placeholder="$t('VEHICLES.FUEL_TYPE')"
        :disabled="disable"
      >
        <fuel-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.fuel_type"
          @fuelTypeChanged="
            (fuelType) => {
              vehicle.fuel_type = fuelType;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.fuel_type" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.odometer_mileage"
        type="number"
        min="0"
        :label="`${$t('VEHICLES.ODOMETER')}`"
        :placeholder="`${$t('VEHICLES.ODOMETER')}`"
        :disable="disable"
        :required="true"
      />

      <validation-error :errors="apiValidationErrors.odometer_mileage" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.odometer_type"
        :label="`${$t('VEHICLES.ODOMETER_TYPE')}`"
        :placeholder="`${$t('VEHICLES.ODOMETER_TYPE')}`"
        :disable="disable"
        :required="true"
      >
        <odometer-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.odometer_type"
          @odometerTypeChanged="
            (type) => {
              vehicle.odometer_type = type;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.odometer_type" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.car_type"
        :label="`${$t('VEHICLES.CAR_TYPE')}`"
        :placeholder="`${$t('VEHICLES.CAR_TYPE')}`"
        :disable="disable"
        :required="true"
      >
        <car-type-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.car_type"
          @carTypeChanged="
            (type) => {
              vehicle.car_type = type;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.car_type" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.fuel_cap"
        :label="`${$t('VEHICLES.FUEL_CAP')} (L)`"
        :placeholder="`${$t('VEHICLES.FUEL_CAP')}`"
      />

      <validation-error :errors="apiValidationErrors.fuel_cap" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.horse_power"
        :label="`${$t('VEHICLES.HORSE_POWER')}`"
        :placeholder="`${$t('VEHICLES.HORSE_POWER')}`"
        :disable="disable"
      />

      <validation-error :errors="apiValidationErrors.horse_power" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.drivetrain"
        :label="`${$t('VEHICLES.TRANSMISSION')} `"
        :placeholder="$t('VEHICLES.TRANSMISSION')"
        :disabled="disable"
      >
        <drivetrain-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.drivetrain"
          @drivetrainChanged="
            (drivetrain) => {
              vehicle.drivetrain = drivetrain;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.drivetrain" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.transmission"
        :label="`${$t('VEHICLES.GEARBOX')} `"
        :placeholder="$t('VEHICLES.GEARBOX')"
        :disabled="disable"
      >
        <transmission-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.transmission"
          @transmissionChanged="
            (transmission) => {
              vehicle.transmission = transmission;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.transmission" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.nb_passengers"
        type="number"
        min="1"
        :label="`${$t('VEHICLES.NB_PASSENGERS')}`"
        :placeholder="`${$t('VEHICLES.NB_PASSENGERS')}`"
        :disable="disable"
        :required="true"
      />

      <validation-error :errors="apiValidationErrors.nb_passengers" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.nb_doors"
        type="number"
        min="1"
        :label="`${$t('VEHICLES.NB_DOORS')}`"
        :placeholder="`${$t('VEHICLES.NB_DOORS')}`"
        :disable="disable"
        :required="true"
      />

      <validation-error :errors="apiValidationErrors.nb_doors" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.outside_color"
        :label="`${$t('VEHICLES.OUTSIDE_COLOR')}`"
        :placeholder="`${$t('VEHICLES.OUTSIDE_COLOR')}`"
        :disable="disable"
        :required="true"
      >
        <vehicle-color-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.outside_color"
          :placeholder="`${$t('VEHICLES.OUTSIDE_COLOR')}`"
          @colorChanged="
            (color) => {
              vehicle.outside_color = color;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.outside_color" />
    </div>

    <div class="inspection-form-group width-1_4">
      <base-input
        v-model="vehicle.inside_color"
        :label="`${$t('VEHICLES.INSIDE_COLOR')}`"
        :placeholder="`${$t('VEHICLES.INSIDE_COLOR')}`"
        :disable="disable"
        :required="true"
      >
        <vehicle-color-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.inside_color"
          @colorChanged="
            (color) => {
              vehicle.inside_color = color;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.inside_color" />
    </div>

    <div class="inspection-form-group width-1">
      <base-input
        :label="`${$t('VEHICLES.ACCESSORIES')}`"
        :placeholder="`${$t('VEHICLES.ACCESSORIES')}`"
        :disable="disable"
        :required="true"
      >
        <accessories-selector
          :disabled="disable"
          :filterable="true"
          :value="vehicle.accessories ?? []"
          @accessoriesChanged="
            (accessories) => {
              vehicle.accessories = accessories;
            }
          "
        />
      </base-input>

      <validation-error :errors="apiValidationErrors.accessories" />
    </div>
  </form>
</template>

<script>
import _, { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import VehicleApiByVinSelector from "@/components/VehicleApiByVinSelector.vue";
import VinScanner from "@/components/VinScanner.vue";
import YearSelector from "@/components/YearSelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import StyleSelector from "@/components/StyleSelector.vue";
import FuelTypeSelector from "@/components/FuelTypeSelector.vue";
import OdometerTypeSelector from "@/components/OdometerTypeSelector.vue";
import CarTypeSelector from "@/components/CarTypeSelector.vue";
import DrivetrainSelector from "@/components/DrivetrainSelector.vue";
import TransmissionSelector from "@/components/TransmissionSelector.vue";
import VehicleColorSelector from "@/components/VehicleColorSelector.vue";
import AccessoriesSelector from "@/components/AccessoriesSelector.vue";
import { VEHICLE_CAR_TYPES } from "@/constants/vehicles";

export default {
  name: "step1",
  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationSelector,
    VehicleApiByVinSelector,
    VinScanner,
    YearSelector,
    MakeSelector,
    ModelSelector,
    SerieSelector,
    StyleSelector,
    FuelTypeSelector,
    OdometerTypeSelector,
    CarTypeSelector,
    DrivetrainSelector,
    TransmissionSelector,
    VehicleColorSelector,
    AccessoriesSelector,
  },

  mixins: [formMixin],

  props: {
    vehicleData: { type: Object },
    formErrors: {
      type: [Object, Array],
    },
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
  },

  data() {
    let vehicleData = cloneDeep(this.vehicleData);

    return {
      vehicle: vehicleData,
      launchVinSearch: false,
      VEHICLE_CAR_TYPES,
    };
  },

  computed: {},

  created() {},

  methods: {
    onVinScanned(vin) {
      console.log("onVinScanned", vin);
      this.$set(this.vehicle, "vin", vin);
      this.launchVinSearch = true;
    },
    onVehicleSelected(vehicleData) {
      this.launchVinSearch = false;

      const style = (vehicleData.style.toLowerCase() ?? "").toUpperCase();

      let carType = "";

      VEHICLE_CAR_TYPES.forEach((type) => {
        if (style.includes(type)) {
          carType = type;

          return;
        }
      });

      this.vehicle = {
        ...this.vehicle,
        id: `${vehicleData.id}`,
        vin: vehicleData.requested_vin,
        sku: vehicleData.sku ?? this.vehicle.sku ?? "",
        make: vehicleData.make.toLowerCase() ?? this.vehicle.make ?? "",
        model: vehicleData.model.toLowerCase() ?? this.vehicle.model ?? "",
        series: vehicleData.series.toLowerCase() ?? this.vehicle.series ?? "",
        model_year:
          `${vehicleData.model_year}` ?? this.vehicle.model_year ?? "",
        style: vehicleData.style.toLowerCase() ?? this.vehicle.style ?? "",
        engine_description:
          vehicleData.engine_description ??
          this.vehicle.engine_description ??
          "",
        fuel_type:
          vehicleData.fuel_type.toLowerCase() ?? this.vehicle.fuel_type ?? "",
        fuel_cap: vehicleData.fuel_cap ?? this.vehicle.fuel_cap ?? "",
        horse_power: vehicleData.base_hp ?? this.vehicle.horse_power ?? "",
        transmission:
          vehicleData.transmission ?? this.vehicle.transmission ?? "",
        drivetrain: vehicleData.drivetrain ?? this.vehicle.drivetrain ?? "",
        class_code: vehicleData.class_code ?? this.vehicle.class_code ?? "",
        class_name: vehicleData.class_name ?? this.vehicle.class_name ?? "",
        registration_number:
          vehicleData.registration_number ??
          this.vehicle.registration_number ??
          "",
        odometer_mileage:
          vehicleData.odometer_mileage ?? this.vehicle.odometer_mileage ?? "",
        odometer_type:
          vehicleData.odometer_type ?? this.vehicle.odometer_type ?? "",
        outside_color:
          vehicleData.outside_color ?? this.vehicle.outside_color ?? "",
        car_type: carType,
        inside_color:
          vehicleData.inside_color ?? this.vehicle.inside_color ?? "",
        nb_passengers: vehicleData.seat_cap ?? this.vehicle.nb_passengers ?? "",
        nb_doors: vehicleData.ext_doors ?? this.vehicle.nb_doors ?? "",
      };
    },
    isFormValid() {
      return this.$refs.form.checkValidity();
    },
    submit() {
      this.$emit("submitted", {
        ...cloneDeep(this.vehicle),
        current_step: 0,
      });
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    vehicleData(vehicleData) {
      if (vehicleData) {
        this.vehicle = cloneDeep(vehicleData);
      }
    },
    vehicle: {
      handler: function (value) {
        if (_.isEqual(value, this.vehicle)) return;

        this.$emit("formChanged", this.vehicle);
      },
      deep: true,
    },
  },
};
</script>
