<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ taxe.name }}</h3>
          <badge v-if="taxe.active" type="success">
            {{ $t("TAXES.ACTIVE") }}
          </badge>
          <badge v-else type="secondary">
            {{ $t("TAXES.DEACTIVATED") }}
          </badge>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_TAXES)"
            @click="deleteTaxe"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_TAXES)"
            @click="editTaxe"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="row mb-3 mt-2">
        <el-switch
          :disabled="taxe.is_default"
          @change="handleActivateOrDeactivate"
          style="display: block"
          v-model="taxeIsActive"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-text="$t('TAXES.ACTIVE')"
          :inactive-text="$t('TAXES.DEACTIVATED')"
        >
        </el-switch>
      </div>
      <div class="all-infos-text">
        <dl class="row" v-if="taxe.country">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ taxe.country ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.state">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ taxe.state ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ taxe.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.label">
          <dt>{{ $t("TAXES.DENOMINATION") }}</dt>
          <dd>
            {{ taxe.label ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.tax_number">
          <dt>{{ $t("TAXES.TAXE_NUMBER") }}</dt>
          <dd>
            {{ taxe.tax_number ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.tax_type">
          <dt>{{ $t("TAXES.TRANSACTION") }}</dt>
          <dd>
            {{ $t("TAXES." + taxe?.tax_type) }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.calculation_base">
          <dt>{{ $t("TAXES.CALCULATION_BASE") }}</dt>
          <dd>
            {{ $t("TAXES." + taxe?.calculation_base) }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.calculation_type == TYPE_MONTANT_FIXE">
          <dt>{{ $t("TAXES.AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(taxe.cost) }}
          </dd>
        </dl>

        <dl class="row" v-if="taxe.calculation_type == TYPE_POURCENTAGE">
          <dt>{{ $t("TAXES.PERCENTAGE") }}</dt>
          <dd>{{ taxe.cost }} %</dd>
        </dl>

        <dl class="row" v-if="taxe.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ taxe.excerpt ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(taxe.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(taxe.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="paliers my-1" v-if="taxe.tiers && taxe.tiers.length">
        <tiers-list :tiers="taxe.tiers" :title="$t('TAXES.PALIERS')" />
      </div>
    </div>
  </div>
</template>

<script>
import { Switch } from "element-ui";
import TiersList from "../components/TiersList.vue";
import { TYPE_POURCENTAGE, TYPE_MONTANT_FIXE } from "@/constants/taxes";
export default {
  name: "taxe-view-global",
  components: {
    TiersList,
    [Switch.name]: Switch,
  },
  props: ["taxe"],

  data() {
    return {
      taxeIsActive: this.taxe.active,
      TYPE_MONTANT_FIXE: TYPE_MONTANT_FIXE,
      TYPE_POURCENTAGE: TYPE_POURCENTAGE,
    };
  },

  created() {},

  methods: {
    activateOrDeactivate() {
      this.$emit("onActivateOrDeactivate", this.taxe);
    },
    async handleActivateOrDeactivate() {
      await this.activateOrDeactivate();
      this.taxeIsActive = this.taxe.active;
    },
    taxeUpdated() {
      this.$emit("taxeUpdated", true);
    },
    editTaxe() {
      this.$emit("onEditTaxe", this.taxe);
    },
    deleteTaxe() {
      this.$emit("onDeleteTaxe", this.taxe);
    },
  },

  mounted() {
    this.taxeIsActive = this.taxe.active;
  },

  watch: {
    taxe(taxe) {
      this.taxeIsActive = taxe.active;
    },
  },
};
</script>
