<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!product">
      <span class="loader"></span>
    </span>
    <div v-if="product" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <product-view-global
            :product="product"
            @onEditProduct="editProduct"
            @onDeleteProduct="deleteProduct"
          />
        </tab-pane>

        <tab-pane
          title="taxes"
          id="2"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_TAXES) &&
            product?.taxesGroup
          "
        >
          <span slot="title">
            <taxe-icon :width="'20px'" class="custom-svg-icon" />
            {{ $t("TAXES.TAXES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <div class="custom-header">
              <h3>{{ product.name }} - {{ $t("TAXES.TAXES") }}</h3>
              <base-button
                class="elite-button add"
                type="button"
                @click="toggleModalState"
              >
                <span class="btn-inner--icon">
                  <i class="far fa-edit"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("TAXES_GROUPS.EDIT_TAXES") }}
                </span>
              </base-button>
            </div>
            <view-taxes-group :taxes-group-id="product?.taxesGroup?.id" />
          </div>
        </tab-pane>

        <tab-pane title="gallery" id="2">
          <span slot="title">
            <i class="fas fa-images"></i>
            {{ $t("COMMON.GALLERY") }}
          </span>
          <product-view-gallery :product="product" />
        </tab-pane>

        <!-- <tab-pane title="supplier-products" id="3">
          <span slot="title">
            <i class="ni ni-circle-08" />
            {{ $t("COMMON.SUPPLIERS") }}
          </span>
          <product-view-supplier-products :product="product" />
        </tab-pane> -->

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="ni ni-folder-17"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="product" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <product-view-logs :product="product" />
        </tab-pane>
      </tabs>
    </div>

    <modal :show="showModal" modal-classes="modal-secondary" size="lg">
      <product-taxes-group-form
        v-if="showModal"
        :product-id="product?.id"
        :showModal="showModal"
        @onChangeModalState="toggleModalState"
        @needReload="needReload"
      />
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import ProductViewGlobal from "../partials/ProductViewGlobal.vue";
import ProductViewLogs from "../partials/ProductViewLogs.vue";
import ProductViewGallery from "../partials/ProductViewGallery.vue";
// import ProductViewSupplierProducts from "../partials/ProductViewSupplierProducts.vue";
import ViewTaxesGroup from "../../../AdminModule/TaxesGroupManagement/components/ViewTaxesGroup.vue";
import TaxeIcon from "@/components/Icons/TaxeIcon.vue";
import ProductTaxesGroupForm from "./ProductTaxesGroupForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ProductViewGlobal,
    ProductViewGallery,
    ProductViewLogs,
    ListFileComponent,
    //ProductViewSupplierProducts,
    ViewTaxesGroup,
    TaxeIcon,
    ProductTaxesGroupForm
  },

  mixins: [requestErrorMixin],

  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      product: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    needReload(product) {
      this.$emit("needReload", product);
    },
    toggleModalState() {
      this.showModal = !this.showModal;
    },
    async get() {
      try {
        await this.$store.dispatch("products/get", this.productId);
        this.product = this.$store.getters["products/product"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editProduct(product) {
      this.$emit("onEditProduct", product);
    },
    deleteProduct(product) {
      this.$emit("onDeleteProduct", product);
    },
  },
};
</script>
