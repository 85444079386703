<template>
<div class="page-wrapper-table-body">
    <div class="page-wrapper-table-body-inner">
      <div v-if="salesCharges.length" class="charges-section">
        <h6 class="section-title">{{ $t('CHARGES.sales') }}</h6>
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="salesCharges"
        >
          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            min-width="350"
          >
            <template v-slot="{ row }">
              <span class="charge-category text-uppercase text-gray">
                {{ $t("CHARGES." + row.category) }} -
              </span>
              <span class="charge-name">{{ row.name }}</span>
              <em class="charge-type text-gray">
                - {{ $t("CHARGES." + row.charge_type) }}
              </em>
            </template>
          </el-table-column>
            <el-table-column :label="$t('CHARGES.ACTIVE')" min-width="100">
              <template v-slot="{ row }">
                <badge v-if="row.is_active" type="success">
                  {{ $t("CHARGES.ACTIVE") }}
                </badge>
                <badge v-else type="secondary">
                  {{ $t("CHARGES.DEACTIVATED") }}
                </badge>
              </template>
            </el-table-column>
          <el-table-column :label="$t('CHARGES.COUT')" min-width="300">
            <template v-slot="{ row }">
              <span v-if="row.charge_type == typeMontantFixe">{{
                $formatCurrency(row.amount)
              }}</span>
              <span v-else-if="row.charge_type == typePourcentage">{{
                row.percentage + "%"
              }}</span>
              <div v-else-if="row.charge_type == typePalier && row.tiers">
                <tiers-list
                  :tiers="row.tiers"
                  :title="$t('CHARGES.PALIERS')"
                  currency="$"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" min-width="120">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGES)"
            >
              <a
                type="text"
                @click="viewCharge(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fal fa-expand-alt"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
        </el-table>
      </div>

      <div v-if="purchaseCharges.length" class="charges-section">
        <h6 class="section-title">{{ $t('CHARGES.purchases') }}</h6>
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="purchaseCharges"
        >
          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            min-width="350"
          >
            <template v-slot="{ row }">
              <span class="charge-category text-uppercase text-gray">
                {{ $t("CHARGES." + row.category) }} -
              </span>
              <span class="charge-name">{{ row.name }}</span>
              <em class="charge-type text-gray">
                - {{ $t("CHARGES." + row.charge_type) }}
              </em>
            </template>
          </el-table-column>
            <el-table-column :label="$t('CHARGES.ACTIVE')" min-width="100">
              <template v-slot="{ row }">
                <badge v-if="row.is_active" type="success">
                  {{ $t("CHARGES.ACTIVE") }}
                </badge>
                <badge v-else type="secondary">
                  {{ $t("CHARGES.DEACTIVATED") }}
                </badge>
              </template>
            </el-table-column>
          <el-table-column :label="$t('CHARGES.COUT')" min-width="300">
            <template v-slot="{ row }">
              <span v-if="row.charge_type == typeMontantFixe">{{
                $formatCurrency(row.amount)
              }}</span>
              <span v-else-if="row.charge_type == typePourcentage">{{
                row.percentage + "%"
              }}</span>
              <div v-else-if="row.charge_type == typePalier && row.tiers">
                <tiers-list
                  :tiers="row.tiers"
                  :title="$t('CHARGES.PALIERS')"
                  currency="$"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" min-width="120">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_CHARGES)"
            >
              <a
                type="text"
                @click="viewCharge(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fal fa-expand-alt"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  TYPE_PALIER,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  TYPE_TRASANCTION_PURCHASES,
  TYPE_TRASANCTION_SALES
} from "@/constants/charges";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import { Table, TableColumn } from "element-ui";
import TiersList from "./TiersList.vue";

export default {
  name: "ChargesViewTable",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TiersList,
  },
  props: {
    charges: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      typeMontantFixe: TYPE_MONTANT_FIXE,
      typePourcentage: TYPE_POURCENTAGE,
      typePalier: TYPE_PALIER,
      TYPE_TRASANCTION_PURCHASES: TYPE_TRASANCTION_PURCHASES,
      TYPE_TRASANCTION_SALES: TYPE_TRASANCTION_SALES,
    };
  },
  computed: {
    salesCharges() {
      return this.charges.filter(
        (charge) => charge.transaction_type ===  this.TYPE_TRASANCTION_SALES
      );
    },
    purchaseCharges() {
      return this.charges.filter(
        (charge) => charge.transaction_type === this.TYPE_TRASANCTION_PURCHASES
      );
    },
  },
  methods: {
    viewCharge(charge) {
      this.$router.push({
        name: "List Charges",
        query: { id: charge.id, action: QUERY_ACTIONS_VIEW },
      });
    },
  },
};
</script>


<style scoped>
.charges-section {
  margin-bottom: 30px;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}
.charge-category {
  color: gray;
  font-size: 13px;
}
.charge-type {
  font-size: 11px;
}

.elite-content .resizable-wrapper-content-body .page-wrapper-table-body-inner {
    border: none !important;
}
.elite-content .page-wrapper-table-body-inner {
    display: block !important;
}
</style>