export default {
  type: "taxes-groups",
  name: null,
  slug: null,
  excerpt: null,
  active:true,
  is_default:false,
  relationshipNames: ["taxes"],
  taxes: [],
};
