<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ $objectDenomination(vehicle) }}</h3>
        </div>

        <div class="col-6 text-right">
          <template
            v-if="
              vehicle.status?.toLowerCase() ===
              VEHICLE_STATUS_COMPLETED.toLowerCase()
            "
          >
            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="addTransactionBtn"
              @click="openCreateTransactionModal"
            >
              <!--<span class="btn-inner--icon">
                <i class="far fa-money"></i>
              </span>-->
              <span class="btn-inner--text">
                {{ $t("TRANSACTIONS.ADD_TRANSACTION") }}
              </span>
            </base-button>
            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="vehicle.is_validated !== true"
              @click="validateVehicle"
            >
              <span class="btn-inner--icon">
                <i class="far fa-check"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.VALIDATE") }}
              </span>
            </base-button>

            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="
                $currentUserCan($permissions.PERM_INVALIDATE_VEHICLES) &&
                vehicle.is_validated === true &&
                vehicle.auction_status?.toLowerCase() ===
                  VEHICLE_AUCTION_STATUS_SOON.toLowerCase()
              "
              @click="invalidateVehicle"
            >
              <span class="btn-inner--icon">
                <i class="far fa-redo"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.INVALIDATE") }}
              </span>
            </base-button>

            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="
                $currentUserCan($permissions.PERM_CLOSE_VEHICLE_AUCTION) &&
                vehicle.is_validated === true &&
                vehicle.auction_status?.toLowerCase() !==
                  VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase()
              "
              @click="closeVehicleAuction"
            >
              <span class="btn-inner--icon">
                <!-- <i class="ni ni-fat-remove icon-shape-danger"></i> -->
              </span>
              <span class="btn-inner--text">
                {{ $t("VEHICLES.CLOSE_AUCTION") }}
              </span>
            </base-button>

            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="
                vehicle.auction_status?.toLowerCase() ===
                  VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase() &&
                vehicle.auction_type === AUCTION_TYPE_EVALUATION &&
                vehicle.sale_confirmation_status ===
                  VEHICLE_SALE_CONFIRMATION_STATUS_PENDING
              "
              @click="cancelVehicleSale"
            >
              <span class="btn-inner--text">
                {{ $t("VEHICLES.CANCEL_SALE") }}
              </span>
            </base-button>

            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="
                vehicle.auction_status?.toLowerCase() ===
                  VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase() &&
                vehicle.auction_type === AUCTION_TYPE_EVALUATION &&
                vehicle.sale_confirmation_status ===
                  VEHICLE_SALE_CONFIRMATION_STATUS_PENDING
              "
              @click="confirmVehicleSale"
            >
              <span class="btn-inner--text">
                {{ $t("VEHICLES.CONFIRM_SALE") }}
              </span>
            </base-button>
          </template>

          <template v-else>
            <base-button
              class="btn-outline-danger"
              icon
              size="sm"
              v-if="$currentUserCan($permissions.PERM_DELETE_VEHICLES)"
              @click="deleteVehicle"
            >
              <span class="btn-inner--icon">
                <i class="far fa-trash-alt"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.DELETE") }}
              </span>
            </base-button>

            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="$currentUserCan($permissions.PERM_EDIT_OWN_VEHICLES)"
              @click="editVehicle"
            >
              <span class="btn-inner--icon">
                <i class="far fa-edit"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.EDIT") }}
              </span>
            </base-button>
          </template>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="vehicle.exterior_photos?.length > 0"
        class="all-infos-image"
        :src="vehicle.exterior_photos[0]"
        alt="logo"
      />

      <img v-else class="all-infos-image" src="/img/camera.svg" alt="logo" />

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("VEHICLES.VIN") }}</dt>
          <dd>
            {{ vehicle.vin }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.SKU") }}</dt>
          <dd>
            {{ vehicle.sku }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.MODEL_YEAR") }}</dt>
          <dd>
            {{ vehicle.model_year }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.MAKE") }}</dt>
          <dd>
            {{ vehicle.make }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.MODEL") }}</dt>
          <dd>
            {{ vehicle.model }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.SERIES") }}</dt>
          <dd>
            {{ vehicle.series }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.STYLE") }}</dt>
          <dd>
            {{ vehicle.style }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.OUTSIDE_COLOR") }}</dt>
          <dd>
            {{
              vehicle.outside_color
                ? $t(`VEHICLES.COLOR_${vehicle.outside_color}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.INSIDE_COLOR") }}</dt>
          <dd>
            {{
              vehicle.inside_color
                ? $t(`VEHICLES.COLOR_${vehicle.inside_color}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.ENGINE_DESCRIPTION") }}</dt>
          <dd>
            {{ vehicle.engine_description }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.GEARBOX") }}</dt>
          <dd>
            {{ $t(`TRANSMISSIONS.${vehicle.transmission}`) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.FUEL_TYPE") }}</dt>
          <dd>
            {{
              vehicle.fuel_type
                ? $t(`FUEL_TYPES.${vehicle.fuel_type.toUpperCase()}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
          <dd>{{ vehicle.odometer_mileage }} {{ vehicle.odometer_type }}</dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.CAR_TYPE") }}</dt>
          <dd>
            {{
              vehicle.car_type
                ? $t(`VEHICLES.CAR_TYPE_${vehicle.car_type}`)
                : "-"
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.TRANSMISSION") }}</dt>
          <dd>
            {{ vehicle.drivetrain }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.NB_PASSENGERS") }}</dt>
          <dd>
            {{ vehicle.nb_passengers }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.NB_DOORS") }}</dt>
          <dd>
            {{ vehicle.nb_doors }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("VEHICLES.ACCESSORIES") }}</dt>
          <dd>
            <ul>
              <li v-for="(a, i) in vehicle.accessories" :key="i">
                {{ a }}
              </li>
            </ul>
          </dd>
        </dl>

        <div class="row">
          <dt>{{ $t("VEHICLES.CONDITION") }}</dt>
          <dd>
            {{ $t("VEHICLES.CONDITION_" + vehicle.condition) }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.OWNERSHIP_CAN_BE_TRANSFERRED") }}
          </dt>
          <dd>
            {{
              vehicle.ownership_can_be_transferred
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.MUST_BE_TOWED") }}</dt>
          <dd>
            {{ vehicle.must_be_towed ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.CARFAX_RECLAIMS") }}</dt>
          <dd>
            {{ vehicle.carfax_reclaims }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.CARFAX_REPORTING_ENABLED") }}
          </dt>
          <dd>
            {{
              vehicle.carfax_reporting_enabled
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.CARFAX_API") }}</dt>
          <dd>
            {{ vehicle.carfax_api }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.TIRE_CONDITION") }}</dt>
          <dd>
            {{ $t("VEHICLES.CONDITION_" + vehicle.tire_condition) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.TPMS") }}</dt>
          <dd>
            {{ vehicle.tpms }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.HAS_ORIGINAL_WHEELS") }}</dt>
          <dd>
            {{
              vehicle.has_original_wheels ? $t("COMMON.YES") : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.HAS_WINTER_TIRES") }}</dt>
          <dd>
            {{ vehicle.has_winter_tires ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.HAS_STUDDED_WINTER_TIRES") }}
          </dt>
          <dd>
            {{
              vehicle.has_studded_winter_tires
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>
            {{ $t("VEHICLES.HAS_FOUR_IDENTICAL_TIRES") }}
          </dt>
          <dd>
            {{
              vehicle.has_four_identical_tires
                ? $t("COMMON.YES")
                : $t("COMMON.NO")
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SECOND_TIRES") }}</dt>
          <dd>
            {{ vehicle.second_tires }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.DECLARATIONS") }}</dt>
          <dd>
            <ul>
              <li v-for="(a, i) in vehicle.declarations" :key="i">
                {{ a }}
              </li>
            </ul>
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SELLER_NOTES") }}</dt>
          <dd>
            {{ vehicle.seller_notes }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="vehicle.organization" />
          </dd>
        </div>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="vehicle.allowedLocations" />
          </dd>
        </dl>

        <div class="row">
          <dt>{{ $t("VEHICLES.PICKUP_LOCATION") }}</dt>
          <dd>
            {{ vehicle.pickup_location }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("AUCTIONS.AUCTION_TYPE") }}</dt>
          <dd>
            {{
              vehicle.auction_type
                ? $t("AUCTIONS.AUCTION_TYPE_" + vehicle.auction_type)
                : "-"
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.AUCTION_DATE") }}</dt>
          <dd>
            {{
              vehicle.auction_date
                ? $timeZoneDateFormat(vehicle.auction_date)
                : "-"
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.AUCTION_END_DATE") }}</dt>
          <dd>
            {{
              vehicle.auction_end_date
                ? $timeZoneDateFormat(vehicle.auction_end_date)
                : "-"
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.AUCTION_STATUS") }}</dt>
          <dd>
            <badge
              :type="
                vehicle.auction_status?.toLowerCase() ===
                VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase()
                  ? 'success'
                  : vehicle.auction_status?.toLowerCase() ===
                    VEHICLE_AUCTION_STATUS_ONGOING.toLowerCase()
                  ? 'warning'
                  : 'info'
              "
            >
              {{
                $t(
                  `VEHICLES.AUCTION_STATUS_${
                    vehicle.auction_status?.toUpperCase() ?? "SOON"
                  }`
                )
              }}
            </badge>
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SALE_CONFIRMATION_DATE") }}</dt>
          <dd>
            {{
              vehicle.sale_confirmation_date
                ? $timeZoneDateFormat(vehicle.sale_confirmation_date)
                : "-"
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SALE_CONFIRMATION_STATUS") }}</dt>
          <dd>
            <badge
              :type="
                vehicle.sale_confirmation_status?.toLowerCase() ===
                VEHICLE_SALE_CONFIRMATION_STATUS_CONFIRMED.toLowerCase()
                  ? 'success'
                  : vehicle.sale_confirmation_status?.toLowerCase() ===
                    VEHICLE_SALE_CONFIRMATION_STATUS_CANCELED.toLowerCase()
                  ? 'danger'
                  : 'warning'
              "
            >
              {{
                $t(
                  `VEHICLES.SALE_CONFIRMATION_STATUS_${
                    vehicle.sale_confirmation_status?.toUpperCase() ?? "PENDING"
                  }`
                )
              }}
            </badge>
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.SALE_CONFIRMED_AT") }}</dt>
          <dd>
            {{
              vehicle.sale_confirmed_at
                ? $timeZoneDateFormat(vehicle.sale_confirmed_at)
                : "-"
            }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.BUY_NOW_PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(vehicle.buy_now_price) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.MIN_BID_AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(vehicle.min_bid_amount) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("VEHICLES.RESERVE_PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(vehicle.reserve_price) }}
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <badge
              :type="
                vehicle.status.toLowerCase() ===
                VEHICLE_STATUS_COMPLETED.toLowerCase()
                  ? 'success'
                  : 'warning'
              "
            >
              {{ $t(`COMMON.${vehicle.status.toUpperCase() ?? "DRAFT"}`) }}
            </badge>
          </dd>
        </div>

        <div class="row">
          <dt>{{ $t("COMMON.VALIDATED") }}</dt>
          <dd>
            <badge :type="vehicle.is_validated ? 'success' : 'warning'">
              {{ vehicle.is_validated ? $t("COMMON.YES") : $t("COMMON.NO") }}
            </badge>
          </dd>
        </div>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization
              v-if="vehicle.organization"
              :organization="vehicle.organization"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="vehicle.created_at">
            {{ $timeZoneDateFormat(vehicle.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="vehicle.updated_at">
            {{ $timeZoneDateFormat(vehicle.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
    <div
      v-if="showCreateTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showCreateTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.ADD_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeCreateTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <add-transaction-component
            :vehicleId="vehicle.id"
            @onViewTransaction="openViewTransactionModal"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showViewTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showViewTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.VIEW_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeViewTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <view-transaction-component :transactionId="transaction.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_AUCTION_STATUS_SOON,
  VEHICLE_AUCTION_STATUS_ONGOING,
  VEHICLE_AUCTION_STATUS_COMPLETED,
  VEHICLE_SALE_CONFIRMATION_STATUS_PENDING,
  VEHICLE_SALE_CONFIRMATION_STATUS_CONFIRMED,
  VEHICLE_SALE_CONFIRMATION_STATUS_CANCELED,
} from "@/constants/vehicles";
import AddTransactionComponent from "../../TransactionManagement/components/AddTransactionComponent.vue";
import ViewTransactionComponent from "../../TransactionManagement/components/ViewTransactionComponent.vue";
import { AUCTION_TYPE_EVALUATION } from "@/constants/auctions";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";

export default {
  name: "vehicle-view-global",

  components: {
    AddTransactionComponent,
    ViewTransactionComponent,
  },

  props: ["vehicle"],

  data() {
    return {
      VEHICLE_STATUS_COMPLETED,
      VEHICLE_AUCTION_STATUS_SOON,
      VEHICLE_AUCTION_STATUS_ONGOING,
      VEHICLE_AUCTION_STATUS_COMPLETED,
      showCreateTransactionModal: false,
      showViewTransactionModal: false,
      transaction: null,
      addTransactionBtn: true,
      VEHICLE_SALE_CONFIRMATION_STATUS_PENDING,
      VEHICLE_SALE_CONFIRMATION_STATUS_CONFIRMED,
      VEHICLE_SALE_CONFIRMATION_STATUS_CANCELED,
      AUCTION_TYPE_EVALUATION,
    };
  },

  computed: {},

  created() {
    this.getTransaction();
  },

  methods: {
    editVehicle() {
      this.$emit("onEditVehicle", this.vehicle);
    },
    deleteVehicle() {
      this.$emit("onDeleteVehicle", this.vehicle);
    },
    validateVehicle() {
      this.$emit("onValidateVehicle", this.vehicle);
    },
    invalidateVehicle() {
      this.$emit("onInvalidateVehicle", this.vehicle);
    },
    closeVehicleAuction() {
      this.$emit("onCloseVehicleAuction", this.vehicle);
    },
    closeCreateTransactionModal(refresh) {
      this.showCreateTransactionModal = false;
    },
    openCreateTransactionModal() {
      this.showCreateTransactionModal = true;
    },
    closeViewTransactionModal(refresh) {
      this.showViewTransactionModal = false;
    },
    openViewTransactionModalDetails() {
      this.showCreateTransactionModal = false;
      this.showViewTransactionModal = true;
    },
    openViewTransactionModal(transaction, reRender = false) {
      this.closeViewTransactionModal();
      this.transaction = transaction;
      this.showViewTransactionModal = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: { id: this.transaction.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    async getTransaction() {
      try {
        this.loading = true;
        let params = {
          sort: "created_at",
          filter: {
            ...(this.vehicle ? { vehicle: this.vehicle.id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };
        await this.$store.dispatch("transactions/list", params);
        const transactionsArr = await this.$store.getters["transactions/list"];
        transactionsArr.forEach((elem) => {
          if (elem.status != "CANCELED") {
            this.addTransactionBtn = false;
            this.transaction = elem;
          }
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
    cancelVehicleSale() {
      this.$emit("onCancelVehicleSale", this.vehicle);
    },
    confirmVehicleSale() {
      this.$emit("onConfirmVehicleSale", this.vehicle);
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
