import { DEFAULT_SIGNATURE_X, DEFAULT_SIGNATURE_Y, DEFAULT_SIGNATURE_WIDTH, DEFAULT_SIGNATURE_HEIGHT, SUBTYPE_CAPTURE } from "@/constants/contracts";
export default {
  type: "contract-files",
  name: null,
  url: null,
  path: null,
  is_template: false,
  template_name: null,
  signature_pages: [
    {
      number: 1,
      x: DEFAULT_SIGNATURE_X,
      y: DEFAULT_SIGNATURE_Y,
      width: DEFAULT_SIGNATURE_WIDTH,
      height: DEFAULT_SIGNATURE_HEIGHT,
      signature_type: SUBTYPE_CAPTURE,
    },
  ],
  signature_type: SUBTYPE_CAPTURE,
  excerpt: "",
  meta: null,
  relationshipNames: ["contract"],
  contract: {
    type: "contracts",
    id: null,
  },
};
