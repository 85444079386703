export const TAX_TYPE_PERCENTAGE = "PERCENTAGE";
export const TAX_TYPE_AMOUNT = "AMOUNT";
export const DEFAULT_TAXE_COUNTRY =  "Canada";

export const TYPE_PALIER = "LEVELS";
export const TYPE_POURCENTAGE = "PERCENTAGE";
export const TYPE_MONTANT_FIXE = "AMOUNT";
export const TYPE_TRASANCTION_SALES = "SALES";
export const TYPE_TRASANCTION_PURCHASES = "PURCHASES";

export const CALCULATION_BASE_BEFORE_TAX = 'BEFORE_TAX';
export const CALCULATION_BASE_AFTER_TAX = 'AFTER_TAX';

export const TAXE_TYPES = {
    '':'',
    [TYPE_POURCENTAGE]: "PERCENTAGE",
    [TYPE_MONTANT_FIXE]: "AMOUNT",
    [TYPE_PALIER]: "LEVELS",
  };

  export const TAXE_TRANSACTIONS_TYPES = {
    '':'',
    [TYPE_TRASANCTION_SALES]: "SALES",
    [TYPE_TRASANCTION_PURCHASES]: "PURCHASES"
  };

  export const CALCULATION_BASES= {
    '':'',
    [CALCULATION_BASE_BEFORE_TAX]: "BEFORE_TAX",
    [CALCULATION_BASE_AFTER_TAX]: "AFTER_TAX",
  };
  
