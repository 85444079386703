<template>
    <div class="payment-method-component">
      <el-card shadow="hover" class="payment-card">
        <div class="d-flex align-items-center">
          <!-- Section gauche : as_default + Détails -->
          <div class="col-md-6 d-flex align-items-center">
            <!-- as_default activé si `is_default` est vrai -->
            <el-button v-if="isDefault" type="success" class="very-small mr-2" disabled circle></el-button>
            <el-button v-if="!isDefault && paymentMethod.status==STATUS_ACTIVE" @click="setAsDefault" type="info"  class="very-small mr-2" circle></el-button>

            <div class="icon-container mr-3">
              <i class="el-icon-user"></i>
            </div>
            <div class="details ms-3">
              <p class="bank-name">{{ paymentMethod.details.bank_name }}</p>
              <p>{{ paymentMethod.details.account_number }}</p>
              <p>
                {{ $t("COMMON.CREATED_AT") }} : 
                {{ $timeZoneDateFormat(paymentMethod.created_at) }}
              </p>
            </div>
          </div>
  
          <!-- Section droite : Statut + Actions -->
          <div class="col-md-6 text-right">
            <div class="actions ms-3">
              <status-badge class="mr-5 p-2 rounded-status-tag" :status="paymentMethod.status" />
              <base-button
              v-if="!paymentMethod.is_default "
                plain
                class="btn-rounded-10 btn-outline-danger btn-danger mr-2"
                icon
                size="sm"
                @click="deletePaymentMethod"
              >
                <span class="btn-inner--text">
                  <i class="fa fa-trash"></i>
                </span>
              </base-button>
              <base-button
                class="elite-button btn-black"
                icon
                size="sm"
                @click="viewPaymentMethod"
              >
                <span class="btn-inner--text">
                  {{ $t("PAYMENT_METHODS.VIEW_DETAILS") }}
                </span>
              </base-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </template>
  
  <script>
  import { Card, Tag, Radio, Button } from "element-ui";
  import StatusBadge from "./StatusBadge.vue";
  import { STATUS_ACTIVE} from '@/constants/paymentMethods'
  
  export default {
    layout: "DashboardLayout",
  
    components: {
      [Tag.name]: Tag,
      [Card.name]: Card,
      [Radio.name]: Radio,
      [Button.name]: Button,
      StatusBadge
    },
  
    props: {
      paymentMethod: {
        type: Object,
        required: true,
      },
    },
  
    data(){
      return{
        STATUS_ACTIVE:STATUS_ACTIVE,
      }
    },

    computed: {
      // Vérifie si ce moyen de paiement est marqué comme "par défaut"
      isDefault() {
        return this.paymentMethod.is_default;
      },
    },
  
    methods: {
      /**
       * Émet un événement pour afficher les détails d'un moyen de paiement
       */
      viewPaymentMethod() {
        this.$emit("viewPaymentMethod", this.paymentMethod);
      },
  
      /**
       * Émet un événement pour supprimer un moyen de paiement
       */
      deletePaymentMethod() {
        this.$emit("deletePaymentMethod", this.paymentMethod);
      },
  
      /**
       * Définit ce moyen de paiement comme "par défaut" s'il ne l'est pas déjà
       */
      setAsDefault() {
        if (!this.paymentMethod.is_default) {
          this.$emit("setAsDefault", this.paymentMethod);
        }
      },
    },
  };
  </script>