import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/payments-transactions`, options).then((response) => {
    return {
      list: response.data.data,
      meta: {
        next_page_url:response.data.next_page_url,
        path: response.data.path,
        per_page: response.data.per_page,
        prev_page_url: response.data.prev_page_url,
        to: response.data.to,
        total: response.data.total,
      },
    };
  });
}

function toggleOnHold(transactions) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const payload = jsona.serialize({
    stuff: transactions,
    includeNames: [],
  });

  return axios
    .patch(`${url}/payments-transactions/toggle-onhold`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}



function sendPaymentsToDpa(transactions) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const payload = jsona.serialize({
    stuff: transactions,
    includeNames: [],
  });

  return axios
    .patch(`${url}/payments-transactions/send`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  toggleOnHold,
  sendPaymentsToDpa,
};
