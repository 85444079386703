export default {
    TAXES_GROUPS: "Groupe de taxes",
    TAXES_GROUPS_LIST: "Liste des groupes de taxes",
    ADD_TAXES_GROUP: "Ajouter un groupe de taxes",
    TAXES_GROUP_ADDED: "Groupe ajouté",
    EDIT_TAXES_GROUP: "Modifier un groupe",
    TAXES_GROUP_UPDATED: "Groupe mise à jour",
    DELETE_THIS_TAXES_GROUP: "Supprimer ce groupe de taxes ?",
    TAXES_GROUP_DELETED: "Groupe supprimé",
    TAXES_GROUP_TYPE: "Catégorie de groupe",
    CATEGORY: "Catégorie",
    VIEW_TAXES_GROUP: "Groupe",
    RETURN_TO_FROM: "Retourner au formulaire",
    SAME_TAXES_GROUPS:
      'Un ou plusieurs groupes ont déjà été enregistrés avec le nom {name}. S\'agit-il du même groupe ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
    CREATED_AT: 'Créé le',
    EDIT_TAXES:'Modifier les taxes',
    PURCHASE_FEES: "Frais d'achat",
    SELLING_FEES: "Frais de vente",
    TAXES_GROUP_REQUIRED: "Veuillez sélectionner le groupe de taxes applicable",
  };
  