<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <edit-taxes-group-component
      v-if="taxesGroup"
      :taxesGroupId="taxesGroup.id"
      @onViewTaxesGroup="openTaxesGroupViewModal"
      only-taxes
      @cancelEdition="closeFormModal"
    />
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import EditTaxesGroupComponent from "../../components/EditTaxesGroupComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    EditTaxesGroupComponent,
  },
  props: ["taxesGroupData", "showModal"],

  data() {
    let taxesGroupData = { ...this.taxesGroupData };
    return {
      taxesGroup: taxesGroupData,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    openTaxesGroupViewModal() {
      this.$emit("needReload");
      this.closeFormModal();
    },
    closeFormModal() {
      this.$emit("onChangeModalState");
    },
  },

  watch: {
    taxesGroupData(taxesGroupData) {
      if (taxesGroupData) {
        this.taxesGroup = {
          ...this.taxesGroup,
          ...cloneDeep(taxesGroupData),
        };
      }
    },
  },
};
</script>
