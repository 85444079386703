import { CALCULATION_BASES } from "../../constants/charges";

export default {
    TAXES: "Taxes",
    TAXES_LIST: "Liste des taxes",
    ADD_TAXE: "Ajouter une taxe",
    TAXE_ADDED: "Taxe ajoutée",
    EDIT_TAXE: "Modifier une taxe",
    TAXE_UPDATED: "Taxe mise à jour",
    DELETE_THIS_TAXE: "Supprimer cette taxe ?",
    TAXE_DELETED: "Taxe supprimée",
    TYPE: "Type",
    CATEGORY: "Catégorie",
    VIEW_TAXE: "Taxe",
    RETURN_TO_FROM: "Retourner au formulaire",
    SAME_TAXES:
      'Une ou plusieures taxes ont déjà été enregistrées avec le nom {name}. S\'agit-il de la même taxe ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
    CREATED_AT: 'Créée le',
    fee: 'Frais',
    commission: 'Commission',
    COMMISSION: 'Commission',
    ADD_COMMISSION: 'Ajouter une commission',
    LEVELS: 'Palier',
    PERCENTAGE: 'Pourcentage',
    FIXED_AMOUNT: 'Montant Fixe',
    KEY: 'Clé Unique',
    AMOUNT: 'Montant',
    PERCENTAGE: 'Pourcentage',
    PALIERS: 'Paliers',
    ADD_PALIER: 'Ajouter un palier',
    MIN_VALUE: 'Montant Minimal',
    MAX_VALUE: 'Montant Maximal',
    min_value_required: 'Le montant minimal est requis',
    max_value_required: 'Le montant maximal est requis',
    ACTIONS: 'Actions',
    RANGE: 'Portée',
    COUT: 'Coût',
    MIN: 'Min',
    MAX: 'Max',
    CATEGORY_REQUIRED:'Veuillez sélectionner la catégorie',
    TYPE_REQUIRED:'Veuillez sélectionner le type',
    NAME_REQUIRED:'Veuillez entrer le nom',
    AMOUNT_REQUIRED:'Veuillez entrer le montant',
    PERCENTAGE_REQUIRED: 'Veuillez préciser le pourcentage',
    RANGE_REQUIRED:'Veuillez préciser une portée',
    COUT_REQUIRED:'Veuillez préciser un coût',
    IN_PERCENTAGE: 'En pourcentage',
    CLEAR_FORM:'Les données déjà entrées seront perdues',
    CONFIRM_CLEAR_FORM_TITLE: 'Réinitialisation du formulaire',
    CLEAR_FORM_CONFIRM_BUTTON: 'Effacer',
    CANCEL: 'Annuler',
    SELECT:'Sélectionner une taxe',
    SELECT_TAXES:'Ajouter des taxes',
    REQUIRED: 'Veuillez sélectionner les taxes à ajouter',
    MIN_LESS_THAN_MAX: 'La valeur minimale doit-être inférieure à la valeur maximale',
    DENOMINATION: 'Dénomination',
    TRANSACTION: "Transaction",
    TRANSACTION_REQUIRED: "Veuillez préciser la transaction",
    SALES:"Vente",
    PURCHASES:"Achat",
    ACTIVE: "Active",
    DEACTIVATED: "Désactivée",
    VALUE: "Valeur",
    TAXE_NUMBER : "Numero de taxe",
    CALCULATION_BASE : "Base de calcul",
    CALCULATION_BASE_REQUIRED:"Veuillez préciser la base de calcul",
    after_tax: "Montant apres taxe",
    before_tax: "Montant avant taxe",
    AFTER_TAX: "Montant apres taxe",
    BEFORE_TAX: "Montant avant taxe",
    ACTIVATE_THIS_TAXE: "Êtes-vous sûr de vouloir activer cette taxe ?",
    DEACTIVATE_THIS_TAXE: "Êtes-vous sûr de vouloir désactiver cette taxe ?",
    ACTIVATE: "Activer",
    DEACTIVATE: "Désactiver",
    TAXE_ACTIVATION_CHANGED: "Le statut de la taxe a été changé avec succès !",
    ACTIVATION: "Activation",
    NO_TAXES_FOUND: "Aucune taxe trouvée",
    SELECTED_TAXES: "Taxes sélectionnées",
    CALCULATION_TYPE: "Type de calcul",
    DENOMINATION_REQUIRED: "Veuillez saisr la dénomination",
    NO_TAXABLE_PRODUCT: "Produit non taxable"
  };
  