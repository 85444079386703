<template>
  <VuePhoneNumberInput
    color="#fb6340"
    v-model="phoneNumberModel"
    size="lg"
    :required="required"
    :no-example="true"
    @update="onChangePhoneNumber"
    :fetch-country="true"
    :translations="{
      countrySelectorLabel: $t('COMMON.CODE_PAYS'),
      countrySelectorError: $t('COMMON.SELECT_COUNTRY'),
      phoneNumberLabel: $t('COMMON.PHONE_NUMBER'),
      example: 'Exemple :'}"
  />
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "phone-number-input",

  components: {
    VuePhoneNumberInput,
  },

  props: {
    phoneNumber: {
      type: String,
      default: null,
      description: "Phone number",
    },
    required: {
      type: Boolean,
      default: true,
      description: "Required phone number",
    },
  },

  data() {
    return {
      phoneNumberModel: this.phoneNumber,
    };
  },

  setup() {},

  created() {},

  methods: {
    onChangePhoneNumber(input) {
      if (input.isValid) {
        const phoneParsed = parsePhoneNumber(
          input.phoneNumber,
          input.countryCode
        );
        this.$emit("phoneNumberChanged", phoneParsed.format("E.164"));
      } else {
        if (input.countryCallingCode && input.phoneNumber) {
          this.$emit(
            "phoneNumberChanged",
            `+${input.countryCallingCode}${input.phoneNumber}`
          );
        } else {
          this.$emit("phoneNumberChanged", ``);
        }
      }
    },
  },

  watch: {
    phoneNumber(phoneNumber) {
      this.phoneNumberModel = phoneNumber;
    },
  },
};
</script>
