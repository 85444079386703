<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('AUCTIONS.NAME')} (*)`"
        :placeholder="$t('AUCTIONS.NAME')"
        v-model="auction.name"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('AUCTIONS.AUCTION_TYPE')} (*)`"
        :placeholder="$t('AUCTIONS.AUCTION_TYPE')"
      >
        <el-select
          v-model="auction.auction_type"
          clearable
          class="select-primary pagination-select"
          :placeholder="$t('AUCTIONS.AUCTION_TYPE')"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="item in AUCTION_TYPES"
            :key="item"
            :label="$t(`AUCTIONS.AUCTION_TYPE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.auction_type" />
    </div>

    <div
      class="form-wrapper"
      v-if="
        [
          AUCTION_TYPE_TIMED,
          AUCTION_TYPE_EVALUATION,
          AUCTION_TYPE_EXPRESS,
        ].includes(auction.auction_type)
      "
    >
      <base-input
        :label="`${$t('AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN')} (*)`"
        type="number"
        min="0"
        v-model="auction.delay_before_end_to_bet_again"
        @change="onFormChanged"
        :placeholder="`${$t('AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN')}`"
      />

      <validation-error
        :errors="apiValidationErrors.delay_before_end_to_bet_again"
      />
    </div>

    <div
      class="form-wrapper"
      v-if="
        [
          AUCTION_TYPE_TIMED,
          AUCTION_TYPE_EVALUATION,
          AUCTION_TYPE_EXPRESS,
        ].includes(auction.auction_type)
      "
    >
      <base-input
        :label="`${$t('AUCTIONS.EXTENSION_TIME')} (*)`"
        type="number"
        min="0"
        v-model="auction.extension_time"
        @change="onFormChanged"
        :placeholder="`${$t('AUCTIONS.EXTENSION_TIME')}`"
      />
      <validation-error :errors="apiValidationErrors.extension_time" />
    </div>

    <div
      class="form-wrapper"
      v-if="[AUCTION_TYPE_EXPRESS].includes(auction.auction_type)"
    >
      <base-input
        :label="`${$t('AUCTIONS.EXPRESS_AUCTION_DURATION')} (*)`"
        type="number"
        min="0"
        v-model="auction.express_auction_duration"
        @change="onFormChanged"
        :placeholder="`${$t('AUCTIONS.EXPRESS_AUCTION_DURATION')}`"
      />
      <validation-error
        :errors="apiValidationErrors.express_auction_duration"
      />
    </div>

    <div
      class="form-wrapper"
      v-if="[AUCTION_TYPE_EVALUATION].includes(auction.auction_type)"
    >
      <base-input
        :label="`${$t('AUCTIONS.SALE_CONFIRMATION_DEADLINE')} (*)`"
        type="number"
        min="0"
        v-model="auction.sale_confirmation_deadline"
        @change="onFormChanged"
        :placeholder="`${$t('AUCTIONS.SALE_CONFIRMATION_DEADLINE')}`"
        required
      />
      <validation-error
        :errors="apiValidationErrors.sale_confirmation_deadline"
      />
    </div>

    <!-- <div class="form-wrapper full">
      <base-input
        :label="`${$t('AUCTIONS.AUTHORIZED_PAYMENTS')} (*)`"
        :placeholder="$t('AUCTIONS.AUTHORIZED_PAYMENTS')"
      >
        <el-select
          :multiple="true"
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('AUCTIONS.AUTHORIZED_PAYMENTS')"
          v-model="auction.authorized_payments"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in AUCTION_PAYMENTS"
            :key="key"
            :label="$t(`AUCTIONS.PAYMENT_SOURCE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>

      <validation-error :errors="apiValidationErrors.authorized_payments" />
    </div> -->

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          auction.id ? $t("AUCTIONS.EDIT_AUCTION") : $t("AUCTIONS.ADD_AUCTION")
        }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  AUCTION_PAYMENTS,
  AUCTION_TYPES,
  AUCTION_TYPE_TIMED,
  AUCTION_TYPE_EVALUATION,
  AUCTION_TYPE_EXPRESS,
} from "@/constants/auctions";

export default {
  layout: "DashboardLayout",

  components: {
    flatPicker,
    BaseInput,
    ValidationError,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["auctionData", "formErrors", "loading"],

  data() {
    let auctionData = { ...this.auctionData };
    return {
      auction: auctionData,
      AUCTION_TYPES,
      AUCTION_TYPE_TIMED,
      AUCTION_TYPE_EVALUATION,
      AUCTION_TYPE_EXPRESS,
      AUCTION_PAYMENTS,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.auction.end_at = this.$convertDateToUtc(this.auction.end_at);
      this.auction.start_at = this.$convertDateToUtc(this.auction.start_at);
      let auctionData = cloneDeep(this.auction);
      this.$emit("auctionSubmitted", auctionData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    auctionData(auctionData) {
      if (auctionData) {
        this.auction = {
          ...this.auction,
          ...cloneDeep(auctionData),
        };
      }
    },
  },
};
</script>
