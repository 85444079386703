<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ paymentMethod.details.account_number }}</h3>
          <status-badge
            class="mr-3 p-2 rounded-status-tag"
            :status="paymentMethod.status"
          />
        </div>

        <div class="col-6 text-right">
          <base-button
            class="elite-button"
            icon
            size="sm"
            v-if="
              !paymentMethod.is_default &&
              paymentMethod.is_active &&
              paymentMethod.status == STATUS_ACTIVE &&
              $currentUserCan($permissions.PERM_EDIT_PAYMENT_METHODS)
            "
            @click="setAsDefault"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("PAYMENT_METHODS.SET_THIS_AS_DEFAULT") }}
            </span>
          </base-button>
          <!-- <base-button
            class="elite-button btn-default"
            icon
            size="sm"
            v-if="!paymentMethod.is_default && $currentUserCan($permissions.PERM_EDIT_PAYMENT_METHODS)"
            @click="activateOrDeactivate"
          >
            <span class="btn-inner--text" v-if="paymentMethod.is_active">
              {{ $t("PAYMENT_METHODS.DEACTIVATE") }}
            </span>
            <span class="btn-inner--text" v-if="!paymentMethod.is_active">
              {{ $t("PAYMENT_METHODS.ACTIVATE") }}
            </span>
          </base-button> -->
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              !paymentMethod.is_default &&
              $currentUserCan($permissions.PERM_DELETE_PAYMENT_METHODS)
            "
            @click="deletePaymentMethod"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <!-- <base-button
            disabled
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_PAYMENT_METHODS)"
            @click="editPaymentMethod"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button> -->
        </div>
      </div>
    </div>

    <div class="paymentMethod mt-3">
      <div class="row">
        <div class="col-12">
          <div class="row mb-3 mt-2">
            <el-switch
              :disabled="paymentMethod.is_default"
              @change="handleActivateOrDeactivate"
              style="display: block"
              v-model="paymentMethodIsActive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-text="$t('PAYMENT_METHODS.ACTIVATED')"
              :inactive-text="$t('PAYMENT_METHODS.DEACTIVATED')"
            >
            </el-switch>
          </div>

          <div class="row mt-2">
            <h5 class="col-2">{{ $t("COMMON.TYPE") }}</h5>
            <h5 class="col-10">
              {{ $t(`PAYMENT_METHODS.${paymentMethod.payment_method_type}`) }}
            </h5>
          </div>

          <div class="payment-method-details mt-3">
            <h4>
              <span>{{ $t("PAYMENT_METHODS.DETAILS") }}</span>
            </h4>
            <object-details :data="getDetails(paymentMethod.details)" />
          </div>
          <!-- <div class="payment-method-holder mt-3">
                <h4><span>{{ $t("PAYMENT_METHODS.PAYMENT_METHOD_HOLDER")}}</span></h4>
                <object-details :data="paymentMethod.details.holder" />
            </div>
            <div v-if="paymentMethod.signers" class="payment-method-signers mt-3">
                <h4><span>{{ $t("PAYMENT_METHODS.SIGNERS")}}</span></h4>
                <object-details
                  :data="getSigners(paymentMethod.signers)"
                  :with-trad="false"
                />
            </div> -->

          <div
            class="mt-3 mb-3 row-details w-full"
            v-if="paymentMethod?.specimen"
          >
            <div class="row-wrapper fl_col gp10">
              <h5 class="p18 w-700 red-txt">
                {{ $t("PAYMENT_METHODS.CHECK_SPECIMEN") }}
              </h5>
              <el-image :src="paymentMethod?.specimen">
                <template #placeholder>
                  <div class="image-slot">
                    Loading<span class="dot">...</span>
                  </div>
                </template>
              </el-image>
            </div>
          </div>

          <div class="row mt-3">
            <h5 class="col-2">{{ $t("COMMON.CREATED_AT") }}</h5>
            <h5 class="col-10">
              {{ $timeZoneDateFormat(paymentMethod.created_at) }}
            </h5>
          </div>

          <div class="row mt-3" v-if="paymentMethod.updated_at">
            <h5 class="col-2">{{ $t("COMMON.UPDATED_AT") }}</h5>
            <h5 class="col-10">
              {{ $timeZoneDateFormat(paymentMethod.updated_at) }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Switch, Image } from "element-ui";
import ObjectDetails from "../components/ObjectDetails.vue";
import StatusBadge from "../components/StatusBadge.vue";
import { STATUS_ACTIVE } from "@/constants/paymentMethods";

export default {
  name: "payment-method-view-global",
  components: {
    [Switch.name]: Switch,
    [Image.name]: Image,
    ObjectDetails,
    StatusBadge,
  },
  props: ["paymentMethod"],

  data() {
    return {
      STATUS_ACTIVE: STATUS_ACTIVE,
      paymentMethodIsActive: this.paymentMethod.is_active,
    };
  },

  created() {},

  methods: {
    getDetails(details) {
      return {
        bank_name: details.bank_name,
        account_number: details.account_number,
        PAYMENT_METHOD_HOLDER: details.holder,
      };
    },
    oldgetDetails(details) {
      return {
        bank_name: details.bank_name,
        account_number: details.account_number,
        branch_code: details.branch_code,
        transit_number: details.transit_number,
        bank_acronym: details.bank_acronym,
      };
    },

    getSigners(signers) {
      return signers.reduce((acc, signer) => {
        const fullName = `${signer.firstname} ${signer.lastname}`;
        acc[fullName] = signer.email;
        return acc;
      }, {});
    },
    paymentMethodUpdated() {
      this.$emit("paymentMethodUpdated", true);
    },
    editPaymentMethod() {
      this.$emit("onEditPaymentMethod", this.paymentMethod);
    },
    deletePaymentMethod() {
      this.$emit("onDeletePaymentMethod", this.paymentMethod);
    },
    setAsDefault() {
      this.$emit("onSetAsDefault", this.paymentMethod);
    },
    activateOrDeactivate() {
      this.$emit("onActivateOrDeactivate", this.paymentMethod);
    },

    async handleActivateOrDeactivate() {
      await this.activateOrDeactivate();
      this.paymentMethodIsActive = this.paymentMethod.is_active;
    },
  },

  mounted() {
    this.paymentMethodIsActive = this.paymentMethod.is_active;
  },

  watch: {
    paymentMethod(paymentMethod) {
      this.paymentMethodIsActive = paymentMethod.is_active;
    },
  },
};
</script>
