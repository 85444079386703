<template>
  <el-select
    :placeholder="$t('COMMON.COUNTRY')"
    :clearable="clearable"
    :class="[inputClasses]"
    v-model="countryModel"
    :name="$t('COMMON.COUNTRIES')"
    :multiple="multiple"
    :filterable="filterable"
    @change="countryChanged"
  >
    <el-option
      :class="[inputClasses]"
      v-for="country in countries"
      :key="country[0]"
      :value="country[0]"
      :label="country[0]"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { allCountries } from "country-region-data";

export default {
  name: "country-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    country: {
      type: String,
      default: null,
      description: "Country id",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    clearable: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
  },

  data() {
    return {
      countryModel: this.country,
      countries: allCountries,
    };
  },

  setup() {},

  created() {},

  methods: {
    countryChanged(country) {
      this.$emit("countryChanged", country);
    },
  },

  watch: {
    country(country) {
      this.countryModel = country;
    },
  },
};
</script>
