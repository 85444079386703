<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ chargePackage.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_CHARGE_PACKAGES)"
            @click="deleteChargePackage"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_CHARGE_PACKAGES)"
            @click="editChargePackage"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="chargePackage.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ chargePackage.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="chargePackage.excerpt">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ chargePackage.excerpt ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(chargePackage.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(chargePackage.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "charge-package-view-global",

  props: ["chargePackage"],

  data() {
    return {};
  },

  created() {},

  methods: {
    chargePackageUpdated() {
      this.$emit("chargePackageUpdated", true);
    },
    editChargePackage() {
      this.$emit("onEditChargePackage", this.chargePackage);
    },
    deleteChargePackage() {
      this.$emit("onDeleteChargePackage", this.chargePackage);
    },
  },

  mounted() {},

  watch: {
    chargePackage(chargePackage) {},
  },
};
</script>
