<template>
  <el-select
    v-model="taxesGroupModel"
    @change="taxesGroupChanged"
    autocomplete="new-password"
    :placeholder="$t('TAXES.SELECT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getList"
    :loading="loading"
    :allow-create="allowCreate"
    class="w-100"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="taxesGroup in taxesGroups"
      :key="taxesGroup.id"
      :value="taxesGroup.id"
      :label="`${taxesGroup.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import _ from "lodash";

export default {
  name: "taxesGroup-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    allowNone: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taxesGroup: {
      type: [String, Array],
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      sort: "-created_at",
      taxesGroupModel: this.taxesGroup, // Modèle pour la sélection
      taxesGroups: [], // Liste des taxesGroups récupérées
      loading: false, // Indicateur de taxesGroupment
      query: "", // Pour la recherche dynamique
    };
  },

  created() {
    this.getList(null, this.taxesGroup); // Taxer les données initiales
  },

  methods: {
    // Fonction pour récupérer les taxesGroups avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.selectedType) {
          params.filter.taxesGroup_type = this.selectedType;
        }

        await this.$store.dispatch("taxesGroups/list", params);
        const taxesGroupsArr = await this.$store.getters["taxesGroups/list"];
        this.taxesGroups = taxesGroupsArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    // Emission de l'événement lorsque la sélection change
    taxesGroupChanged(taxesGroup) {
      this.$emit("taxesGroupChanged", taxesGroup);
    },
  },

  watch: {
    taxesGroup(newTaxe) {
      if (newTaxe) {
        this.taxesGroupModel = newTaxe;
        // Si la valeur a changé, on retaxe la liste avec l'ID ou les IDs
        if (newTaxe !== this.taxesGroupModel) {
          this.getList(null, newTaxe);
        }
      } else {
        this.taxesGroupModel = null;
      }
    },
  },
};
</script>
