<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="far fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />

      <base-button
        class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
        icon
        size="sm"
        @click="resetFilters"
      >
        <span class="btn-inner--icon">
          <i class="fa fa-undo"></i>
        </span>
      </base-button>
    </div>

    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="!loading ? bids : []"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>

          <el-table-column
            :label="$t('BIDS.BIDDER_NUMBER')"
            prop="bidder_number"
            min-width="180"
          />

          <el-table-column
            :label="$t('BIDS.BIDDER_TYPE')"
            prop="bidder_type"
            min-width="180"
          />

          <el-table-column :label="$t('COMMON.USER')" min-width="220">
            <template v-slot="{ row }">
              {{ row.user.firstname }} {{ row.user.lastname }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.ORGANIZATION')" min-width="220">
            <template v-slot="{ row }">
              {{ row.user.organization?.name ?? "-" }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('BIDS.BID')"
            prop="bid"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatCurrency(row.bid) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('BIDS.MAX_BID')"
            prop="max_bid"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ row.max_bid ? $formatCurrency(row.max_bid) : "-" }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('TRANSACTIONS.ADD_TRANSACTION')"
                placement="top"
                v-if="
                  maxBid &&
                  row.id == maxBid.id &&
                  (!transaction ||
                    (transaction && transaction.status == 'CANCELED'))
                "
              >
                <a
                  type="text"
                  @click="openCreateTransactionModal(row.user.organization?.id)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="ni ni-money-coins"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
          <!-- <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_bids)"
              >
                <a
                  type="text"
                  @click="viewVehicle(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_bids)"
              >
                <a
                  type="text"
                  @click="editVehicle(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_bids)"
              >
                <a
                  type="text"
                  @click="deleteVehicle(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column> -->
        </el-table>
      </div>
    </div>

    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>

      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
    <div
      v-if="showCreateTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showCreateTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.ADD_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeCreateTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <add-transaction-component
            :vehicleId="maxBid.vehicle.id.toString()"
            :buyerId="organizationId"
            :price="maxBid.bid"
            @onViewTransaction="openViewTransactionModal"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showViewTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showViewTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.VIEW_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeViewTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <view-transaction-component :transactionId="transaction.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import AddTransactionComponent from "../../TransactionManagement/components/AddTransactionComponent.vue";
import ViewTransactionComponent from "../../TransactionManagement/components/ViewTransactionComponent.vue";

export default {
  name: "bid-list-table",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    AddTransactionComponent,
    ViewTransactionComponent,
  },

  mixins: [requestErrorMixin],

  props: {
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      bids: [],
      showCreateTransactionModal: false,
      showViewTransactionModal: false,
      transaction: null,
      organizationId: null,
      maxBid: null,
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterVehicle: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "user.organization",
        };

        if (this.filterVehicle) {
          params = {
            ...params,
            filter: { ...params.filter, vehicleId: this.filterVehicle },
          };
        }

        await this.$store.dispatch("bids/list", params);
        this.bids = this.$store.getters["bids/list"];
        /*let maxBidId = this.bids.reduce(function(prev, current) {
          console.log(current.id);
            if (+current.bid > +prev.bid) {
                return current.id;
            } else {
                return prev.id;
            }
        });*/
        if (this.bids.length > 0) {
          let maxBidId = null;
          let maxBidValue = 0;
          this.bids.forEach((elem) => {
            if (elem.bid > maxBidValue) {
              maxBidValue = elem.bid;
              maxBidId = elem.id;
            }
          });
          await this.$store.dispatch("bids/get", maxBidId);
          this.maxBid = this.$store.getters["bids/bid"];
          this.getTransaction();
        }
        this.total = this.$store.getters["bids/listTotal"];
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    /** start transaction*/
    closeCreateTransactionModal(refresh) {
      this.showCreateTransactionModal = false;
    },
    openCreateTransactionModal(organization) {
      this.organizationId = organization;
      this.showCreateTransactionModal = true;
    },
    closeViewTransactionModal(refresh) {
      this.showViewTransactionModal = false;
    },
    openViewTransactionModalDetails() {
      this.showCreateTransactionModal = false;
      this.showViewTransactionModal = true;
    },
    openViewTransactionModal(transaction, reRender = false) {
      this.closeViewTransactionModal();
      this.transaction = transaction;
      this.showViewTransactionModal = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Vehicles",
          query: { id: this.transaction.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    async getTransaction() {
      try {
        this.loading = true;
        let params = {
          sort: "created_at",
          filter: {
            ...(this.maxBid ? { vehicle: this.maxBid.vehicle.id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };
        await this.$store.dispatch("transactions/list", params);
        const transactionsArr = await this.$store.getters["transactions/list"];
        this.transaction = transactionsArr[0];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
    /** end transaction */
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
