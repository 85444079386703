<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ warehouse.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_WAREHOUSES)"
            @click="deleteWarehouse"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_WAREHOUSES)"
            @click="editWarehouse"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="col-12">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ warehouse.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY") }}
          </dt>
          <dd>
            <icon-check :checked="!!warehouse.allow_negative_inventory" />
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS") }}
          </dt>
          <dd>
            <icon-check :checked="!!warehouse.allow_unregistered_products" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.DEFAULT_TAXES") }}</dt>
          <dd>
            <dl class="row" v-for="(tax, key) in warehouse.taxes" :key="key">
              <dt>{{ tax.name }}</dt>
              <dd v-if="tax.type === TAX_TYPE_PERCENTAGE">{{ tax.value }} %</dd>
              <dd v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="warehouse.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="warehouse.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="warehouse.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(warehouse.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(warehouse.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "warehouse-view-global",

  props: ["warehouse"],

  components: { IconCheck },

  data() {
    return { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT };
  },

  computed: {
    warehouseableName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.warehouse.warehouseable.company_name;
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.warehouse.warehouseable.customer_name;
      }
      return "N/A";
    },
    warehouseableTypeName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    warehouseUpdated() {
      this.$emit("warehouseUpdated", true);
    },
    editWarehouse() {
      this.$emit("onEditWarehouse", this.warehouse);
    },
    deleteWarehouse() {
      this.$emit("onDeleteWarehouse", this.warehouse);
    },
  },

  mounted() {},

  watch: {
    warehouse(warehouse) {},
  },
};
</script>
