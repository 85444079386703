<template>
  <div>
    <el-form
      class="add-form"
      @submit.prevent="checkTaxeInformation"
      @keydown.enter.prevent="() => {}"
      :model="taxe"
      label-position="top"
      ref="taxeForm"
    >
      <div class="row">
        <div class="form-wrapper col-md-6 p-2">
          <el-form-item
            class="form-group form-group-wrapper-item"
            :label="`${$t('COMMON.COUNTRY')}`"
            prop="country"
          >
            <base-input
              :placeholder="$t('COMMON.COUNTRY')"
            >
              <country-selector
                :country="taxe.country"
                :filterable="true"
                :showAll="false"
                @countryChanged="countryChanged"
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.country" />
          </el-form-item>
        </div>

        <div class="form-wrapper col-md-6 p-2">
          <el-form-item
            class="form-group form-group-wrapper-item"
            :label="`${$t('COMMON.STATE')}`"
            prop="state"
          >
            <base-input
              :placeholder="$t('COMMON.STATE')"
            >
              <state-selector
                :country="taxe.country"
                :state="taxe.state"
                :filterable="true"
                :showAll="false"
                @stateChanged="stateChanged"
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.state" />
          </el-form-item>
        </div>

        <!-- <div class="form-wrapper col-md-6 p-2">
           Transaction 
          <el-form-item
            class="form-group form-group-wrapper-item"
            :label="`${$t('TAXES.TRANSACTION')}`"
            prop="tax_type"
            :rules="[
              {
                required: true,
                message: $t('TAXES.TRANSACTION_REQUIRED'),
              },
            ]"
          >
            <el-select
              :name="`${$t('TAXES.TRANSACTION')}`"
              :placeholder="$t('TAXES.TRANSACTION')"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
              v-model="taxe.tax_type"
            >
              <el-option
                v-for="(transaction, val) in taxeTransactions"
                :key="val"
                :value="val"
                :label="`${val ? $t('TAXES.' + val) : ''}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.tax_type" />
          </el-form-item>
        </div> -->

        <div
          :class="{
            'col-md-4 form-wrapper p-2': true,
          }"
        >
          <!-- Name -->
          <el-form-item
            :class="{
              'form-group form-group-wrapper-item': true,
            }"
            :label="`${$t('COMMON.NAME')}`"
            prop="name"
            :rules="[
              {
                required: true,
                message: $t('TAXES.NAME_REQUIRED'),
              },
            ]"
          >
            <base-input
              :placeholder="$t('COMMON.NAME')"
              v-model="taxe.name"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.name" />
          </el-form-item>
        </div>
        <div
          :class="{
            'col-md-4 form-wrapper p-2': true,
          }"
        >
          <!-- Dénomination -->
          <el-form-item
            :class="{
              'form-group form-group-wrapper-item': true,
            }"
            :label="`${$t('TAXES.DENOMINATION')}`"
            prop="label"
            :rules="[
              {
                required: true,
                message: $t('TAXES.DENOMINATION_REQUIRED'),
              },
            ]"
          >
            <base-input
              :placeholder="$t('TAXES.DENOMINATION')"
              v-model="taxe.label"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.label" />
          </el-form-item>
        </div>
        <div class="form-wrapper p-2 col-md-4">
          <!-- Numero de taxe -->
          <el-form-item
            :class="{
              'form-group-wrapper-item': true,
            }"
            :label="`${$t('TAXES.TAXE_NUMBER')}`"
            prop="tax_number"
          >
            <base-input
              :placeholder="$t('TAXES.TAXE_NUMBER')"
              v-model="taxe.tax_number"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.tax_number" />
          </el-form-item>
        </div>
      </div>
      <div class="row">
        
        <div :class="{
            'form-wrapper p-2': true,
            'col-md-4': taxe.calculation_type != typePalier,
            'col-md-6': taxe.calculation_type == typePalier,
          }">
          <!-- Base de calcul -->
          <el-form-item
            :class="{
              'form-group form-group-wrapper-item': true,
            }"
            :label="`${$t('TAXES.CALCULATION_BASE')}`"
            prop="calculation_base"
            :rules="[
              {
                required: true,
                message: $t('TAXES.CALCULATION_BASE_REQUIRED'),
              },
            ]"
          >
            <el-select
              :name="`${$t('TAXES.CALCULATION_BASE')}`"
              :placeholder="$t('TAXES.CALCULATION_BASE')"
              @onchange="
                () => {
                  onFormChanged();
                }
              "
              v-model="taxe.calculation_base"
            >
              <el-option
                v-for="(calcul, val) in calculationBases"
                :key="val"
                :value="val"
                :label="`${val ? $t('TAXES.' + val) : ''}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.calculation_base" />
          </el-form-item>
        </div>

        <div
          :class="{
            'form-wrapper p-2': true,
            'col-md-4': taxe.calculation_type != typePalier,
            'col-md-6': taxe.calculation_type == typePalier,
          }"
        >
          <!-- Type -->
          <el-form-item
            class="form-group form-group-wrapper-item"
            :label="`${$t('TAXES.CALCULATION_TYPE')}`"
            prop="calculation_type"
            :rules="[
              {
                required: true,
                message: $t('TAXES.TYPE_REQUIRED'),
              },
            ]"
          >
            <el-select
              :name="`${$t('TAXES.CALCULATION_TYPE')}`"
              v-model="tempTaxeType"
              :placeholder="$t('TAXES.CALCULATION_TYPE')"
              @input="onTaxeTypeInput"
            >
              <el-option
                v-for="(taxe, val) in taxeTypes"
                :key="val"
                :value="val"
                :label="`${val ? $t('TAXES.' + val) : ''}`"
              />
            </el-select>
            <validation-error :errors="apiValidationErrors.calculation_type" />
          </el-form-item>
        </div>

        <div
          :class="{
            'col-md-4 form-wrapper p-2': true,
          }"
          v-if="
            taxe.calculation_type == typeMontantFixe ||
            taxe.calculation_type == typePourcentage
          "
        >
          <!-- MONTANT OU POURCENTAGE-->
          <el-form-item
            class="form-group form-group-wrapper-item"
            prop="cost"
            :label="
              taxe.calculation_type == typeMontantFixe
                ? `${$t('TAXES.AMOUNT')}`
                : `${$t('TAXES.PERCENTAGE')}`
            "
            :rules="[
              {
                required: true,
                message:
                  taxe.calculation_type == typeMontantFixe
                    ? $t('TAXES.AMOUNT_REQUIRED')
                    : $t('TAXES.PERCENTAGE_REQUIRED'),
              },
            ]"
          >
            <base-input
              :placeholder="
                taxe.calculation_type == typeMontantFixe
                  ? `${$t('TAXES.AMOUNT')}`
                  : `${$t('TAXES.PERCENTAGE')}`
              "
              type="number"
              v-model="taxe.cost"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.cost" />
          </el-form-item>
        </div>
        <!-- <div
            :class="{
              'form-wrapper col-md-4 p-2': true,
            }"
          v-if="taxe.calculation_type == typePourcentage"
        >
          <el-form-item
            class="form-group form-group-wrapper-item"
            prop="cost"
            :label="`${$t('TAXES.PERCENTAGE')}`"
            :rules="[
              {
                required: true,
                message: $t('TAXES.PERCENTAGE_REQUIRED'),
              },
            ]"
          >
            <base-input
              :placeholder="$t('TAXES.PERCENTAGE')"
              type="number"
              v-model="taxe.cost"
              :disabled="!isEmpty(taxe.cost)"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
            <validation-error :errors="apiValidationErrors.cost" />
          </el-form-item>
        </div> -->
      </div>
      <div
        class="form-wrapper paliers full"
        v-if="taxe.calculation_type == typePalier"
      >
        <!-- Section Paliers -->
        <h3>{{ $t("TAXES.PALIERS") }}</h3>
        <!-- TIERS ou PALIER -->
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush form-table"
            header-row-class-name="thead-light"
            :data="taxe.tiers"
          >
            <el-table-column
              :label="$t('TAXES.RANGE')"
              prop="range"
              min-width="150"
            >
              <template slot-scope="scope">
                <!-- Form Item for min_value and max_value -->
                <el-form-item
                  :prop="'tiers.' + scope.$index + '.range'"
                  :rules="[
                    {
                      validator: (rule, value, callback) =>
                        validateRange(scope.row, callback),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-row>
                    <!-- Label for min_value -->
                    <el-col :span="4">
                      <label :for="'min_value_' + scope.$index">{{
                        $t("TAXES.MIN")
                      }}</label>
                    </el-col>
                    <!-- min_value input -->
                    <el-col :span="18">
                      <base-input
                        type="number"
                        :id="'min_value_' + scope.$index"
                        class="my-1"
                        v-model="scope.row.min_value"
                        :placeholder="$t('TAXES.MIN_VALUE')"
                      />
                    </el-col>
                  </el-row>

                  <el-row>
                    <!-- Label for max_value -->
                    <el-col :span="4">
                      <label :for="'max_value_' + scope.$index">{{
                        $t("TAXES.MAX")
                      }}</label>
                    </el-col>
                    <!-- max_value input -->
                    <el-col :span="18">
                      <base-input
                        type="number"
                        :id="'max_value_' + scope.$index"
                        class="my-1"
                        v-model="scope.row.max_value"
                        :placeholder="$t('TAXES.MAX_VALUE')"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column :label="$t('TAXES.COUT')" min-width="150">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'tiers.' + scope.$index + '.cout'"
                  :rules="[
                    {
                      validator: (rule, value, callback) =>
                        validateCout(scope.row, callback),
                      trigger: 'blur',
                    },
                  ]"
                >
                  <base-input
                    type="number"
                    class="my-1"
                    v-model="scope.row.amount"
                    :placeholder="$t('TAXES.AMOUNT')"
                    :disabled="!isEmpty(scope.row.percentage)"
                  />
                  <base-input
                    type="number"
                    class="my-1"
                    v-model="scope.row.percentage"
                    :placeholder="$t('TAXES.PERCENTAGE')"
                    :disabled="!isEmpty(scope.row.amount)"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.EXCERPT')" min-width="200">
              <template slot-scope="scope">
                <el-form-item :prop="'tiers.' + scope.$index + '.excerpt'">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :placeholder="$t('COMMON.EXCERPT')"
                    v-model="scope.row.excerpt"
                  />
                </el-form-item>
              </template>
            </el-table-column>

            <!-- Colonne pour supprimer un palier -->
            <el-table-column
              :label="$t('TAXES.ACTIONS')"
              prop="actions"
              min-width="70"
            >
              <template slot-scope="scope">
                <el-form-item :prop="'tiers.' + scope.$index + '.button'">
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="removePalier(scope.$index)"
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <base-button
          type="button"
          class="btn base-button elite-button add ml-auto btn-icon btn-fab btn-default btn-sm my-2"
          @click="addPalier"
        >
          <i class="far fa-plus" />
          {{ $t("TAXES.ADD_PALIER") }}
        </base-button>
      </div>

      <!-- <div v-if="taxe.category == categoryFee" class="form-wrapper full">
       Section Commision
        <div class="taxe-selection width_1">
          <h3>{{ $t("TAXES.ADD_COMMISSION") }}</h3>
          <taxe-selector
            :taxe="taxe.commission?.id"
            :filterable="true"
            :showAll="false"
            :allowCreate="false"
            :selectedCategory="categoryCommission"
            @taxeChanged="commissionSelected"
          />
        </div>
      </div> -->

      <!-- Excerpt -->
      <div class="form-wrapper p-2 col-md-12">
        <el-form-item
          :class="{
            'form-group-wrapper-item': true,
          }"
          :label="`${$t('TAXES.ACTIVATION')}`"
          prop="active"
        >
          <el-checkbox v-model="taxe.active">{{
            $t("TAXES.ACTIVE")
          }}</el-checkbox>
        </el-form-item>
        <el-form-item
          :class="{
            'form-group-wrapper-item': true,
          }"
          :label="`${$t('COMMON.EXCERPT')}`"
          prop="excerpt"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            :placeholder="$t('COMMON.EXCERPT')"
            v-model="taxe.excerpt"
          >
          </el-input>
        </el-form-item>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>

      <div class="submit-wrapper">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          @click.prevent="checkTaxeInformation"
          :disabled="loading || processing"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
          {{ taxe.id ? $t("TAXES.EDIT_TAXE") : $t("TAXES.ADD_TAXE") }}
        </base-button>
      </div>
    </el-form>
    <modal :show="showTaxeCheckModal" modal-classes="modal-secondary" size="lg">
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("TAXES.SAME_TAXES", {
                name: taxe.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="taxes"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleTaxeCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Row,
  Col,
  Radio,
  MessageBox,
  Checkbox,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
// import TaxeSelector from "../components/TaxeSelector.vue";
import {
  TYPE_PALIER,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  TAXE_TRANSACTIONS_TYPES,
  TAXE_TYPES,
  CALCULATION_BASES
} from "@/constants/taxes";

export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    CountrySelector,
    StateSelector,
    //TaxeSelector,
  },

  mixins: [formMixin],

  props: ["taxeData", "formErrors", "loading", "hideSubmit"],

  data() {
    let taxeData = { ...this.taxeData };
    return {
      taxe: taxeData,
      taxeTransactions: TAXE_TRANSACTIONS_TYPES,
      calculationBases: CALCULATION_BASES,
      typePalier: TYPE_PALIER,
      typePourcentage: TYPE_POURCENTAGE,
      typeMontantFixe: TYPE_MONTANT_FIXE,
      taxeTypes: TAXE_TYPES,
      taxes: [],
      paliers: [],
      total: 0,
      showModal: false,
      showTaxeCheckModal: false,
      processing: false,
      tempTaxeType: taxeData.calculation_type, // Valeur temporaire
      lastValidTaxeType: TYPE_POURCENTAGE, // Dernière valeur confirmée
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    countryChanged(country) {
      this.taxe.country = country;
    },
    stateChanged(state) {
      this.taxe.state = state;
    },
    commissionSelected(commision) {
      this.taxe.commission = {
        type: "taxes",
        id: commision,
      };
    },
    isEmpty(string) {
      return string === null || string === "";
    },
    validateRange(row, callback) {
      if (row.min_value !== null && row.max_value !== null) {
        // Si min_value est supérieur ou égal à max_value, retourner une erreur
        if (parseInt(row.min_value) >= parseInt(row.max_value)) {
          callback(new Error(this.$t("TAXES.MIN_LESS_THAN_MAX")));
        } else {
          callback();
        }
      }
      // Remplir au moins un
      else if (row.min_value || row.max_value) {
        callback();
      } else {
        callback(new Error(this.$t("TAXES.RANGE_REQUIRED")));
      }
    },
    validateCout(row, callback) {
      if (row.amount || row.percentage) {
        callback();
      } else {
        callback(new Error(this.$t("TAXES.COUT_REQUIRED")));
      }
    },
    // Méthode pour ajouter un palier
    addPalier() {
      this.taxe.tiers.push({
        min_value: null,
        max_value: null,
        amount: null,
        percentage: null,
        excerpt: "",
      });
    },
    // Méthode pour supprimer un palier
    removePalier(index) {
      this.taxe.tiers.splice(index, 1);
    },

    async handleSubmit() {
      const checkUniqueTaxe = await this.checkTaxeUniqueInformation();
      if (checkUniqueTaxe) {
        await this.sendInformation();
      } else {
        this.showTaxeCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.taxe.name) {
        this.taxe.name = this.taxe.name.toString();
      }
      let taxeData = cloneDeep(this.taxe);
      this.$emit("taxeSubmitted", taxeData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    hasTiers() {
      return this.taxe.tiers && this.taxe.tiers.length;
    },

    async onTaxeTypeInput(newType) {
      if (this.hasTiers() || !this.isEmpty(this.taxe.cost)) {
        try {
          // Afficher une confirmation avant de supprimer les paliers
          await MessageBox.confirm(
            this.$t("TAXES.CLEAR_FORM"),
            this.$t("TAXES.CONFIRM_CLEAR_FORM_TITLE"),
            {
              confirmButtonText: this.$t("TAXES.CLEAR_FORM_CONFIRM_BUTTON"),
              cancelButtonText: this.$t("TAXES.CANCEL"),
              type: "warning",
            }
          );
          // Si confirmé, met à jour le modèle taxe avec la nouvelle valeur
          this.taxe.calculation_type = newType;
          this.taxe.cost = null;
          this.taxe.tiers = [];
          this.lastValidTaxeType = newType; // Mettre à jour la dernière valeur confirmée
          if (newType == this.typePalier) {
            this.addPalier();
          }
        } catch (error) {
          // Si l'utilisateur annule, réinitialise tempTaxeType à la dernière valeur confirmée
          this.tempTaxeType = this.lastValidTaxeType;
        }
      } else {
        // Met à jour directement si pas de tiers ou valeurs présentes
        this.taxe.calculation_type = newType;
        this.lastValidTaxeType = newType;
        if (newType == this.typePalier) {
          this.addPalier();
        }
      }
      this.onFormChanged();
    },

    async getList() {
      if (!this.taxe?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.taxe?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("taxes/list", params);
        this.taxes = this.$store.getters["taxes/list"];
        this.total = this.$store.getters["taxes/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkTaxeUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.taxe?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleTaxeCheckModalState();
      await this.sendInformation();
    },

    async toggleTaxeCheckModalState() {
      this.showTaxeCheckModal = !this.showTaxeCheckModal;
    },

    checkTaxeInformation() {
      this.$refs["taxeForm"].validate(async (valid) => {
        if (valid) {
          if (this.taxe.name) {
            this.handleSubmit();
            this.showModal = false;
          } else {
            this.showModal = true;
          }
        }
      });
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    taxeData(taxeData) {
      if (taxeData) {
        this.taxe = {
          ...this.taxe,
          ...cloneDeep(taxeData),
        };
      }
    },
  },
};
</script>
