<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <base-input :placeholder="$t('COMMON.START_AT')">
        <flat-picker
          :placeholder="$t('COMMON.START_AT')"
          :config="{
            allowInput: true,
            enableTime: false,
            type: 'date',
            locale: $flatPickrLocale(),
            disable: [
              function (date) {
                return date.getDay() === 0;
              },
            ],
          }"
          class="form-control datepicker"
          v-model="startAt"
        >
        </flat-picker>
      </base-input>

      <base-input :placeholder="$t('COMMON.END_AT')">
        <flat-picker
          :placeholder="$t('COMMON.END_AT')"
          :config="{
            allowInput: true,
            enableTime: false,
            type: 'date',
            minDate: startAt,
            locale: $flatPickrLocale(),
            disable: [
              function (date) {
                return date.getDay() === 0;
              },
            ],
          }"
          class="form-control datepicker"
          v-model="endAt"
        >
        </flat-picker>
      </base-input>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="far fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />

      <base-button
        class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
        icon
        size="sm"
        @click="resetFilters"
      >
        <span class="btn-inner--icon">
          <i class="fa fa-undo"></i>
        </span>
      </base-button>
    </div>

    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="!loading ? auctions : []"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>

          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ `${row.name}` }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('AUCTIONS.AUCTION_TYPE')"
            prop="auction_type"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ $t(`AUCTIONS.AUCTION_TYPE_${row.auction_type}`) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('AUCTIONS.DELAY_BEFORE_END_TO_BET_AGAIN')"
            prop="delay_before_end_to_bet_again"
            sortable="custom"
            min-width="250"
          >
            <template v-slot="{ row }">
              <span>
                {{
                  row.delay_before_end_to_bet_again
                    ? `${row.delay_before_end_to_bet_again} s`
                    : "-"
                }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('AUCTIONS.EXTENSION_TIME')"
            prop="extension_time"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ row.extension_time ? `${row.extension_time} s` : "-" }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('AUCTIONS.EXPRESS_AUCTION_DURATION')"
            prop="express_auction_duration"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{
                  row.express_auction_duration
                    ? `${row.express_auction_duration} s`
                    : "-"
                }}
              </span>
            </template>
          </el-table-column>

          <!-- <el-table-column
            :label="$t('COMMON.START_AT')"
            prop="start_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ row.start_at ? $timeZoneDateFormat(row.start_at) : "-" }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.END_AT')"
            prop="end_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ row.end_at ? $timeZoneDateFormat(row.end_at) : "-" }}
            </template>
          </el-table-column> -->

          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.created_at) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.UPDATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.updated_at) }}
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_AUCTIONS)"
              >
                <a
                  type="text"
                  @click="viewAuction(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_AUCTIONS)"
              >
                <a
                  type="text"
                  @click="editAuction(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_AUCTIONS)"
              >
                <a
                  type="text"
                  @click="deleteAuction(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>

      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import defaultAuction from "../defaultAuction";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "auction-list-table",

  components: {
    BasePagination,
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {},

  data() {
    return {
      query: null,
      startAt: null,
      endAt: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      auctions: [],
      loading: true,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;

      if (this.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    endAt: {
      handler: "getListDebounced",
      immediate: true,
    },
    startAt: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.endAt = null;
      this.startAt = null;
      this.query = null;
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };

        if (this.startAt) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              start_at: this.startAt,
            },
          };
        }

        if (this.endAt) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              end_at: this.endAt,
            },
          };
        }

        await this.$store.dispatch("auctions/list", params);
        this.auctions = this.$store.getters["auctions/list"];
        this.total = this.$store.getters["auctions/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteAuction(auction) {
      this.$emit("onDeleteAuction", auction);
    },

    viewAuction(auction) {
      this.$emit("onViewAuction", auction);
    },

    editAuction(auction) {
      this.$emit("onEditAuction", auction);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
