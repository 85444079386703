<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("PURCHASES_PAYMENTS.PURCHASES_PAYMENTS_LIST") }}
            </h3>
          </div>
        </div>
      </div>

      <transactions-list-table
        @onViewTransaction="openTransactionViewModal"
        @onDeleteTransaction="deleteTransaction"
        @onToggleOnHold="toggleOnHold"
        @onSendPaymentsToDpa="sendPaymentsToDpa"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewTransactionModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewTransactionModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_PAYMENT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1 v-if="openTransaction.type=='purchases-payments'"> {{ $t("PURCHASES_PAYMENTS.VIEW_PURCHASES_PAYMENT") }}</h1>
              <h1 v-else> {{ $t("COMMON.SALES_PAYMENT") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openTransaction"
                :objectType="openTransaction.type=='purchases-payments'?'PurchasesPayment':'SalesPayment'"
                :objectId="openTransaction.id?.toString()"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeTransactionModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-transaction-component
              v-if="openTransaction"
              :transactionId="openTransaction.id"
              :transactionType="openTransaction.type"
              @onDeleteTransaction="deleteTransaction"
              @onValidateTransaction="validateTransaction"
              @onCancelTransaction="cancelTransaction"
              @onToggleOnHold="toggleOnHold"
              @onSendPaymentsToDpa="sendPaymentsToDpa"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { PAYMENT_STATUS_DRAFT, PAYMENT_STATUS_PENDING,PAYMENT_STATUS_HOLDON } from "@/constants/payments";
import {
  QUERY_ACTIONS_VIEW
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import TransactionsListTable from "./partials/TransactionsListTable.vue";
import ViewTransactionComponent from "./components/ViewTransactionComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    TransactionsListTable,
    NotificationSubscription,
    ViewTransactionComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const type = this.$route.query.type;
    const action = this.$route.query.action;
    let isViewTransactionModalOpened = false;
    let openTransaction = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewTransactionModalOpened = true;
      }
      openTransaction = { 
        id: locationId,
        type: type
      };
    }
    return {
      isViewTransactionModalOpened: isViewTransactionModalOpened,
      openTransaction: openTransaction,
      renderKey: 1,
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
      PAYMENT_STATUS_PENDING: PAYMENT_STATUS_PENDING,
      PAYMENT_STATUS_HOLDON: PAYMENT_STATUS_HOLDON,
    };
  },

  methods: {
    openTransactionViewModal(transaction, reRender = false) {
      this.closeTransactionModal();
      this.openTransaction = transaction;
      this.isViewTransactionModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListTransactions",
          query: {
            id: this.openTransaction.id,
            type: this.openTransaction.type,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    closeTransactionModal() {
      this.isViewTransactionModalOpened = false;
      this.openTransaction = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "ListTransactions",
          query: {},
        }).href
      );
    },
    async cancelTransaction(transaction) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("COMMON.CANCEL_PAYMENT_QUESTION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          let paymentStore = transaction.type=='purchases-payments' ? 'purchasesPayments':'salesPayments';
          await this.$store.dispatch(paymentStore+"/cancel", transaction.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COMMON.PAYMENT_CANCELED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async validateTransaction(transaction) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("COMMON.VALIDATE_PAYMENT_QUESTION"),
        type: "question",
        customClass: {
          popup: "warning-confirm",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          let paymentStore = transaction.type=='purchases-payments' ? 'purchasesPayments':'salesPayments';
          await this.$store.dispatch(paymentStore+"/validate", transaction.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COMMON.PAYMENT_VALIDATED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async toggleOnHold(transactions) {
      let nb = transactions.length
      let text = this.$t("PAYMENTS.PUT_THIS_PAYMENT_ON_HOLD");
      let firstTransaction = transactions[0];
      let isHoldingOn = firstTransaction.status == PAYMENT_STATUS_PENDING;
      if(nb==1){
        text = isHoldingOn ? this.$t("PAYMENTS.PUT_THIS_PAYMENT_ON_HOLD") : this.$t("PAYMENTS.PUT_THIS_PAYMENT_ON_PENDING")
      }else{
        text = isHoldingOn ? this.$t("PAYMENTS.PUT_THOSE_PAYMENTS_ON_HOLD") : this.$t("PAYMENTS.PUT_THOSE_PAYMENTS_ON_PENDING")
      }
      const confirmation = await swal.fire({
        text: text,
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {if (confirmation.value === true) {
          await this.$store.dispatch("paymentsTransactions/toggleOnHold", transactions);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("PAYMENTS.STATUS_CHANGED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async sendPaymentsToDpa(transactions) {
      let nb = transactions.length
      let text = nb==1 ? this.$t("PAYMENTS.SEND_THIS_PAYMENT_TO_DPA"):this.$t("PAYMENTS.SEND_THOSE_PAYMENTS_TO_DPA");
      let canBeSent = transactions.every(transaction => transaction.status === PAYMENT_STATUS_PENDING);
      if(canBeSent){
      const confirmation = await swal.fire({
        text: text,
        type: "question",
        customClass: {
          popup: "warning-confirm",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {if (confirmation.value === true) {
          await this.$store.dispatch("paymentsTransactions/sendPaymentsToDpa", transactions);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("PAYMENTS.PAYMENT_SENT_TO_DPA"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    }else{
        this.$notify({
          type: "danger",
          message: this.$t("PAYMENTS.CANT_BE_SENT"),
        });
      }
    },

    
    async deleteTransaction(transaction) {
      const confirmation = await swal.fire({
        text: this.$t("PAYMENTS.DELETE_THIS_PAYMENT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          let paymentStore = transaction.type=='purchases-payments' ? 'purchasesPayments':'salesPayments';
          await this.$store.dispatch(
            paymentStore+"/destroy",
            transaction.id
          );
          this.renderKey++;
          this.closeTransactionModal();
          this.$notify({
            type: "success",
            message: this.$t("PURCHASES_PAYMENTS.PURCHASES_PAYMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
