<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("TAXES.TAXES_LIST") }}</h3>
          </div>

          <div class="col-6 text-right">
            <base-button
              class="elite-button btn-black add"
              icon
              size="sm"
              @click="openTaxesGroups"
              v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-folder"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TAXES_GROUPS.TAXES_GROUPS") }}
              </span>
            </base-button>
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openTaxeCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_TAXES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TAXES.ADD_TAXE") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
              :objectType="'taxes'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <taxe-list-table
        @onViewTaxe="openTaxeViewModal"
        @onEditTaxe="openTaxeEditModal"
        @onDeleteTaxe="deleteTaxe"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewTaxeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewTaxeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'TAXE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TAXES.VIEW_TAXE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openTaxe"
                :objectType="'taxes'"
                :objectId="openTaxe.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              
              <button class="close" @click="closeTaxeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-taxe-component
              v-if="openTaxe"
              :taxeId="openTaxe.id"
              @onEditTaxe="openTaxeEditModal"
              @onDeleteTaxe="deleteTaxe"
              @onActivateOrDeactivate="activateOrDeactivate"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditTaxeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditTaxeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'TAXE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TAXES.EDIT_TAXE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openTaxeViewModal(openTaxe)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeTaxeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-taxe-component
              v-if="openTaxe"
              :taxeId="openTaxe.id"
              @onViewTaxe="openTaxeViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddTaxeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddTaxeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'TAXE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("TAXES.ADD_TAXE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeTaxeModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-taxe-component @onViewTaxe="openTaxeViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import TaxeListTable from "./partials/TaxeListTable.vue";
import EditTaxeComponent from "./components/EditTaxeComponent.vue";
import AddTaxeComponent from "./components/AddTaxeComponent.vue";
import ViewTaxeComponent from "./components/ViewTaxeComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    TaxeListTable,
    NotificationSubscription,
    EditTaxeComponent,
    AddTaxeComponent,
    ViewTaxeComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const taxeId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewTaxeModalOpened = false;
    let isEditTaxeModalOpened = false;
    let isAddTaxeModalOpened = false;
    let openTaxe = null;
    if (taxeId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewTaxeModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditTaxeModalOpened = true;
      }
      openTaxe = { id: taxeId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddTaxeModalOpened = true;
    }
    return {
      isViewTaxeModalOpened: isViewTaxeModalOpened,
      isEditTaxeModalOpened: isEditTaxeModalOpened,
      isAddTaxeModalOpened: isAddTaxeModalOpened,
      openTaxe: openTaxe,
      renderKey: 1,
    };
  },

  methods: {
    openTaxeCreateModal() {
      this.closeTaxeModal();
      this.isAddTaxeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Taxes",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openTaxesGroups() {
      this.$router.push({
        name: "List TaxesGroups",
      });
    },
    openTaxeViewModal(taxe, reRender = false) {
      this.closeTaxeModal();
      this.openTaxe = taxe;
      this.isViewTaxeModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Taxes",
          query: { id: this.openTaxe.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openTaxeEditModal(taxe) {
      this.closeTaxeModal();
      this.openTaxe = taxe;
      this.isEditTaxeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Taxes",
          query: { id: this.openTaxe.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeTaxeModal() {
      this.isAddTaxeModalOpened = false;
      this.isViewTaxeModalOpened = false;
      this.isEditTaxeModalOpened = false;
      this.openTaxe = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Taxes",
          query: {},
        }).href
      );
    },

    async activateOrDeactivate(taxe) {
        const confirmation = await swal.fire({
          text: taxe.active ? this.$t("TAXES.DEACTIVATE_THIS_TAXE") : this.$t("TAXES.ACTIVATE_THIS_TAXE"),
          type: "question",
          customClass: {
            popup: taxe.active ? "delete-popup" : "warning-confirm",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText:
            this.$t("COMMON.YES") + ", " + (taxe.active ? this.$t("TAXES.DEACTIVATE") : this.$t("TAXES.ACTIVATE")),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        try {
          if (confirmation.value === true) {
            await this.$store.dispatch(
              "taxes/activateOrDeactivate",
              taxe
            );
            this.renderKey++;
            this.$notify({
              type: "success",
              message: this.$t("TAXES.TAXE_ACTIVATION_CHANGED"),
            });
          }
        } catch (error) {
          console.log(error);
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      },

    async deleteTaxe(taxe) {
      const confirmation = await swal.fire({
        text: this.$t("TAXES.DELETE_THIS_TAXE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("taxes/destroy", taxe.id);
          this.renderKey++;
          this.closeTaxeModal();
          this.$notify({
            type: "success",
            message: this.$t("TAXES.TAXE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
