export const CATEGORY_FEE = "fee";
export const CATEGORY_TAXE = "taxe";
export const CATEGORY_COMMISSION = "commission";
export const TYPE_PALIER = "palier";
export const TYPE_POURCENTAGE = "pourcentage";
export const TYPE_MONTANT_FIXE = "montant_fixe";
export const TYPE_TRASANCTION_SALES = "sales";
export const TYPE_TRASANCTION_PURCHASES = "purchases";

export const CALCULATION_BASE_BEFORE_TAX = 'before_tax';
export const CALCULATION_BASE_AFTER_TAX = 'after_tax';

export const CHARGE_CATEGORIES = {
  '':'',
  [CATEGORY_FEE]: "fee",
};

export const CHARGE_CATEGORIES_WHITH_COMMISSION = {
  '':'',
  [CATEGORY_FEE]: "fee",
  [CATEGORY_COMMISSION]: "commission"
};

export const CHARGE_TYPES = {
    '':'',
    [TYPE_POURCENTAGE]: "pourcentage",
    [TYPE_MONTANT_FIXE]: "montant_fixe",
    [TYPE_PALIER]: "palier",
  };

  export const CHARGE_TRANSACTIONS_TYPES = {
    '':'',
    [TYPE_TRASANCTION_SALES]: "sales",
    [TYPE_TRASANCTION_PURCHASES]: "purchases"
  };

  export const CALCULATION_BASES= {
    '':'',
    [CALCULATION_BASE_BEFORE_TAX]: "before_tax",
    [CALCULATION_BASE_AFTER_TAX]: "after_tax",
  };
  