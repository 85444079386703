<template>
  <div class="data-capture-view">
    <base-button class="expand" icon size="sm" @click="toogleScanner">
      <i class="far fa-expand"></i>
    </base-button>

    <div
      :style="{ display: scanOpened ? 'block' : 'none' }"
      id="data-capture-view"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";
import swal from "sweetalert2";
import { Option, Select } from "element-ui";

import {
  Camera,
  CameraSwitchControl,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  RectangularViewfinder,
  RectangularViewfinderStyle,
  configure,
  loadingStatus,
} from "@scandit/web-datacapture-core";
import {
  BarcodeCapture,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureSettings,
  Symbology,
  SymbologyDescription,
  barcodeCaptureLoader,
} from "@scandit/web-datacapture-barcode";

const scanditLicenceKey = process.env.VUE_APP_SCANDIT_LICENCE_KEY;

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {},

  data() {
    return {
      // loading: true,
      context: null,
      barcodeCapture: null,
      view: null,
      scanOpened: false,
    };
  },

  setup() {},

  created() {},

  mounted() {
    this.loadAndPrepareLibrary();
  },

  destroyed() {
    this.closeScanner();
  },

  methods: {
    async loadAndPrepareLibrary() {
      loadingStatus.subscribe(this.loadingStatusSubscriber);

      await configure({
        licenseKey: scanditLicenceKey,
        // libraryLocation: new URL(
        //   "/scandit-engine/",
        //   document.baseURI
        // ).toString(),
        libraryLocation: "sdc-lib",
        moduleLoaders: [barcodeCaptureLoader()],
      });

      loadingStatus.unsubscribe(this.loadingStatusSubscriber);

      this.context = await DataCaptureContext.create();

      await this.context.setFrameSource(Camera.default);

      const settings = new BarcodeCaptureSettings();

      settings.enableSymbologies([
        Symbology.Code39,
        Symbology.Code128,
        Symbology.QR,
      ]);

      this.barcodeCapture = await BarcodeCapture.forContext(
        this.context,
        settings
      );

      await this.barcodeCapture.setEnabled(false);

      this.barcodeCapture.addListener({
        didScan: async (barcodeCaptureMode, session) => {
          const barcode = session.newlyRecognizedBarcode;
          if (!barcode) {
            return;
          }

          const symbology = new SymbologyDescription(barcode.symbology);
          await this.barcodeCapture.setEnabled(false);
          this.showResult(symbology, barcode);
        },
      });

      this.view = await DataCaptureView.forContext(this.context);

      this.view.connectToElement(document.getElementById("data-capture-view"));

      this.view.addControl(new CameraSwitchControl());
    },

    loadingStatusSubscriber(info) {
      if (info.percentage === 100) {
        swal.close();
      }
    },

    showResult(symbology, barcode) {
      console.log(symbology);

      let vin = barcode.data;

      if (vin.length > 17 && ["l", "i"].includes(vin[0].toLowerCase())) {
        vin = vin.slice(1);
      }

      this.$emit("onVinScanned", vin);
      this.closeScanner();
    },

    async toogleScanner() {
      if (this.scanOpened) {
        this.closeScanner();
      } else {
        this.openScanner();
      }
    },

    async openScanner() {
      const barcodeCaptureOverlay =
        await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
          this.barcodeCapture,
          this.view,
          BarcodeCaptureOverlayStyle.Frame
        );
      const viewfinder = new RectangularViewfinder(
        RectangularViewfinderStyle.Square
      );
      await barcodeCaptureOverlay.setViewfinder(viewfinder);

      await this.context.frameSource.switchToDesiredState(FrameSourceState.On);
      await this.barcodeCapture.setEnabled(true);
      this.scanOpened = true;
    },

    async closeScanner() {
      await this.context.frameSource.switchToDesiredState(FrameSourceState.Off);
      await this.barcodeCapture.setEnabled(false);
      this.scanOpened = false;
    },
  },

  watch: {},
};
</script>
