<template>
  <div class="container-fluid">
    <product-form
      :loading="loading"
      :productData="product"
      :formErrors="formErrors"
      @productSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultProduct from "../defaultProduct";
import ProductForm from "../partials/ProductForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ProductForm,
  },

  mixins: [alertLeave],

  data() {
    const product = cloneDeep(defaultProduct);
    const me = this.$store.getters["profile/me"];
    return {
      product: product,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(product) {
      this.loading = true;

      const productData = cloneDeep(product);
      delete productData.id;

      try {
        await this.$store.dispatch("products/add", productData);
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCT_ADDED"),
        });
        const product = this.$store.getters["products/product"];
        this.$emit("onViewProduct", product, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
