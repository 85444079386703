<template>
  <div class="container-fluid">
    <card class="no-border-card">
      <div class="row cards-grid">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRODUCTS)"
          :to="{ name: 'List Products' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="fa fa-2x fa-cubes"></i>
              </span>
              <small>{{ $t("COMMON.PRODUCTS") }}</small>
            </a>
          </div>
        </router-link>

       <!--  <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_STOCK_MOVEMENTS)"
          :to="{ name: 'List StockMovements' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="fa fa-2x fa-cube"></i>
              </span>
              <small>{{ $t("COMMON.STOCK_MOVEMENTS") }}</small>
            </a>
          </div>
        </router-link> -->

        <!-- <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_WAREHOUSES)"
          :to="{ name: 'List Warehouses' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <div class="grid-card-content">
            <a class="menu-grid-item" :href="href" @click="navigate">
              <span
                class="shortcut-media avatar avatar-xl rounded-circle bg-info"
              >
                <i class="ni ni-2x ni-map-big"></i>
              </span>
              <small>{{ $t("COMMON.WAREHOUSES") }}</small>
            </a>
          </div>
        </router-link> -->
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
