<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!taxe">
      <span class="loader"></span>
    </span>
    <div v-if="taxe" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="label"
      >
        <tab-pane title="label" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <taxe-view-global
            :taxe="taxe"
            @onTaxeUpdated="get"
            @onEditTaxe="editTaxe"
            @onDeleteTaxe="deleteTaxe"
            @onActivateOrDeactivate="activateOrDeactivate"
          />
        </tab-pane>

        <tab-pane v-if="taxe.commission" title="label" id="2">
          <span slot="title">
            <taxe-icon :width="'20px'" class="custom-svg-icon" />
            {{ $t("TAXES.COMMISSION") }}
          </span>
          <taxe-view-global
            :taxe="taxe.commission"
            @onTaxeUpdated="get"
          />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <taxe-view-logs :taxe="taxe" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import TaxeViewLogs from "../partials/TaxeViewLogs.vue";
import TaxeViewGlobal from "../partials/TaxeViewGlobal.vue";
import TaxeIcon from "@/components/Icons/TaxeIcon.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    TaxeViewLogs,
    TaxeViewGlobal,
    TaxeIcon,
  },

  mixins: [],

  props: {
    taxeId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      taxe: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("taxes/get", this.taxeId);
        this.taxe = this.$store.getters["taxes/taxe"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editTaxe(taxe) {
      this.$emit("onEditTaxe", taxe);
    },
    deleteTaxe(taxe) {
      this.$emit("onDeleteTaxe", taxe);
    },
    activateOrDeactivate(taxe) {
      this.$emit("onActivateOrDeactivate", taxe);
    },
  },
};
</script>
