export default {
  PAYMENTS_LIST: "Liste des paiements",
  ADD_PAYMENT: "Ajouter un paiement",
  PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_PAYMENT: "Modifier le paiement",
  PAYMENT_SOURCE_MANUAL: "Manuel",
  PAYMENT_SOURCE_PAYPAL: "Paypal",
  PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
  AWAITING_DPA_CONTRACT_SIGNATURE: "En attente de Signature",
  HOLDON: "Mise en attente",
  SENT: "Envoyé au DPA",
  PAYMENT_SENT_TO_DPA: "Paiement envoyé au DPA !",
  SENT_TO_BANK: "Envoyé à la banque",
  DPA_CONTRACT_SIGNATURE_DECLINED: "Signature refusée",
  DRAFT: "Brouillon",
  COMPLETED: "Completé",
  CANCELED: "Annulé",
  PENDING: "En cours",
  MANUAL: "Manuel",
  STRIPE: "Stripe",
  PAYPAL: "Paypal",
  CASH: "Cash",
  DPA: "DPA",
  TRANSACTIONS: "Transactions DPA",
  DELETE_THIS_PAYMENT: "Supprimer ce paiement ?",
  FOR_THE_SELECTED_TRANSACTION: "Pour la transaction sélectionnée",
  FOR_THE_SELECTED_TRANSACTIONS: "Pour les {nb} transactions sélectionnées",
  PUT_ON_HOLD:"Mettre en attente",
  PUT_THIS_PAYMENT_ON_HOLD:"Mettre ce paiement en attente ?",
  PUT_THOSE_PAYMENTS_ON_HOLD:"Mettre ces paiements en attente ?",
  STATUS_CHANGED: "Le statut du paiement a bien été modifié !",
  PUT_ON_PENDING:"Remettre en traitement",
  PAYMENTS_TRANSACTIONS_LIST: "Liste des transactions paiements",
  PUT_THOSE_PAYMENTS_ON_PENDING:"Remettre ces paiements en traitement ?",
  PUT_THIS_PAYMENT_ON_PENDING:"Remettre ce paiement en traitement ?",
  SEND_THIS_PAYMENT_TO_DPA: "Envoyer ce paiement au DPA ?",
  SEND_THOSE_PAYMENTS_TO_DPA: "Envoyer ces paiements au DPA ?",
  CANT_BE_SENT: "Vous ne pouvez envoyer au DPA que des paiments en cours !",
  SEND_TO_DPA: "Envoyer au DPA",
  PURCHASE: "Achat",
  SALE: "Vente",
  SELECT_SAME_STATUS_TO_SHOW_OPTIONS: "Sélectionnez les transactions de même statut pour afficher les options.",
  PAIED:"Payé"
};
