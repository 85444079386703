import moment from "moment-timezone";

const utcDatePlugin = {
  install: (Vue) => {
    Vue.prototype.$convertDateToUtc = (value) => {
      // const userTimezone = moment.tz.guess();
      return moment.tz(value, 'America/Toronto').utc().toISOString();
    };
  },
};

export default utcDatePlugin;
