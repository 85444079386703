<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!invitation">
      <span class="loader"></span>
    </span>

    <div v-if="invitation" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>

          <invitation-view-global
            :invitation="invitation"
            @onEditInvitation="editInvitation"
            @onDeleteInvitation="deleteInvitation"
            @onApproveInvitation="approveInvitation"
          />
        </tab-pane>

        <tab-pane title="label" id="2">
          <span slot="title">
            <i class="fa fa-pencil"></i>
            {{ $t("SIGNATURES.SIGNATURES") }}
          </span>
          <signature-list-grid-view
            object-type="Invitation"
            :object-id="invitation.id"
            :signatures="invitation.signatures"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PackageSelector from "@/components/PackageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import InvitationViewGlobal from "../partials/InvitationViewGlobal.vue";
import InvitationViewLogs from "../partials/InvitationViewLogs.vue";
import SignatureListGridView from "../../SignatureManagement/partials/SignatureListGridView.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    InvitationViewGlobal,
    InvitationViewLogs,
    ListFileComponent,
    PackageSelector,
    ValidationError,
    SignatureListGridView,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    invitationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      invitation: null,
      loading: false,
      showSubscriptionAddModal: false,
      addSubscriptionPackageId: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("invitations/get", this.invitationId, {
          include: "signatures",
        });
        this.invitation = this.$store.getters["invitations/invitation"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    getFilesObject() {
      return {
        ...cloneDeep(this.invitation),
        organization: { id: "1" },
      };
    },
    editInvitation() {
      this.$emit("onEditInvitation", this.invitation);
    },
    deleteInvitation() {
      this.$emit("onDeleteInvitation", this.invitation);
    },
    approveInvitation() {
      this.$emit("onApproveInvitation", this.invitation);
    },
  },
};
</script>
