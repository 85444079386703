<template>
  <modal :show="showModal" size="lg" modal-classes="elite-modal edit-file signature-file-modal">
    <div class="elite-modal-form w-100">
      <el-form
      class="add-form"
        @submit.prevent="checkContractInformation"
        @keydown.enter.prevent="() => {}"
        :model="contractFile"
        label-position="top"
        ref="contractFileForm"
      >
      <!-- Name -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('COMMON.NAME')} (*)`"
            prop="name"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.NAME_REQUIRED'),
              },
            ]"
          >
            <base-input>
              <el-input
                :placeholder="$t('COMMON.NAME')"
                v-model="contractFile.name"
              />
            </base-input>
          </el-form-item>
          <!-- TEMPLATE_NAME -->
          <el-form-item
          v-if="contractFile.is_template"
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.TEMPLATE_NAME')} (*)`"
            prop="template_name"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.TEMPLATE_NAME_REQUIRED'),
              },
            ]"
          >
            <base-input>
              <el-input
                :placeholder="$t('CONTRACTS.TEMPLATE_NAME')"
                v-model="contractFile.template_name"
              />
            </base-input>
          </el-form-item>

          <!-- Type de signature -->
          <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.SIGNATURE_TYPE')} (*)`"
            prop="signature_type"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.SIGNATURE_TYPE_REQUIRED'),
              },
            ]"
          >
            <base-input class="">
            <el-select
              :label="$t('CONTRACTS.SIGNATURE_TYPE')"
              :placeholder="$t('CONTRACTS.SIGNATURE_TYPE')"
              v-model="contractFile.signature_type"
            >
              <el-option
                v-for="(type, val) in signatureTypes"
                :key="val"
                :value="val"
                :label="`${val ? $t('CONTRACTS.' + val) : ''}`"
              />
            </el-select>
            </base-input>
          </el-form-item>

          <!-- Pages de signature -->
           
          <div v-if="contractFile.signature_type!=SUBTYPE_SEAL" class="signature_pages w-100 mb-3">
            <div class="row">
              <div class="col-md-4 text-start">
                <h3 class="text-danger">
                  {{ $t("CONTRACTS.SIGNATURE_PAGES") }}
                </h3>
              </div>
              <div class="col-md-8 d-flex modal-footer justify-content-end">
                <!-- Bouton pour ajouter une page -->
                 
                <button
                  type="button"
                  class="btn close ml-auto"
                  @click="addSignaturePage"
                >
                  <i class="mr-1 fa fa-plus"></i>{{ $t("CONTRACTS.ADD_SIGNATURE_PAGE") }}
                </button>
              </div>
            </div>

            <div
              class="add-signature_page mb-3"
              v-for="(signature_page, index) in contractFile.signature_pages"
              :key="index"
            >
              <div class="delete-signature_page d-flex justify-content-end">
                <div class="row">
                  <div class="col-md-11">
                    <el-divider />
                  </div>
                  <div class="col-md-1 text-right">
                    <span v-if="index != 0" @click="removeSignaturePage(index)" class=" text-right text-black cursor-pointer font-bold">
                      <i class="fa fa-trash"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <el-form-item
                    :label="$t('CONTRACTS.SIGNATURE_POSITION_PAGE_NUMBER')"
                    :prop="'signature_pages.' + index + '.number'"
                    :rules="[
                      {
                        required: true,
                        message: $t('CONTRACTS.SIGNATURE_POSITION_PAGE_NUMBER_REQUIRED'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="signature_page.number"
                      :placeholder="$t('CONTRACTS.SIGNATURE_POSITION_PAGE_NUMBER')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-2">
                  <el-form-item
                    :label="$t('CONTRACTS.SIGNATURE_POSITION_X')"
                    :prop="'signature_pages.' + index + '.x'"
                  >
                    <el-input
                      v-model="signature_page.x"
                      :placeholder="$t('CONTRACTS.SIGNATURE_POSITION_X')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-2">
                  <el-form-item
                    :label="$t('CONTRACTS.SIGNATURE_POSITION_Y')"
                    :prop="'signature_pages.' + index + '.y'"
                  >
                    <el-input
                      v-model="signature_page.y"
                      :placeholder="$t('CONTRACTS.SIGNATURE_POSITION_Y')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-2">
                  <el-form-item
                    :label="$t('CONTRACTS.SIGNATURE_POSITION_WIDTH')"
                    :prop="'signature_pages.' + index + '.width'"
                  >
                    <el-input
                      v-model="signature_page.width"
                      :placeholder="$t('CONTRACTS.SIGNATURE_POSITION_WIDTH')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-2">
                  <el-form-item
                    :label="$t('CONTRACTS.SIGNATURE_POSITION_HEIGHT')"
                    :prop="'signature_pages.' + index + '.height'"
                  >
                    <el-input
                      v-model="signature_page.height"
                      :placeholder="$t('CONTRACTS.SIGNATURE_POSITION_HEIGHT')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-2">
                  <el-form-item
                    :label="$t('COMMON.TYPE')"
                    :prop="'signature_pages.' + index + '.signature_type'"
                  >
                    <base-input class="">
                      <el-select
                        :label="$t('COMMON.TYPE')"
                        :placeholder="$t('COMMON.TYPE')"
                        v-model="signature_page.signature_type"
                      >
                        <el-option
                          v-for="(type, val) in signatureTypes"
                          :key="val"
                          :value="val"
                          :label="`${val ? $t('CONTRACTS.' + val) : ''}`"
                        />
                      </el-select>
                    </base-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
<!-- 
          <el-form-item
            v-if="contractFile.signature_type!=SUBTYPE_SEAL"
            class="form-group-wrapper-item w-100"
            :label="`${$t('CONTRACTS.SIGNATURE_PAGES')} (*)`"
            prop="signatures_page"
            :rules="[
              {
                required: true,
                message: $t('CONTRACTS.SIGNATURE_PAGES_REQUIRED'),
              },
            ]"
          >
            <base-input>
              <el-input
                :placeholder="$t('CONTRACTS.SIGNATURE_PAGES')"
                v-model="contractFile.signature_page"
              />
            </base-input>
          </el-form-item> -->

          <!-- Excerpt -->
          <el-form-item
            :label="$t('COMMON.EXCERPT')"
            class="form-group-wrapper-item w-100"
            prop="excerpt"
          >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            :placeholder="$t('COMMON.EXCERPT')"
            v-model="contractFile.excerpt"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn edit"
        @click="checkContractInformation"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("CONTRACTS.EDIT_FILE") }}
      </button>
      <button
        type="button"
        class="btn close ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  Form,
  FormItem,
  Button,
  Select,
  Option,
  Divider
} from "element-ui";
import { cloneDeep } from "lodash";
import {SUBTYPE_CAPTURE, DEFAULT_SIGNATURE_X, DEFAULT_SIGNATURE_Y, DEFAULT_SIGNATURE_WIDTH, DEFAULT_SIGNATURE_HEIGHT, SIGNATURE_SUBTYPES, SUBTYPE_SEAL} from "@/constants/contracts";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Option.name]: Option,
    [Divider.name]: Divider,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: Object,
      default: null,
      description: "File data",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/${this.object.type}/${this.object.id}/files`;
    },
  },

  watch: {},

  data() {
    return {
      loading: false,
      contractFile: cloneDeep(this.fileData),
      signatureTypes:SIGNATURE_SUBTYPES,
      SUBTYPE_SEAL:SUBTYPE_SEAL,
    };
  },

  methods: {

    // Méthode pour ajouter un signataire
    addSignaturePage() {
      let nexNumber = 1;
      let n = this.contractFile.signature_pages.length;
      if(n>0){
        nexNumber = parseInt(this.contractFile.signature_pages[n-1].number) + 1;
      }
      this.contractFile.signature_pages.push({
        number: nexNumber,
        x: DEFAULT_SIGNATURE_X,
        y: DEFAULT_SIGNATURE_Y,
        width: DEFAULT_SIGNATURE_WIDTH,
        height: DEFAULT_SIGNATURE_HEIGHT,
        signature_type: SUBTYPE_CAPTURE,
      });
    },

    // Méthode pour supprimer un signataire
    removeSignaturePage(index) {
      this.contractFile.signature_pages.splice(index, 1);
    },

    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    checkContractInformation() {
      this.$refs["contractFileForm"].validate(async (valid) => {
        if (valid) {
          if (this.contractFile.name) {
            this.editFile();
          }
        }
      });
    },

    async editFile() {
      this.loading = true;
      const fileData = cloneDeep(this.contractFile);
      try {
        await this.$store.dispatch("contractFiles/update", fileData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        return;
      }

      this.$notify({
        type: "success",
        message: this.$t("FILES.FILE_UPDATED"),
      });
      this.closeModal(true);
      this.loading = false;
    },
  },

  watch: {
    fileData(fileData) {
      console.log('filemodel===========',fileData);
      this.contractFile = cloneDeep(fileData);
    },
  },
};
</script>
