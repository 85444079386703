<template>
  <div class="reset-password">
    <div class="reset-password-left">
      <div class="reset-password-left-inner">
        <div class="reset-password-left-inner-logo">
          <img src="/img/e-change-logo.svg" alt="logo" />
        </div>
        <div class="reset-password-left-inner-text">
          <h1>{{ $t("COMMON.RESET_PASSWORD") }}</h1>
          <p>Veuillez vous connecter pour continuer avec votre compte</p>
        </div>
        <div class="reset-password-left-inner-form">
          <base-alert type="danger" v-if="error">
            {{ error }}
          </base-alert>

          <base-alert type="success" v-if="resetPasswordEmailSent">
            {{ $t("COMMON.RESET_PASSWORD_SENT") }}
          </base-alert>

          <form
            class="needs-validation"
            @submit.prevent="handleSubmit()"
            v-if="!resetPasswordEmailSent"
          >
            <div class="form-group">
              <label for="email">Email</label>
              <div class="input-wrapper">
                <input
                  name="Email"
                  placeholder="oliviatemple@domaine.com"
                  v-model="form.data.attributes.email"
                />
              </div>
              <validation-error :errors="apiValidationErrors.email" />
            </div>

            <div class="submit-wrapper">
              <base-button
                type="primary"
                native-type="submit"
                :disabled="!form.data.attributes.email"
              >
                {{ $t("COMMON.SEND_PASSWORD_RESET_LINK") }}
              </base-button>
            </div>
          </form>

          <div class="forgot-password">
            <lang-switcher />
          </div>

          <div class="login-link">
            <router-link :to="{ name: 'Login' }">
              <i class="far fa-long-arrow-left"></i>
              <small>{{ $t("COMMON.RETURN_TO_LOGIN") }}</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="reset-password-right">
      <img src="/img/echange-login.png" alt="login" />
    </div>
  </div>
</template>

<script>
import { BaseAlert } from "@/components";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { LangSwitcher } from "@/components";

export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError, BaseAlert, LangSwitcher },
  data() {
    return {
      form: {
        data: {
          type: "password-forgot",
          attributes: {
            email: "",
            redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
          },
        },
      },
      resetPasswordEmailSent: false,
      error: null,
    };
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      try {
        await this.$store.dispatch("reset/forgotPassword", this.form.data);
        this.resetPasswordEmailSent = true;
      } catch (error) {
        this.error = this.$t("COMMON.RESET_PASSWORD_USER_NOT_FOUND");
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
