<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ stockMovement.code }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              $currentUserCan($permissions.PERM_DELETE_STOCK_MOVEMENTS) &&
              stockMovement.status === STATUS_DRAFT
            "
            @click="deleteStockMovement"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              $currentUserCan($permissions.PERM_EDIT_STOCK_MOVEMENTS) &&
              stockMovement.status === STATUS_DRAFT
            "
            @click="editStockMovement"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="col-12">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(stockMovement.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="stockMovement.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="stockMovement.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="stockMovement.sourceWarehouse">
          <dt>{{ $t("COMMON.SOURCE_WAREHOUSE") }}</dt>
          <dd>
            <object-link :object="stockMovement.sourceWarehouse" />
          </dd>
        </dl>

        <dl class="row" v-if="stockMovement.destinationWarehouse">
          <dt>{{ $t("COMMON.DESTINATION_WAREHOUSE") }}</dt>
          <dd>
            <object-link :object="stockMovement.destinationWarehouse" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.TYPE") }}</dt>
          <dd>
            <stock-movement-movement-type-badge
              :stockMovement="stockMovement"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <stock-movement-status-badge :stockMovement="stockMovement" />
          </dd>
        </dl>

        <dl class="row">
          <dt v-if="stockMovement.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd v-if="stockMovement.excerpt">
            <div v-html="stockMovement.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import StockMovementMovementTypeBadge from "./StockMovementMovementTypeBadge.vue";
import StockMovementStatusBadge from "./StockMovementStatusBadge.vue";
import { STATUS_DRAFT } from "@/constants/stockMovements";

export default {
  name: "stock-movement-view-global",

  components: {
    StockMovementStatusBadge,
    StockMovementMovementTypeBadge,
  },

  props: ["stockMovement"],

  data() {
    return {
      STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {
    editStockMovement() {
      this.$emit("onEditStockMovement", this.stockMovement);
    },
    deleteStockMovement() {
      this.$emit("onDeleteStockMovement", this.stockMovement);
    },
  },

  mounted() {},

  watch: {
    stockMovement(stockMovement) {},
  },
};
</script>
