<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <organization-selector
        v-if="
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
        @organizationChanged="
          (organizationId) => (selectedOrganization = organizationId)
        "
      />

      <base-input
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        :placeholder="`${$t('COMMON.LOCATIONS')}`"
      >
        <locations-selector
          @locationsChanged="(locations) => (selectedLocations = locations)"
        />
      </base-input>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="warehouses"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>
          <el-table-column
            :label="$t('COMMON.NAME')"
            prop="name"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>
                {{ `${row.name}` }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.LOCATIONS')"
            min-width="220"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <template v-slot="{ row }">
              <locations :locations="row.allowedLocations" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.ORGANIZATION')"
            min-width="220"
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
          >
            <template v-slot="{ row }">
              <organization :organization="row.organization" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_PRODUCTS)"
              >
                <a
                  type="text"
                  @click="viewWarehouse(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
              >
                <a
                  type="text"
                  @click="editWarehouse(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_PRODUCTS)"
              >
                <a
                  type="text"
                  @click="deleteWarehouse(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";

export default {
  name: "warehouse-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterWarehouseableType: {
      type: String,
      default: null,
      description: "Warehouseable class",
    },
    filterWarehouseableId: {
      type: String,
      default: null,
      description: "Warehouseable id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      warehouses: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterWarehouseableType: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterWarehouseableId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterWarehouseableType) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouseableType: this.filterWarehouseableType,
            },
          };
        }
        if (this.filterWarehouseableId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouseableId: this.filterWarehouseableId,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("warehouses/list", params);
        this.warehouses = this.$store.getters["warehouses/list"];
        this.total = this.$store.getters["warehouses/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteWarehouse(warehouse) {
      this.$emit("onDeleteWarehouse", warehouse);
    },

    viewWarehouse(warehouse) {
      this.$emit("onViewWarehouse", warehouse);
    },

    editWarehouse(warehouse) {
      this.$emit("onEditWarehouse", warehouse);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
