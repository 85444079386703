import { TYPE_POURCENTAGE, CALCULATION_BASE_BEFORE_TAX, TYPE_TRASANCTION_SALES, DEFAULT_TAXE_COUNTRY } from "@/constants/taxes";
export default {
  type: "taxes",
  active: true,
  name: null,
  label: null,
  tax_number: null,
  tax_type: null,
  calculation_type: TYPE_POURCENTAGE,
  calculation_base: CALCULATION_BASE_BEFORE_TAX,
  cost: null,
  tiers: [],
  excerpt: null,
  country: null,
  state: null,
};
