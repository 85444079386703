<template>
  <div class="elite-content_sidebar_level2">
    <div class="elite-content_sidebar_level2_inner">
      <h2>{{ $t("APPS.INVENTORY_MODULE") }}</h2>
      <ul class="sub-menu-items">
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRODUCTS)">
          <router-link
            :to="{ name: 'List Products' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.PRODUCTS") }}
              </span>
            </a>
          </router-link>
        </li>

        <!-- <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PRODUCTS)">
          <router-link
            :to="{ name: 'List StockMovements' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.STOCK_MOVEMENTS") }}
              </span>
            </a>
          </router-link>
        </li> -->

        <!-- <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_WAREHOUSES)">
          <router-link
            :to="{ name: 'List Warehouses' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.WAREHOUSES") }}
              </span>
            </a>
          </router-link>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
