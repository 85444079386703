<template>
  <div class="taxes-selector">
    <div class="filters" v-if="withFilters">
      <div class="row">
        <!-- <div v-if="selectedCategory == null" class="form-wrapper col-md-6 p-2">
          <el-select
            :name="`${$t('TAXES.CATEGORY')}`"
            :placeholder="$t('TAXES.CATEGORY')"
            v-model="selectedTaxeCategory"
          >
            <el-option
              v-for="(taxe, val) in taxeCategories"
              :key="val"
              :value="val"
              :label="`${val ? $t('TAXES.' + val) : ''}`"
            />
          </el-select>
        </div> -->
       <!--  <div
          v-if="selectedTransactionType == null"
          class="form-wrapper col-md-4 p-2"
        >
          <el-select
            :name="`${$t('TAXES.TRANSACTION')}`"
            :placeholder="$t('TAXES.TRANSACTION')"
            v-model="selectedTaxeTransactionType"
          >
            <el-option
              v-for="(transaction, val) in taxeTransactions"
              :key="val"
              :value="val"
              :label="`${val ? $t('TAXES.' + val) : ''}`"
            />
          </el-select>
        </div> -->
        <div
          class="form-wrapper col-md-6 p-2"
        >
            <base-input
              :placeholder="$t('COMMON.COUNTRY')"
            >
              <country-selector
                clearable
                :filterable="true"
                :showAll="false"
                @countryChanged="countryChanged"
              />
            </base-input>
        </div>
        <div
          class="form-wrapper col-md-6 p-2"
        >
            <base-input
              :placeholder="$t('COMMON.STATE')"
            >
              <state-selector
                clearable
                :country="selectedCountry"
                :filterable="true"
                :showAll="false"
                @stateChanged="stateChanged"
              />
            </base-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-wrapper col-md-12 p-2">
        <el-select
          v-model="taxeModel"
          @change="taxeChanged"
          autocomplete="new-password"
          :placeholder="$t('TAXES.SELECT')"
          :filterable="filterable"
          :multiple="multiple"
          :disabled="disabled"
          remote
          :remote-method="getList"
          :loading="loading"
          :allow-create="allowCreate"
          class="w-100"
        >
          <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
          </el-option>
          <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
          </el-option>
          <el-option
            v-for="taxe in taxes"
            :key="taxe.id"
            :value="taxe.id"
            :label="taxe.name"
          >
            <template #default>
              <!-- <span class="taxe-category text-uppercase text-gray">
                {{ $t("TAXES." + taxe.category) }} -
              </span> -->
              <span class="taxe-name">{{ taxe.name }}</span>
              <em class="text-gray" v-if="taxe.calculation_type == typePourcentage"
                > - {{ taxe.cost }} %</em
              >
              <em class="text-gray" v-else-if="taxe.calculation_type == typeMontantFixe"> - {{
                $formatCurrency(taxe.cost)
              }}</em>
              <em class="text-gray" v-else> - {{ $t("TAXES." + taxe.calculation_type) }}</em>

            </template>
          </el-option>
          <el-option
            disabled
            v-if="taxes.length == 0"
            :value="null"
            :label="$t('TAXES.NO_TAXES_FOUND')"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="withFilters" class="col-md-12">
        <!-- Liste des taxes sélectionnées -->
        <div v-if="selectedTaxes.length > 0" class="selected-taxes mt-4">
          <h5 class="text-uppercase text-center">{{ $t("TAXES.SELECTED_TAXES") }}</h5>
          <div class="selected-taxes-list">
           <div class="row">
            <!-- Liste pour les taxes de type "vente" -->
            <!-- <div v-if="salesTaxes.length > 0" class="sales col-md-6"> 
              <h5 class="text-center text-uppercase">{{ $t("TAXES.SALES") }}</h5>-->
            <div class="sales col-md-12">
              <draggable
                v-model="selectedTaxes"
                class="draggable-list"
                :group="'taxes'"
                @change="emitTaxeChangedEvent"
              >
                <div
                  v-for="(taxe, index) in selectedTaxes"
                  :key="index"
                  class="taxe-item-p"
                >
                  <!-- <div v-if="isSalesTransactionTaxe(taxe)"class="taxe-item d-flex justify-content-between align-items-center mb-2 border rounded"> -->
                  <div class="taxe-item d-flex justify-content-between align-items-center mb-2 border rounded">
                    <div class="taxe-name float-left">
                      <span class="btn-inner--text mr-2">
                        <i class="fa fa-bars"></i>
                      </span>
                      <div class="full-name float-right">
                        <!-- <span class="taxe-category text-uppercase text-gray"
                          >{{ $t("TAXES." + taxe.category) }} -
                        </span> -->
                        <span class="taxe-name">{{ taxe.name }}</span>
                        <em class="text-gray" v-if="taxe.calculation_type == typePourcentage"
                          > - {{ taxe.cost }} %</em
                        >
                        <em class="text-gray" v-else-if="taxe.calculation_type == typeMontantFixe"> - {{
                          $formatCurrency(taxe.cost)
                        }}</em>
                        <em class="text-gray" v-else> - {{ $t("TAXES." + taxe.calculation_type) }}</em>

                      </div>
                    </div>
                    <span
                      @click="removeTaxe(taxe.id)"
                      class="cursor-pointer btn-inner--text text-danger"
                    >
                      <i class="fa fa-trash"></i>
                    </span>
                  </div>
                </div>
              </draggable>
            </div>
            <!-- Liste pour les taxes de type "achat" -->
<!--             <div v-if="purchaseTaxes.length > 0" class="purchases col-md-6">
              <h5 class="text-center text-uppercase">{{ $t("TAXES.PURCHASES") }}</h5>
              <draggable
                v-model="selectedTaxes"
                class="draggable-list"
                :group="'taxes'"
                @change="emitTaxeChangedEvent"
              >
                <div
                  v-for="(taxe, index) in selectedTaxes"
                  :key="index"
                  class="taxe-item-p"
                >
                  <div
                    v-if="!isSalesTransactionTaxe(taxe)"
                    class="taxe-item d-flex justify-content-between align-items-center mb-2 border rounded"
                  >
                    <div class="taxe-name float-left">
                      <span class="btn-inner--text mr-2">
                        <i class="fa fa-bars"></i>
                      </span>
                      <div class="full-name float-right">
                        <span class="taxe-name">{{ taxe.name }}</span>
                        <em class="taxe-type text-gray">
                          - {{ $t("TAXES." + taxe.calculation_type) }}</em
                        >
                      </div>
                    </div>
                    <span
                      @click="removeTaxe(taxe.id)"
                      class="cursor-pointer btn-inner--text text-danger"
                    >
                      <i class="fa fa-trash"></i>
                    </span>
                  </div>
                </div>
              </draggable>
            </div> -->
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import { Option, Select } from "element-ui";
import draggable from "vuedraggable";
import _ from "lodash";
import {
  TYPE_PALIER,
  TYPE_POURCENTAGE,
  TYPE_MONTANT_FIXE,
  TAXE_CATEGORIES,
  TAXE_TRANSACTIONS_TYPES,
  TAXE_TYPES,
  CALCULATION_BASES,
  TYPE_TRASANCTION_SALES,
  TYPE_TRASANCTION_PURCHASES,
} from "@/constants/taxes";
export default {
  name: "taxe-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    CountrySelector,
    StateSelector
  },

  props: {
    withFilters: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    allowNone: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taxe: {
      type: [String, Array],
      default: null,
    },
    selectedTransactionType: {
      type: String,
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedTaxeTransactionType: null,
      taxeTransactions: TAXE_TRANSACTIONS_TYPES,
      calculationBases: CALCULATION_BASES,
      typePalier: TYPE_PALIER,
      typePourcentage: TYPE_POURCENTAGE,
      typeMontantFixe: TYPE_MONTANT_FIXE,
      taxeTypes: TAXE_TYPES,
      TYPE_TRASANCTION_PURCHASES: TYPE_TRASANCTION_PURCHASES,
      TYPE_TRASANCTION_SALES: TYPE_TRASANCTION_SALES,
      sort: "-created_at",
      taxeModel: this.taxe,
      taxes: [],
      selectedTaxes: [],
      loading: false,
      query: "",
      selectedCountry:null,
      selectedState:null,
    };
  },

  created() {
    this.selectedTaxeTransactionType = this.selectedTransactionType;
    this.getList(null, this.taxe);
  },
  computed: {
    salesTaxes() {
      return this.selectedTaxes.filter(
        (taxe) => taxe.tax_type === this.TYPE_TRASANCTION_SALES
      );
    },
    purchaseTaxes() {
      return this.selectedTaxes.filter(
        (taxe) => taxe.tax_type === this.TYPE_TRASANCTION_PURCHASES
      );
    },
  },
  methods: {
    
    countryChanged(country) {
      this.selectedCountry = country;
    },
    stateChanged(state) {
      this.selectedState = state;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    // Fonction pour récupérer les taxes avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.selectedType) {
          params.filter.calculation_type = this.selectedType;
        }
        
        if (this.selectedState) {
          params.filter.state = this.selectedState;
        }

        if (this.selectedCountry) {
          params.filter.country = this.selectedCountry;
        }

        if (this.selectedTaxeTransactionType) {
          params.filter.tax_type = this.selectedTaxeTransactionType;
        }

        await this.$store.dispatch("taxes/list", params);
        const taxesArr = await this.$store.getters["taxes/list"];
        this.taxes = taxesArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    isSalesTransactionTaxe(taxe) {
      return taxe.tax_type === this.TYPE_TRASANCTION_SALES;
    },

    taxeChanged(selectedTaxeIds) {
      const currentSelectedTaxes = [...this.selectedTaxes];

      const fromCurrentSelected = currentSelectedTaxes.filter((taxe) =>
        selectedTaxeIds.includes(taxe.id)
      );

      // Filtrer les taxes restantes dans this.taxes
      const fromAllTaxes = this.taxes.filter(
        (taxe) =>
          selectedTaxeIds.includes(taxe.id) &&
          !currentSelectedTaxes.some((c) => c.id === taxe.id)
      );

      // Combiner les deux
      const newSelectedTaxes = [...fromCurrentSelected, ...fromAllTaxes];
      this.selectedTaxes = newSelectedTaxes;
      this.$emit("taxeChanged", selectedTaxeIds);
    },

    removeTaxe(taxeId) {
      // Supprimer la taxe de `selectedTaxes`
      this.selectedTaxes = this.selectedTaxes.filter(
        (taxe) => taxe.id !== taxeId
      );

      // Supprimer l'ID de `taxeModel`
      this.taxeModel = this.taxeModel.filter((id) => id !== taxeId);

      this.emitTaxeChangedEvent();
    },

    emitTaxeChangedEvent() {
      const selectedTaxeIds = this.selectedTaxes.map((taxe) => taxe.id);
      this.$emit("taxeChanged", selectedTaxeIds);
    },
  },

  watch: {
    selectedCountry: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedState: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTaxeTransactionType: {
      handler: "getListDebounced",
      immediate: true,
    },
    taxe(newTaxe) {
      if (newTaxe) {
        this.taxeModel = newTaxe;
        // Si la valeur a changé, on retaxe la liste avec l'ID ou les IDs
        if (newTaxe !== this.taxeModel) {
          this.getList(null, newTaxe);
        }
      } else {
        this.taxeModel = null;
      }
    },
  },
};
</script>

<style>
.draggable-list {
  list-style: none;
  padding: 0;
}
.taxe-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 0px 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.taxe-category {
  color: gray;
  font-size: 13px;
}
.taxe-type {
  font-size: 11px;
}
</style>
