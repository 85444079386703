<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <reclamation :reclamation="reclamation" />
        </div>
        <div class="col-6 text-right">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            @click="completeReclamation(reclamation)"
            v-if="reclamation.status == 'DRAFT'"
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("RECLAMATIONS.COMPLETE_RECLAMATION") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            @click="cancelReclamation(reclamation)"
            v-if="reclamation.status == 'DRAFT'"
          >
            <span class="btn-inner--icon">
              <i class="far fa-times"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("RECLAMATIONS.CANCEL_RECLAMATION") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("RECLAMATIONS.STATUS") }}</dt>
          <dd>
            {{ $t(`RECLAMATIONS.STATUS_${reclamation.status}`) }}
            <span v-if="reclamation.motif_end">
              -
              {{ $t(`RECLAMATIONS.MOTIF_END_${reclamation.motif_end}`) }}</span
            >
          </dd>
        </dl>
        <dl class="row" v-if="reclamation.description_end">
          <dt>{{ $t("RECLAMATIONS.DESCRIPTION_END") }}</dt>
          <dd>
            <div v-html="reclamation.description_end"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("RECLAMATIONS.MOTIF") }}</dt>
          <dd>
            {{ $t(`RECLAMATIONS.MOTIF_${reclamation.motif}`) }}
          </dd>
        </dl>

        <dl class="row" v-if="reclamation.other_motif">
          <dt>{{ $t("RECLAMATIONS.OTHER_MOTIF") }}</dt>
          <dd>
            {{ reclamation.other_motif }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("RECLAMATIONS.EXCERPT") }}</dt>
          <dd>
            <div v-html="reclamation.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="reclamation.created_at">
            {{ $timeZoneDateFormat(reclamation.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="reclamation.updated_at">
            {{ $timeZoneDateFormat(reclamation.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="">
      <modal
        :show="showCompleteReclamationModal"
        modal-classes="modal-secondary"
      >
        <div class="row">
          <div class="col-6">
            <h3>
              {{ $t("RECLAMATIONS.COMPLETE_RECLAMATION") }}
            </h3>
          </div>
          <div class="col-6">
            <button class="close" @click="closeCompleteReclamationModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
        <complete-reclamation-component
          :showModal="showCompleteReclamationModal"
          :reclamation="reclamation"
          :reclamationId="reclamation.id"
          @reclamationCompleted="reclamationCompleted($event)"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import Reclamation from "@/components/Reclamation";
import CompleteReclamationComponent from "../components/CompleteReclamationComponent.vue";

export default {
  name: "reclamation-view-global",

  components: {
    Reclamation,
    CompleteReclamationComponent,
  },

  props: ["reclamation"],

  data() {
    return {
      itemModel: {},
      backupData: null,
      showCompleteReclamationModal: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    completeReclamation() {
      this.showCompleteReclamationModal = true;
    },
    closeCompleteReclamationModal() {
      this.showCompleteReclamationModal = false;
    },
    cancelReclamation() {
      this.$emit("onCancelReclamation", this.reclamation);
    },
    editReclamation() {
      this.$emit("onEditReclamation", this.reclamation);
    },
    deleteReclamation() {
      this.$emit("onDeleteReclamation", this.reclamation);
    },
    reclamationCompleted(reclamation) {
      this.reclamation.status = reclamation.status;
      this.reclamation.motif_end = reclamation.motif_end;
      this.reclamation.description_end = reclamation.description_end;
      this.showCompleteReclamationModal = false;
      this.$emit("onTransactionValidated", this.reclamation);
    },
  },

  mounted() {},

  watch: {
    reclamation(reclamation) {},
  },
};
</script>
