<template>
  <el-form
    class="add-form"
    @submit.prevent="handleSubmit"
    ref="taxesGroupForm"
  >
    <div class="form-group-wrapper">
      <!-- Charges -->
      <el-form-item
        class="form-group-wrapper-item h3 w-100"
        :label="`${$t('TAXES_GROUPS.TAXES_GROUPS')} (*)`"
        prop="taxesGroup"
      >
        <taxes-group-selector
          with-filters
          :taxesGroup="product?.taxesGroup.id"
          :multiple="false"
          :filterable="true"
          :showAll="false"
          @taxesGroupChanged="taxesGroupSelected"
        />
      </el-form-item>

      <div class="submit-wrapper">
        <base-button
          type="button"
          class="btn btn-sm elite-submit"
          native-type="submit"
          @click.prevent="handleSubmit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{ $t("TAXES_GROUPS.EDIT_TAXES_GROUP") }}
        </base-button>
        <button
          type="button"
          class="btn btn-sm base-button btn-black text-white px-5 ml-2"
          @click="closeFormModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { cloneDeep } from "lodash";
import { Form, FormItem } from "element-ui";
import TaxesGroupSelector from "../../../AdminModule/TaxesGroupManagement/components/TaxesGroupSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    TaxesGroupSelector,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },

  props: {
    productId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },

  data() {
    return {
      product: null,
      formErrors: null,
      loading: false,
      productTaxesGroupData: {
        type:'product',
        id: null,
        taxes_group_id: null,
      },
    };
  },

  mounted() {
    this.getProduct();
    if (this.product) {
      this.productTaxesGroupData.id = this.product.id;
    }
  },

  methods: {
    
    taxesGroupSelected(selected) {
      this.productTaxesGroupData.taxes_group_id = selected;
      this.product.taxesGroup = {
        type: "taxes-groups",
        id: selected,
      }
    },

    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("products/get", this.productId);
        this.product = this.$store.getters["products/product"];
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit() {
      if (
        this.productTaxesGroupData.taxes_group_id == null
      ) {
        return;
      }
      this.loading = true;
      this.productTaxesGroupData.id = this.product.id;

      try {
        await this.$store.dispatch(
          "products/changeTaxesGroup",
          this.productTaxesGroupData
        );
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCT_UPDATED"),
        });
        this.openTaxesGroupViewModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
    

    async getProduct() {
      await this.get();
    },

    openTaxesGroupViewModal() {
      this.closeFormModal();
      this.$emit("needReload", this.product);
    },
    closeFormModal() {
      this.$emit("onChangeModalState");
    },
  },
};
</script>
